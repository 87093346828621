import CardAlternative from "../../../Card/CardAlternative";
import React from "react";
import * as PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import _ from "lodash";

function Network(props) {
  const { jobStats } = props;

  return (
    <>
      <CardAlternative
        title={
          "Network " +
          (_.has(jobStats, "job.networkScore")
            ? "(" + jobStats.job.networkScore + "/10)"
            : "")
        }
      />

      <div className="campaigns__grid">
        <List>
          <ListItem>
            <ListItemText
              primary="Inbound Packet Loss"
              secondary={
                _.has(
                  jobStats,
                  "softphoneSummary.networkPerformanceMetrics.packetLoss.avg"
                )
                  ? "Avg: " +
                    jobStats.softphoneSummary.networkPerformanceMetrics
                      .packetLoss.avg +
                    " " +
                    "Min: " +
                    jobStats.softphoneSummary.networkPerformanceMetrics
                      .packetLoss.min +
                    " " +
                    "Max: " +
                    jobStats.softphoneSummary.networkPerformanceMetrics
                      .packetLoss.max
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Outbound Packet Loss"
              secondary={
                _.has(
                  jobStats,
                  "softphoneSummary.networkPerformanceMetrics.outboundPacketLossPercentage.avg"
                )
                  ? "Avg: " +
                    jobStats.softphoneSummary.networkPerformanceMetrics
                      .outboundPacketLossPercentage.avg +
                    " " +
                    "Min: " +
                    jobStats.softphoneSummary.networkPerformanceMetrics
                      .outboundPacketLossPercentage.min +
                    " " +
                    "Max: " +
                    jobStats.softphoneSummary.networkPerformanceMetrics
                      .outboundPacketLossPercentage.max
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Jitter"
              secondary={
                _.has(
                  jobStats,
                  "softphoneSummary.networkPerformanceMetrics.jitter.avg"
                )
                  ? "Avg: " +
                    jobStats.softphoneSummary.networkPerformanceMetrics.jitter
                      .avg +
                    " " +
                    "Min: " +
                    jobStats.softphoneSummary.networkPerformanceMetrics.jitter
                      .min +
                    " " +
                    "Max: " +
                    jobStats.softphoneSummary.networkPerformanceMetrics.jitter
                      .max
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="RTT"
              secondary={
                _.has(
                  jobStats,
                  "softphoneSummary.networkPerformanceMetrics.rtt.avg"
                )
                  ? "Avg: " +
                    jobStats.softphoneSummary.networkPerformanceMetrics.rtt
                      .avg +
                    " " +
                    "Min: " +
                    jobStats.softphoneSummary.networkPerformanceMetrics.rtt
                      .min +
                    " " +
                    "Max: " +
                    jobStats.softphoneSummary.networkPerformanceMetrics.rtt.max
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="MOS"
              secondary={
                _.has(
                  jobStats,
                  "softphoneSummary.networkPerformanceMetrics.mos.avg"
                )
                  ? "Avg: " +
                    jobStats.softphoneSummary.networkPerformanceMetrics.mos.avg.toFixed(
                      2
                    ) +
                    " " +
                    "Min: " +
                    jobStats.softphoneSummary.networkPerformanceMetrics.mos.min.toFixed(
                      2
                    ) +
                    " " +
                    "Max: " +
                    jobStats.softphoneSummary.networkPerformanceMetrics.mos.max.toFixed(
                      2
                    )
                  : "N/A"
              }
            />
          </ListItem>
        </List>
        <List>
          <ListItem>
            <ListItemText
              primary="Agent LAN IP"
              secondary={
                _.has(jobStats, "softphoneSummary.agentLocalIPAddress")
                  ? jobStats.softphoneSummary.agentLocalIPAddress
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Agent WAN IP"
              secondary={
                _.has(jobStats, "softphoneSummary.agentExternalIPAddress")
                  ? jobStats.softphoneSummary.agentExternalIPAddress
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="TURN server FQDN + Port"
              secondary={
                _.has(jobStats, "softphoneSummary.iceServers[0].turnIpAddress")
                  ? jobStats.softphoneSummary.iceServers[0].turnIpAddress +
                    " + " +
                    jobStats.softphoneSummary.iceServers[0].port
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Network Type"
              secondary={
                _.has(jobStats, "softphoneSummary.networkType")
                  ? jobStats.softphoneSummary.networkType
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="WebRTC Audio Latency"
              secondary={
                jobStats.audioLatency &&
                _.has(jobStats, "audioLatency") &&
                jobStats.audioLatency.find(
                  (audioLatency) =>
                    audioLatency.recordingType === "connect" &&
                    audioLatency.averageLatencyMs
                )
                  ? jobStats.audioLatency.find(
                      (audioLatency) => audioLatency.recordingType === "connect"
                    ).averageLatencyMs + " ms"
                  : "N/A"
              }
            />
          </ListItem>
        </List>
      </div>
    </>
  );
}

Network.propTypes = {
  jobStats: PropTypes.object,
  calculateDegradation: PropTypes.func,
  customerToConnectReference: PropTypes.number,
};

export default Network;
