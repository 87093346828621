import { BI_AUTH_SUCCESSFUL } from "../actions/auth";

const canvas = (
  state = {
    biAuthSuccessful: false,
  },
  action
) => {
  switch (action.type) {
    case BI_AUTH_SUCCESSFUL:
      return {
        biAuthSuccessful: action.value,
      };
    default:
      return state;
  }
};

export default canvas;
