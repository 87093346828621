import React from "react";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import * as PropTypes from "prop-types";
import CardAlternative from "../../Card/CardAlternative";
import moment from "moment";

function JobDetails(props) {
  const { job, insights } = props;
  const completedTime = findCompletedTimestamp(insights.events);
  return (
    <div className="campaigns__grid">
      <List>
        <ListItem>
          <ListItemText
            primary="Job Start Time"
            secondary={moment(job.createdOn).format("DD-MM-YYYY HH:mm:ss")}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Job End Time"
            secondary={
              insights && moment(completedTime).format("DD-MM-YYYY HH:mm:ss")
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Duration"
            secondary={
              calculateDuration(job.createdOn, completedTime) + " Second(s)"
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Amazon Contact Contact ID"
            secondary={job.callContactId}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Amazon Connect Region"
            secondary={job.connectRegion}
          />
        </ListItem>
      </List>

      <List>
        <ListItem>
          <ListItemText primary="Timezone" secondary={""} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Operata Job ID" secondary={job.jobId} />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Operata Monitor ID"
            secondary={job.monitorId}
          />
        </ListItem>
        <ListItem>
          <ListItemText primary="Operata Call ID" secondary={job.callSid} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Agent Region" secondary={job.agentRegion} />
        </ListItem>
      </List>
    </div>
  );
}

JobDetails.propTypes = { job: PropTypes.any, insights: PropTypes.any };

function TelcoDetails(props) {
  const { insightsSummary } = props;
  return (
    <div>
      <List>
        <ListItem>
          <ListItemText
            primary="From"
            secondary={insightsSummary.from.caller}
          />
        </ListItem>
        <ListItem>
          <ListItemText primary="To" secondary={insightsSummary.to.callee} />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Called Carrier"
            secondary={insightsSummary.to.carrier}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Media POP"
            secondary={
              insightsSummary.carrier_edge
                ? insightsSummary.carrier_edge.properties.media_region
                : "N/A"
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Codec"
            secondary={
              insightsSummary.carrier_edge
                ? insightsSummary.carrier_edge.metrics.inbound.codec_name
                : "N/A"
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Post Dial Delay"
            secondary={insightsSummary.properties.pdd_ms + " (ms)"}
          />
        </ListItem>
      </List>
    </div>
  );
}

TelcoDetails.propTypes = { insightsSummary: PropTypes.any };

function JobFlags(props) {
  const { tags } = props;
  return (
    <div>
      <List>
        {tags.map((tag, i) => (
          <ListItem key={i}>
            <ListItemText secondary={tag} />
          </ListItem>
        ))}
      </List>
    </div>
  );
}

JobFlags.propTypes = { tags: PropTypes.array };

function findCompletedTimestamp(events) {
  if (events != null) {
    let retTimestamp = null;
    events.map((event) => {
      if (event.name === "completed") {
        retTimestamp = event.timestamp;
      }
    });

    return retTimestamp;
  }
}

function calculateDuration(start, end) {
  if (start != null && end != null) {
    const duration = moment.duration(moment(end).diff(moment(start)));
    return duration.asSeconds();
  }
}

function Overview(props) {
  const { jobDetails, insights } = props;

  return (
    <Grid container spacing={2}>
      {jobDetails !== null && jobDetails.tags != null && (
        <Grid item xs={7}>
          <CardAlternative title={"Job Flags"}>
            <JobFlags tags={jobDetails.tags} />
          </CardAlternative>
        </Grid>
      )}
      <Grid item xs={5}>
        <CardAlternative title={"Telco Details"}>
          {insights !== null && insights.summary != null && (
            <TelcoDetails insightsSummary={insights.summary} />
          )}
        </CardAlternative>
      </Grid>
      <Grid item xs={12}>
        <CardAlternative title={"Job Details"}>
          {jobDetails !== null && jobDetails.job && (
            <JobDetails job={jobDetails.job} insights={insights} />
          )}
        </CardAlternative>
      </Grid>
    </Grid>
  );
}

Overview.propTypes = { jobDetails: PropTypes.any, insights: PropTypes.any };

export default Overview;
