import React from "react";
import "./style.scss";
import { Stack } from "@operata/adagio";
import CallHeader from "./CallHeader";
import CallDetail from "./CallDetail";
import CallContent from "./CallContent";
import { getCallData } from "./tools";
import { auth, USER_ROLE_SUPER } from "../../auth/Auth";
import PropTypes from "prop-types";

class CallOnPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactId: props?.match?.params?.contactId || null,
      call: null,
      hasResolved: false,
    };
  }

  componentDidMount() {
    const { contactId } = this.state;
    const region = auth.getCurrentRegion();

    if (contactId && region) {
      getCallData(
        contactId,
        region,
        auth.hasPermission([USER_ROLE_SUPER])
      ).then((call) => {
        this.setState({ call: call });
        this.setState({ hasResolved: true });
      });
    } else {
      this.setState({ hasResolved: true });
    }
  }

  render() {
    const { call, contactId, hasResolved } = this.state;
    const basePath = `${this.props.match.url}/call/${contactId}`;

    return (
      <Stack direction="column" gap="24" padding="medium">
        <div className="section">
          <CallHeader call={call} hasResolved={hasResolved} />
          <div className="section__container">
            <Stack direction="row" layout="fixed" padding="medium" gap="medium">
              <CallDetail call={call} hasResolved={hasResolved} />
            </Stack>
            <CallContent
              events={false}
              call={call}
              path={basePath}
              hasResolved={hasResolved}
            />
          </div>
        </div>
      </Stack>
    );
  }
}

CallOnPage.propTypes = {
  contactId: PropTypes.string,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired,
  }).isRequired,
};

export default CallOnPage;
