import React from "react";
import { Stack, ButtonRefactored as Button, CopyIcon } from "@operata/adagio";
import JSONPretty from "react-json-pretty";
import PropTypes from "prop-types";
import { isEmpty } from "../tools";
import "./_style-json.scss";

const TabRTC = ({ call, hasResolved }) => {
  return (
    <Stack padding="medium" gap="medium">
      {call?.call && (
        <Button
          type="tertiary"
          size="medium"
          icon={<CopyIcon />}
          onClick={() => {
            navigator.clipboard.writeText(JSON.stringify(call?.call));
          }}
        >
          Copy RAW
        </Button>
      )}
      <div id="raw-output-container">
        <JSONPretty
          id="json-pretty"
          data={
            hasResolved
              ? !isEmpty(call?.call)
                ? call.call
                : "Unable to retrieve RTC Trace"
              : "Loading..."
          }
        />
      </div>
    </Stack>
  );
};

TabRTC.propTypes = {
  call: PropTypes.object,
  hasResolved: PropTypes.bool,
};

export default TabRTC;
