import { validate as userValidation } from "./validation";
import { change } from "redux-form";

import { axiosProxy } from "../../axios/AxiosProxy";
import { getAPIUrl } from "../../utils/common";

export const validate = (values) => {
  return userValidation(values);
};

export const asyncValidate = (values, dispatch) => {
  const email = values["email"];
  let groups = values["groups"];
  return axiosProxy
    .getInstance()
    .get(getAPIUrl() + "/users/" + email)
    .then((resp) => {
      dispatch(change("MemberForm", "id", resp.data["id"]));
      dispatch(change("MemberForm", "name", resp.data["name"]));
      dispatch(change("MemberForm", "groups", groups));
    })
    .catch((error) => {
      dispatch(change("MemberForm", "id", 0));

      if (error.response) {
        console.log(
          "serverside error while trying to verify email ",
          error.response.statusText
        );
      } else {
        throw error;
      }
    });
};
