import { README_RECEIVED, SAMPLE_CODE_RECEIVED } from "../actions/deployment";

const deployment = (state = { loading: false }, action) => {
  switch (action.type) {
    case README_RECEIVED:
      return Object.assign({}, state, {
        readme: action.readme,
        loading: false,
        status: README_RECEIVED,
      });
    case SAMPLE_CODE_RECEIVED:
      return Object.assign({}, state, {
        code: action.code,
        loading: false,
        status: SAMPLE_CODE_RECEIVED,
      });
    default:
      return Object.assign({}, state);
  }
};

export default deployment;
