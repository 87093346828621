import React from "react";
import WorkflowContext from "../WorkflowContext";
import VisibilityToggle from "../../VisibilityToggle/VisibilityToggle";

class StepTrigger extends React.Component {
  renderTriggers(
    selectedApplication,
    availableTriggers,
    selectedTrigger,
    setTrigger
  ) {
    return Object.keys(availableTriggers).map((key) => {
      let { displayText, description, image, application } =
        availableTriggers[key];
      if (application !== selectedApplication) return;

      let selected = key === selectedTrigger ? "selected" : "";

      return (
        <li
          className={`trigger__cell ${selected}`}
          key={key}
          onClick={() => {
            setTrigger(key);
          }}
        >
          <div className="trigger__inner">
            <div className="trigger__image">
              <img src={image} alt={displayText} />
            </div>
            <h2 className="trigger__title">{displayText}</h2>
            <VisibilityToggle className={"trigger__description"}>
              {description}
            </VisibilityToggle>
          </div>
        </li>
      );
    });
  }

  render() {
    return (
      <WorkflowContext.Consumer>
        {({ application, trigger, setTrigger, availableTriggers }) => (
          <ul className="auto-grid">
            {this.renderTriggers(
              application,
              availableTriggers,
              trigger,
              setTrigger
            )}
          </ul>
        )}
      </WorkflowContext.Consumer>
    );
  }
}

export default StepTrigger;
