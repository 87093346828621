import React from "react";
import { Stack, ButtonRefactored as Button, CopyIcon } from "@operata/adagio";
import JSONPretty from "react-json-pretty";
import PropTypes from "prop-types";
import { isEmpty } from "../tools";
import "./_style-json.scss";

const TabLogs = ({ call, hasResolved }) => {
  return (
    <Stack padding="medium" gap="medium">
      {call?.logs && (
        <Button
          type="tertiary"
          size="medium"
          icon={<CopyIcon />}
          onClick={() => {
            navigator.clipboard.writeText(JSON.stringify(call?.logs));
          }}
        >
          Copy RAW
        </Button>
      )}
      <div id="raw-output-container">
        <JSONPretty
          id="json-pretty"
          data={
            hasResolved
              ? !isEmpty(call?.logs)
                ? call.logs
                : "Unable to retrieve Softphone Logs"
              : "Loading..."
          }
        />
      </div>
    </Stack>
  );
};

TabLogs.propTypes = {
  call: PropTypes.object,
  hasResolved: PropTypes.bool,
};

export default TabLogs;
