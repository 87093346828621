import { SHOW_PROGRESS } from "./progress";
import { axiosProxy } from "../axios/AxiosProxy";
import {
  EmbeddedDashboard,
  DataRole,
  CreateDataRole,
  UpdateDataRole,
  UpdateDataRoleName,
} from "../models/dataRole";
import { Dispatch } from "redux";

export const ROLES_RECEIVED = "ROLES_RECEIVED";
export const USER_ROLES_RECEIVED = "USER_ROLES_RECEIVED";
export const ROLE_CREATED = "ROLE_CREATED";
export const ROLE_UPDATED = "ROLE_UPDATED";
export const ERROR_ROLES_RECEIVED = "ERROR_ROLES_RECEIVED";
export const ERROR_USER_ROLES_RECEIVED = "ERROR_USER_ROLES_RECEIVED";
export const ERROR_ROLE_CREATED = "ERROR_ROLE_CREATED";
export const ERROR_ROLE_UPDATED = "ERROR_ROLE_UPDATED";

export const DATA_ROLE_USERS_RECEIVED = "DATA_ROLE_USERS_RECEIVED";
export const DATA_ROLE_USER_ADDED = "DATA_ROLE_USER_ADDED";
export const DATA_ROLE_USER_REMOVED = "DATA_ROLE_USER_REMOVED";
export const ERROR_DATA_ROLE_USERS_RECEIVED = "ERROR_DATA_ROLE_USERS_RECEIVED";
export const ERROR_DATA_ROLE_USER_ADDED = "ERROR_DATA_ROLE_USER_ADDED";
export const ERROR_DATA_ROLE_USER_REMOVED = "ERROR_DATA_ROLE_USER_REMOVED";

export const PLAYBOOKS_RECEIVED = "PLAYBOOKS_RECEIVED";
export const ERROR_PLAYBOOKS_RECEIVED = "ERROR_PLAYBOOKS_RECEIVED";

export const showProgress = () => ({
  type: SHOW_PROGRESS,
});

export const rolesReceived = (roles: DataRole[]) => ({
  type: ROLES_RECEIVED,
  roles: roles,
});

export const userRolesReceived = (roles: DataRole[]) => ({
  type: USER_ROLES_RECEIVED,
  roles: roles,
});

export const roleCreated = () => ({
  type: ROLE_CREATED,
});

export const roleUpdated = () => ({
  type: ROLE_UPDATED,
});

export const errorRolesReceived = () => ({
  type: ERROR_ROLES_RECEIVED,
});

export const errorUserRolesReceived = () => ({
  type: ERROR_USER_ROLES_RECEIVED,
});

export const errorRoleCreated = () => ({
  type: ERROR_ROLE_CREATED,
});

export const errorRoleUpdated = () => ({
  type: ERROR_ROLE_UPDATED,
});

export const dataRoleUsersReceived = (
  dataRoleId: number,
  data: { users: string[] }
) => ({
  type: DATA_ROLE_USERS_RECEIVED,
  dataRoleId,
  users: data?.users,
});

export const dataRoleUserAdded = (dataRoleId: number, user: string) => ({
  type: DATA_ROLE_USER_ADDED,
  dataRoleId,
  user,
});

export const dataRoleUserRemoved = (dataRoleId: number, user: string) => ({
  type: DATA_ROLE_USER_REMOVED,
  dataRoleId,
  user,
});

export const errorDataRoleUsersReceived = () => ({
  type: ERROR_DATA_ROLE_USERS_RECEIVED,
});

export const errorDataRoleUserAdded = () => ({
  type: ERROR_DATA_ROLE_USER_ADDED,
});

export const errorDataRoleUserRemoved = () => ({
  type: ERROR_DATA_ROLE_USER_REMOVED,
});

export const playbooksReceived = (playbooks: EmbeddedDashboard[]) => ({
  type: PLAYBOOKS_RECEIVED,
  playbooks: playbooks,
});

export const errorPlaybooksReceived = () => ({
  type: ERROR_PLAYBOOKS_RECEIVED,
});

export const fetchRoles = () => {
  return (dispatch: Dispatch) => {
    dispatch(showProgress());
    return getRoles()
      .then((resp) => {
        dispatch(rolesReceived(resp.data));
      })
      .catch(() => {
        dispatch(errorRolesReceived());
      });
  };
};

export const fetchUserRoles = () => {
  return (dispatch: Dispatch) => {
    dispatch(showProgress());
    return getUserRoles()
      .then((resp) => {
        dispatch(userRolesReceived(resp.data));
      })
      .catch(() => {
        dispatch(errorUserRolesReceived());
      });
  };
};

export const fetchUsersForRole = (role: DataRole) => {
  return (dispatch: Dispatch) => {
    dispatch(showProgress());
    return getUsersForRole(role.id)
      .then((resp) => {
        dispatch(dataRoleUsersReceived(role.id, resp.data));
      })
      .catch(() => {
        dispatch(errorDataRoleUsersReceived());
      });
  };
};

export const addUserToRole = (role: DataRole, userId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(showProgress());
    return postUserToRole(role, userId)
      .then(() => {
        dispatch(dataRoleUserAdded(role.id, userId));
      })
      .catch(() => {
        dispatch(errorDataRoleUserAdded());
      });
  };
};

export const removeUserFromRole = (role: DataRole, userId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(showProgress());
    return deleteUserFromRole(role, userId)
      .then(() => {
        dispatch(dataRoleUserRemoved(role.id, userId));
      })
      .catch(() => {
        dispatch(errorDataRoleUserRemoved());
      });
  };
};

export const createRole = (role: CreateDataRole) => {
  return (dispatch: Dispatch) => {
    dispatch(showProgress());
    return postRole(role)
      .then(() => {
        dispatch(roleCreated());
      })
      .catch(() => {
        dispatch(errorRoleCreated());
      });
  };
};

export const updateRole = (role: UpdateDataRole) => {
  return (dispatch: Dispatch) => {
    dispatch(showProgress());
    return putRole(role)
      .then(() => {
        dispatch(roleUpdated());
      })
      .catch(() => {
        dispatch(errorRoleUpdated());
      });
  };
};

export const updateRoleName = (role: UpdateDataRoleName) => {
  return (dispatch: Dispatch) => {
    dispatch(showProgress());
    return putRoleName(role)
      .then(() => {
        dispatch(roleUpdated());
      })
      .catch(() => {
        dispatch(errorRoleUpdated());
      });
  };
};

export const fetchPlaybooks = () => {
  return (dispatch: Dispatch) => {
    dispatch(showProgress());
    return getPlaybooks()
      .then((resp) => {
        dispatch(playbooksReceived(resp.data));
      })
      .catch(() => {
        dispatch(errorPlaybooksReceived());
      });
  };
};

const getRoles = () => {
  return axiosProxy.getInstance().get("/dataRoles");
};

const getUserRoles = () => {
  return axiosProxy.getInstance().get("/dataRoles/me");
};

const getUsersForRole = (dataRoleId: number) => {
  return axiosProxy.getInstance().get("/dataRoles/" + dataRoleId + "/users");
};

const postRole = (role: CreateDataRole) => {
  return axiosProxy.getInstance().post("/dataRoles", role);
};

const putRole = (role: UpdateDataRole) => {
  return axiosProxy.getInstance().put("/dataRoles/" + role.id, {
    name: role.name,
    conditions: role.conditions,
    dashboards: role.dashboards,
  });
};

const putRoleName = (role: UpdateDataRoleName) => {
  return axiosProxy.getInstance().put("/dataRoles/" + role.id, {
    name: role.name,
  });
};

const postUserToRole = (role: DataRole, user: string) => {
  return axiosProxy
    .getInstance()
    .post("/dataRoles/" + role.id + "/users/" + user);
};

const deleteUserFromRole = (role: DataRole, user: string) => {
  return axiosProxy
    .getInstance()
    .delete("/dataRoles/" + role.id + "/users/" + user);
};

const getPlaybooks = () => {
  return axiosProxy.getInstance().get("/dashboards");
};
