const isLocalhost = Boolean(
  window.location.hostname === "app.localdev.me" ||
    window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const getAPIUrl = () => {
  if (isLocalhost) {
    return process.env.REACT_APP_API_URL;
  } else if (
    ["-alpha", "-bravo", "-charlie"].some((prefix) =>
      window.location.host.split(".")[0].endsWith(prefix)
    )
  ) {
    return (
      "https://" +
      window.location.host
        .replace(/^app/, "api")
        .replace(/-alpha/, "")
        .replace(/-bravo/, "")
        .replace(/-charlie/, "")
    );
  } else {
    return "https://" + window.location.host.replace(/^app/, "api");
  }
};
