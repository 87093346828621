import { COMPLETED_STATUS } from "../../../constants/gsm";
import React from "react";

export const getStatusLevelFromTags = (
  jobStatus,
  jobTags,
  tagsToEvaluate,
  allGoodText = "Good"
) => {
  if (jobStatus !== COMPLETED_STATUS) {
    return {
      text: "-",
    };
  }

  let status = {
    text: allGoodText,
    color: "green",
  };

  for (const tagToEvaluate of tagsToEvaluate) {
    if (jobTags && jobTags.includes(tagToEvaluate)) {
      const tagParts = tagToEvaluate.split(" ");
      if (tagParts[0] === "Very") {
        status.text = `${tagParts[0]} ${tagParts[1]}`;
        status.color = "red";
      } else {
        status.text = tagParts[0];
        status.color = "yellow";
      }

      break;
    }
  }

  return status;
};

export const createStatusLevelText = (statusLevel) => {
  return (
    <span
      className={
        statusLevel.color ? `insights__status__${statusLevel.color}` : ""
      }
    >
      {statusLevel.text}
    </span>
  );
};
