import moment from "moment";
import { ModeEnum } from "../DateTimeRangePicker";

export const generateHours = () => {
  let hours = [];
  for (let i = 0; i < 24; i++) {
    hours.push(i);
  }
  return hours;
};

export const generateMinutes = () => {
  let minutes = [];
  for (let i = 0; i < 60; i++) {
    if (i < 10) {
      minutes.push(`0${i.toString()}`);
    } else {
      minutes.push(i.toString());
    }
  }
  return minutes;
};

function workOutMonthYear(date, secondDate, mode) {
  // If both months are different months then
  // allow normal display in the calendar
  let selectedMonth = date.month();
  let otherMonth = secondDate.month();
  if (selectedMonth !== otherMonth) {
    return date;
  }
  // If both months are the same and the same year
  // have "end"/right as the month and "start"/left as -1 month
  else if (date.year() === secondDate.year() && mode === ModeEnum.start) {
    let lastMonth = JSON.parse(JSON.stringify(date));
    lastMonth = moment(lastMonth);
    lastMonth.subtract(1, "month");
    return lastMonth;
  } else {
    return date;
  }
}

export const getMonth = (date, secondDate, mode) =>
  workOutMonthYear(date, secondDate, mode).month();

export const getYear = (date, secondDate, mode) =>
  workOutMonthYear(date, secondDate, mode).year();

const getDaysBeforeStartMonday = (firstDayOfMonth) => {
  let fourtyTwoDays = [];
  let dayBeforeFirstDayOfMonth = firstDayOfMonth.day() - 1; // We dont want to include the first day of the new month
  // Case whereby day before is a Saturday (6) and we require Saturday back to Monday for that week
  if (dayBeforeFirstDayOfMonth === -1) {
    for (let i = 6; i > 0; i--) {
      let firstDayOfMonthCopy = firstDayOfMonth.clone();
      firstDayOfMonthCopy = firstDayOfMonthCopy.subtract(i, "d");
      fourtyTwoDays.push(firstDayOfMonthCopy);
    }
  }
  // Case Whereby day before first day is the Sunday (0), therefore we want the entire previous week
  if (dayBeforeFirstDayOfMonth === 0) {
    for (let i = 7; i > 0; i--) {
      let firstDayOfMonthCopy = firstDayOfMonth.clone();
      firstDayOfMonthCopy = firstDayOfMonthCopy.subtract(i, "d");
      fourtyTwoDays.push(firstDayOfMonthCopy);
    }
  }
  // Every other day
  else {
    for (let i = dayBeforeFirstDayOfMonth; i > 0; i--) {
      let firstDayOfMonthCopy = firstDayOfMonth.clone();
      firstDayOfMonthCopy = firstDayOfMonthCopy.subtract(i, "d");
      fourtyTwoDays.push(firstDayOfMonthCopy);
    }
  }
  return fourtyTwoDays;
};

const getDaysBeforeStartSunday = (firstDayOfMonth) => {
  let fourtyTwoDays = [];
  let dayBeforeFirstDayOfMonth = firstDayOfMonth.day() - 1; // We dont want to include the first day of the new month

  // Case whereby we need all previous week days
  if (dayBeforeFirstDayOfMonth === -1) {
    for (let i = 7; i > 0; i--) {
      let firstDayOfMonthCopy = firstDayOfMonth.clone();
      firstDayOfMonthCopy = firstDayOfMonthCopy.subtract(i, "d");
      fourtyTwoDays.push(firstDayOfMonthCopy);
    }
  }
  // Every other day
  else {
    for (let i = dayBeforeFirstDayOfMonth + 1; i > 0; i--) {
      let firstDayOfMonthCopy = firstDayOfMonth.clone();
      firstDayOfMonthCopy = firstDayOfMonthCopy.subtract(i, "d");
      fourtyTwoDays.push(firstDayOfMonthCopy);
    }
  }
  return fourtyTwoDays;
};

const getDaysBeforeStart = (firstDayOfMonth, sundayFirst) => {
  if (!sundayFirst) {
    return getDaysBeforeStartMonday(firstDayOfMonth);
  } else {
    return getDaysBeforeStartSunday(firstDayOfMonth);
  }
};

export const getFourtyTwoDays = (initMonth, initYear, sundayFirst) => {
  let fourtyTwoDays = [];
  let firstDayOfMonth = moment(new Date(initYear, initMonth, 1));

  fourtyTwoDays = getDaysBeforeStart(firstDayOfMonth, sundayFirst);
  // Add in all days this month
  for (let i = 0; i < firstDayOfMonth.daysInMonth(); i++) {
    fourtyTwoDays.push(firstDayOfMonth.clone().add(i, "d"));
  }
  // Add in all days at the end of the month until last day of week seen
  let lastDayOfMonth = moment(
    new Date(initYear, initMonth, firstDayOfMonth.daysInMonth())
  );
  let toAdd = 1;
  let gotAllDays = false;
  while (!gotAllDays) {
    if (fourtyTwoDays.length >= 42) {
      gotAllDays = true;
      break;
    }
    fourtyTwoDays.push(lastDayOfMonth.clone().add(toAdd, "d"));
    toAdd++;
  }
  return fourtyTwoDays;
};

export const isInbetweenDates = (isStartDate, dayToFindOut, start, end) => {
  let isInBetweenDates;
  if (isStartDate) {
    isInBetweenDates =
      dayToFindOut.isAfter(start) && dayToFindOut.isBefore(end);
  } else {
    isInBetweenDates =
      dayToFindOut.isBefore(start) && dayToFindOut.isAfter(end);
  }
  return isInBetweenDates;
};

export const isValidTimeChange = (mode, date, start, end) => {
  let modeStartAndDateSameOrBeforeStart =
    mode === "start" && date.isSameOrBefore(end);
  let modeEndAndDateSameOrAfterEnd =
    mode === "end" && date.isSameOrAfter(start);
  return modeStartAndDateSameOrBeforeStart || modeEndAndDateSameOrAfterEnd;
};

export const startDateStyle = () => ({
  borderRadius: "50%",
  borderColour: "transparent",
  color: "#fff",
  backgroundImage: "linear-gradient(134deg, #3023ae, #c86dd7)",
  cursor: "pointer",
});

export const endDateStyle = () => ({
  borderRadius: "50%",
  borderColour: "transparent",
  color: "#fff",
  backgroundImage: "linear-gradient(134deg, #3023ae, #c86dd7)",
  cursor: "pointer",
});

export const inBetweenStyle = () => ({
  borderRadius: "4px 4px 4px 4px",
  borderColour: "transparent",
  color: "black",
  backgroundColor: "#ebf4f8",
  cursor: "pointer",
});

export const normalCellStyle = () => ({
  borderRadius: "0 0 0 0",
  borderColour: "transparent",
  color: "black",
  backgroundColor: "",
});

export const hoverCellStyle = (between) => {
  let borderRadius = "4px 4px 4px 4px";
  if (between) {
    borderRadius = "0 0 0 0";
  }
  return {
    borderRadius: borderRadius,
    borderColour: "transparent",
    color: "white",
    backgroundColor: "#eee",
    cursor: "pointer",
  };
};

export const greyCellStyle = () => {
  let borderRadius = "4px 4px 4px 4px";
  return {
    borderRadius: borderRadius,
    borderColour: "transparent",
    color: "#999",
    backgroundColor: "#fff",
    cursor: "pointer",
    opacity: "0.25",
  };
};

export const invalidStyle = () => {
  let style = greyCellStyle();
  style.cursor = "not-allowed";
  return style;
};

export const getRanges = (now) => {
  return {
    Today: [
      moment().startOf("day"),
      moment().endOf("day"),
      "(from:now/d,to:now)",
      1,
      "now/d",
      "now",
    ],
    "This week": [
      moment().startOf("week"),
      moment().startOf("week"),
      "(from:now/w,to:now)",
      1,
      "now/w",
      "now",
    ],
    "This month": [
      moment().startOf("month"),
      moment().startOf("month"),
      "(from:now/M,to:now)",
      1,
      "now/M",
      "now",
    ],
    "This year": [
      moment().startOf("year"),
      moment().startOf("year"),
      "(from:now/y,to:now)",
      1,
      "now/y",
      "now",
    ],
    "Last 15 minutes": [
      moment(now).subtract(15, "minutes"),
      moment(now),
      "(from:now-15m,to:now)",
      2,
      "now-15m",
      "now",
    ],
    "Last 30 minutes": [
      moment(now).subtract(30, "minutes"),
      moment(now),
      "(from:now-30m,to:now)",
      2,
      "now-30m",
      "now",
    ],
    "Last 1 hour": [
      moment(now).subtract(1, "hour"),
      moment(now),
      "(from:now-1h,to:now)",
      2,
      "now-1h",
      "now",
    ],
    "Last 4 hours": [
      moment(now).subtract(4, "hours"),
      moment(now),
      "(from:now-4h,to:now)",
      2,
      "now-4h",
      "now",
    ],
    "Last 12 hours": [
      moment(now).subtract(12, "hours"),
      moment(now),
      "(from:now-12h,to:now)",
      2,
      "now-12h",
      "now",
    ],
    "Last 24 hours": [
      moment(now).subtract(24, "hours"),
      moment(now),
      "(from:now-24h,to:now)",
      2,
      "now-24h",
      "now",
    ],
    "Last 7 days": [
      moment(now).subtract(7, "days"),
      moment(now),
      "(from:now-7d,to:now)",
      2,
      "now-7d",
      "now",
    ],
    "Last 30 days": [
      moment(now).subtract(30, "days"),
      moment(now),
      "(from:now-30d,to:now)",
      3,
      "now-30d",
      "now",
    ],
    "Last 60 days": [
      moment(now).subtract(60, "days"),
      moment(now),
      "(from:now-60d,to:now)",
      3,
      "now-60d",
      "now",
    ],
    "Last 90 days": [
      moment(now).subtract(90, "days"),
      moment(now),
      "(from:now-90d,to:now)",
      3,
      "now-90d",
      "now",
    ],
    "Last 6 months": [
      moment(now).subtract(6, "months"),
      moment(now),
      "(from:now-6M,to:now)",
      3,
      "now-6M",
      "now",
    ],
    "Last 1 year": [
      moment(now).subtract(1, "year"),
      moment(now),
      "(from:now-1y,to:now)",
      3,
      "now-1y",
      "now",
    ],
    "Last 2 years": [
      moment(now).subtract(2, "years"),
      moment(now),
      "(from:now-2y,to:now)",
      3,
      "now-2y",
      "now",
    ],
    "Last 5 years": [
      moment(now).subtract(5, "years"),
      moment(now),
      "(from:now-5y,to:now)",
      3,
      "now-5y",
      "now",
    ],
  };
};
