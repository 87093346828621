import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import * as PropTypes from "prop-types";
import CardAlternative from "../../Card/CardAlternative";
import { diffWords } from "diff";

import _ from "lodash";

function getTranscriptionByJobName(transcriptions, jobName) {
  if (transcriptions != null) {
    return _.find(transcriptions, ["transcriptionJobName", jobName]);
  }
}

function calculateSubstitutions(substitutions) {
  if (substitutions == null) return 0;

  return _.sumBy(substitutions, "count");
}

function sumValues(obj) {
  if (obj == null) return 0;

  const map = _.map(obj, (value, prop) => ({ prop, value }));
  return _.sumBy(map, "value");
}

function sanitiseText(props) {
  return props.text.toLowerCase().replace(/[^\w\s!?]/g, "");
}

function DiffText(props) {
  const diff = diffWords(props.refText, sanitiseText(props), {
    ignoreCase: true,
    ignoreWhitespace: true,
  });

  return (
    <div className={"textDiff"}>
      {diff.map((part, index) => {
        const color = part.added ? "red" : part.removed ? "green" : "grey";
        const background = part.added ? "yellow" : part.removed ? "yellow" : "";
        return (
          <span
            key={"diff" + index}
            style={{ color: color, background: background }}
          >
            {part.value}
          </span>
        );
      })}
    </div>
  );
}

DiffText.propTypes = { refText: PropTypes.string };

function TranscriptionPerformanceSection(props) {
  const transcription = props.transcription;
  const wer =
    (transcription &&
      transcription.asrEvaluation &&
      transcription.asrEvaluation.wer) ||
    "";
  const languageCode = (transcription && transcription.languageCode) || "";
  const substitutions =
    (transcription &&
      transcription.asrEvaluation &&
      transcription.asrEvaluation.substitutions) ||
    [];
  const wrr =
    (transcription &&
      transcription.asrEvaluation &&
      transcription.asrEvaluation.wrr) ||
    "";
  const insertions =
    (transcription &&
      transcription.asrEvaluation &&
      transcription.asrEvaluation.insertions) ||
    {};
  const deletions =
    transcription &&
    transcription.asrEvaluation &&
    transcription.asrEvaluation.deletions;

  return (
    <List>
      <ListItem>
        <b>{props.title}</b>
      </ListItem>

      <ListItem>
        <div className="campaigns__grid">
          <List>
            <ListItem>
              <ListItemText primary="Word Error Rate" secondary={wer} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Global Average" secondary={"N/A"} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Language" secondary={languageCode} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Substitutions"
                secondary={calculateSubstitutions(substitutions)}
              />
            </ListItem>
          </List>

          <List>
            <ListItem>
              <ListItemText primary="Word Recognition Rate" secondary={wrr} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Global Average" secondary={"N/A"} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Insertions"
                secondary={sumValues(insertions)}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Deletions"
                secondary={sumValues(deletions)}
              />
            </ListItem>
          </List>
        </div>
      </ListItem>
    </List>
  );
}

TranscriptionPerformanceSection.propTypes = {
  title: PropTypes.string,
  transcription: PropTypes.any,
};

function getTranscriptionText(transcripts) {
  if (transcripts == null || transcripts.length <= 0) return "";
  return transcripts[0].transcript;
}

function Transcriptions(props) {
  const refText =
    "the glow deepened in the eyes of the sweet girl the lamp shone with a steady green flame the glow deepened in the eyes of the sweet girl the lamp shone with a steady green flame";
  return (
    <List>
      <ListItem>
        <ListItemText primary="Reference Audio Text" secondary={refText} />
      </ListItem>
      <ListItem>
        <ListItemText primary="Static Transcription Performance (AWS Transcribe)" />
      </ListItem>
      <ListItem>
        <DiffText
          refText={refText}
          text={getTranscriptionText(props.staticTranscripts)}
        />
      </ListItem>
      <ListItem>
        <ListItemText primary="Streaming Transcription Performance (AWS Transcribe)" />
      </ListItem>
      <ListItem>
        <DiffText
          refText={refText}
          text={getTranscriptionText(props.streamingTranscripts)}
        />
      </ListItem>
    </List>
  );
}

Transcriptions.propTypes = {
  staticTranscripts: PropTypes.any,
  streamingTranscripts: PropTypes.any,
};

function TranscriptionPerformance(props) {
  const staticTranscription = getTranscriptionByJobName(
    props.transcription,
    "Transcribe_AWS_Connect_Customer"
  );
  const streamingTranscription = getTranscriptionByJobName(
    props.transcription,
    "Streaming_AWS_Connect_Customer"
  );

  const staticTranscripts =
    (staticTranscription && staticTranscription.transcripts) || [];

  const streamingTranscripts =
    (streamingTranscription && streamingTranscription.transcripts) || [];

  return (
    <>
      <CardAlternative
        title={"Transcription Performance"}
        className={"transcriptionPerformance"}
      >
        <div className="campaigns__grid">
          <TranscriptionPerformanceSection
            transcription={staticTranscription}
            title={"Static Transcription Performance (AWS Transcribe)"}
          />
          <TranscriptionPerformanceSection
            transcription={streamingTranscription}
            title={"Streaming Transcription Performance (AWS Transcribe)"}
          />
        </div>
      </CardAlternative>
      <CardAlternative
        title={"Substitutions, Insertions and Deletions"}
        key={"substitutions"}
      >
        <Transcriptions
          staticTranscripts={staticTranscripts}
          streamingTranscripts={streamingTranscripts}
        />
      </CardAlternative>
    </>
  );
}

TranscriptionPerformance.propTypes = { transcription: PropTypes.array };

function IntelligenceQualityTab(props) {
  const { jobDetails } = props;
  const transcription = (jobDetails && jobDetails.transcription) || [];

  return (
    <>
      {jobDetails != null && jobDetails.transcription != null && (
        <TranscriptionPerformance transcription={transcription} />
      )}
    </>
  );
}

IntelligenceQualityTab.propTypes = { jobDetails: PropTypes.any };

export default IntelligenceQualityTab;
