import React, { useEffect, useState } from "react";
import InsightsDetails from "./InsightsDetails";
import { Filter, Time } from "@operata/ui-components";
import InsightsChart from "./InsightsChart";
import { getTimestampCriteria } from "../../../utils/datatables";
import { fetchLogs } from "../../../actions/gsm";
import { useDispatch, useSelector } from "react-redux";
import "./Insights.scss";

const Insights = () => {
  const dispatch = useDispatch();
  const { logs } = useSelector((state) => state.gsm);
  const [filters, setFilters] = useState({
    timestamp: "time:(from:now-4h,to:now)",
    connectRegions: [],
    agentRegions: [],
  });

  useEffect(() => {
    const timeCriteria = getTimestampCriteria(filters.timestamp);
    const regionsCriteria = {
      connectRegions: filters.connectRegions,
      agentRegions: filters.agentRegions,
    };
    const criteria = Object.assign({}, timeCriteria, regionsCriteria);

    dispatch(fetchLogs(criteria));
  }, [filters]);

  const handleFilterReset = () => {
    setFilters({
      ...filters,
      connectRegions: [],
      agentRegions: [],
    });
  };

  const handleFilterUpdate = (index, filterValue, fieldName) => {
    if (fieldName === "timestamp") {
      setFilters({
        ...filters,
        timestamp: filterValue.dateRange,
      });
    } else {
      setFilters({
        ...filters,
        [fieldName]: filterValue,
      });
    }
  };

  return (
    <React.Fragment>
      <Filter
        filters={[
          {
            name: "timestamp",
            label: "Timestamp",
            type: "custom",
            // eslint-disable-next-line react/display-name
            display: (filteredData, onChange, index, filter) => {
              return (
                <Time
                  filterCallback={(
                    dateRange,
                    selectedRange,
                    selectedRangeDisplay
                  ) => {
                    onChange(
                      {
                        dateRange,
                        selectedRange,
                        selectedRangeDisplay,
                      },
                      index,
                      filter
                    );
                  }}
                  selectedRange={
                    filteredData ? filteredData.selectedRange : "Last 4 hours"
                  }
                  selectedRangeDisplay={
                    filteredData
                      ? filteredData.selectedRangeDisplay
                      : "Last 4 hours"
                  }
                  selectedRefreshRate={1}
                  autoRefresh={false}
                  showAutoRefresh={false}
                />
              );
            },
          },
          {
            label: "Connect Regions",
            name: "connectRegions",
            options: [
              "North Virginia",
              "Oregon",
              "Tokyo",
              "Singapore",
              "Sydney",
              "Frankfurt",
              "London",
            ],
            type: "multiselect",
          },
          {
            label: "Agent Regions",
            name: "agentRegions",
            options: [
              "North Virginia",
              "Los Angeles",
              "Tokyo",
              "Mumbai",
              "Sydney",
              "Frankfurt",
              "London",
            ],
            type: "multiselect",
          },
        ]}
        onFilterReset={handleFilterReset}
        onFilterUpdate={handleFilterUpdate}
      />

      <InsightsChart logs={logs} />
      <InsightsDetails filters={filters} logs={logs} />
    </React.Fragment>
  );
};

export default Insights;
