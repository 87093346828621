import React from "react";
import { connect } from "react-redux";
import "moment-timezone";
import PropTypes from "prop-types";
import { Button, Modal, Time, MUIDataTable } from "@operata/ui-components";
import { formatDate } from "../../../utils/date";
import { showConfirmationDialog } from "../../../actions/dialog";
import {
  showSnackbarMessage,
  SNACKBAR_ERROR,
  SNACKBAR_INFO,
} from "../../../actions/snackbar";
import {
  ERROR_MONITORS,
  MONITOR_DISABLED,
  fetchMonitors,
  disableMonitor,
  MONITOR_CREATED,
  resetMonitorsPageState,
  newMonitor,
  NEW_MONITOR,
} from "../../../actions/gsm";
import Pagination from "../../Pagination/Pagination";
import CreateMonitorWizard from "./CreateMonitor/CreateMonitorWizard";
import { getTimestampCriteria } from "../../../utils/datatables";
import { throttle } from "lodash";

class MonitorsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [[], [], []],
      filterDisplayTexts: [[], [], []],
      timestampFilterKey: 0,
      modalOpen: false,
    };
    this.fetchMonitorsThrottled = throttle(this.fetchMonitorsInternal, 2000);
  }

  componentDidMount() {
    this.props.fetchMonitors(
      getTimestampCriteria("time:(from:now-30d,to:now)")
    );
  }

  static getDerivedStateFromProps(props) {
    let newData = props.monitorData;

    let { showSnackbarMessage } = props;

    if (props.status === ERROR_MONITORS) {
      showSnackbarMessage(
        SNACKBAR_ERROR,
        "An error occurred while retrieving Monitors."
      );
    }
    if (props.status === MONITOR_DISABLED) {
      newData.map((item) => {
        if (item.monitorId && item.monitorId === props.disabledId) {
          item.active = false;
        }
      });
      showSnackbarMessage(SNACKBAR_INFO, "Monitor is successfully disabled");
      return { monitors: newData };
    }
    if (props.status === MONITOR_CREATED) {
      return { modalOpen: false };
    }
    if (props.status === NEW_MONITOR) {
      return { modalOpen: true };
    }

    return null;
  }

  fetchMonitorsInternal(criteria) {
    this.props.fetchMonitors(criteria);
  }

  onCloseModal = () => {
    this.setState({ modalOpen: false });
    this.props.resetMonitorsPageState();
  };

  displayNextRun = (item) => {
    if (!item.active) {
      return "Disabled";
    }
    if (!item.nextRun) {
      return "Not scheduled";
    }
    return item.nextRun;
  };

  handleDisableMonitor(event, monitorId) {
    this.props.showConfirmationDialog(
      "Disable Monitor",
      "Are you sure you want to disable this Monitor?",
      this.props.disableMonitor,
      [monitorId],
      this.handleCancelDisableMonitor,
      []
    );
    event.preventDefault();
  }

  handleCancelDisableMonitor() {
    //do nothing
  }

  handleNewMonitor = () => {
    this.props.newMonitor();
  };

  render() {
    const data = this.props.monitorData;

    const columns = [
      {
        name: "timestamp",
        label: "Timestamp",
        options: {
          showColumn: false,
          filter: true,
          filterType: "custom",
          customFilterListRender: (value) => {
            if (value.length > 0) {
              return this.state.filterDisplayTexts[0];
            } else {
              return "";
            }
          },
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              let selectedRange = "Last 30 days";
              let selectedRangeDisplay = "Last 30 days";
              let filterDisplay = [];

              return (
                <Time
                  key={this.state.timestampFilterKey}
                  filterCallback={(
                    dateRange,
                    selectedRange,
                    selectedRangeDisplay
                  ) => {
                    filterDisplay[index] = selectedRangeDisplay;
                    filterList[index] = dateRange;
                    this.setState({ filterDisplayTexts: filterDisplay });
                    onChange(filterList[index], index, column);
                  }}
                  selectedRange={selectedRange}
                  selectedRangeDisplay={selectedRangeDisplay}
                  selectedRefreshRate={1}
                  autoRefresh={false}
                  showAutoRefresh={false}
                />
              );
            },
            logic: () => {
              return false;
            },
          },

          filterList: this.state.filters[0],
        },
      },
      {
        name: "Monitor ID",
        label: "Monitor ID",
        options: {
          filter: true,
          customFilterListRender: (value) => `Monitor ID: ${value}`,
          sort: true,
          customBodyRender: (value) => {
            return <div className="textfield">{value}</div>;
          },
        },
      },
      {
        name: "Connect Regions",
        label: "Connect Regions",
        options: {
          filter: true,
          customFilterListRender: (value) => `Connect Regions: ${value}`,
          sort: true,
          customBodyRender: (value) => {
            return <div className="textfield">{value}</div>;
          },
        },
      },
      {
        name: "Agent Regions",
        label: "Agent Regions",
        options: {
          filter: true,
          customFilterListRender: (value) => `Agent Regions: ${value}`,
          sort: true,
          customBodyRender: (value) => {
            return <div className="textfield">{value}</div>;
          },
        },
      },
      {
        name: "Created On",
        label: "Created On",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div className="textfield">{formatDate(value)}</div>;
          },
        },
      },
      {
        name: "Last Run",
        label: "Last Run",
        options: {
          sortDirection: "desc",
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            //handling undefined values in last_run column so that they are not auto-formatted by formatDate() since formatDate() converts undefined dates to current dates
            value = value ? value : "";
            return <div className="textfield">{formatDate(value)}</div>;
          },
        },
      },
      {
        name: "Next Schedule",
        label: "Next Schedule",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div className="textfield">{value}</div>;
          },
        },
      },
      {
        name: "Actions",
        label: "Actions",
        options: {
          filter: false,
          download: false,
          sort: false,
          customBodyRender: (item) => {
            return (
              <React.Fragment>
                {item.active && (
                  <span
                    id="actionSpan"
                    className="textfield-bold actions__link"
                    onClick={(event) => {
                      this.handleDisableMonitor(event, item.monitorId);
                    }}
                  >
                    Disable
                  </span>
                )}
              </React.Fragment>
            );
          },
        },
      },
    ];

    const options = {
      selectableRows: "none",
      filter: true,
      filterType: "dropdown",
      fixedHeader: false,
      download: true,
      print: false,
      search: true,
      viewColumns: true,
      rowsPerPage: 10,
      customFooter: (
        count,
        page,
        rowsPerPage,
        changeRowsPerPage,
        changePage
      ) => {
        return (
          <Pagination
            className="pagination__wrapper"
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={(event) =>
              changeRowsPerPage(event.target.value)
            }
            onChangePage={(_, page) => changePage(page)}
          />
        );
      },
      onFilterChange: (column, filterList) => {
        if (column === "timestamp") {
          this.fetchMonitorsThrottled(getTimestampCriteria(filterList[0]));
        }
      },
      //including customSort as it would enable sorting of arrays such as Agent Regions and Connect Regions as well
      customSort: (data, colIndex, order) => {
        return data.sort((a, b) => {
          return (
            (a.data[colIndex] < b.data[colIndex] ? -1 : 1) *
            (order === "asc" ? 1 : -1)
          );
        });
      },
    };

    return (
      <>
        <Button
          className={"button button--right"}
          buttonText={"New Monitor"}
          onClick={this.handleNewMonitor}
          hidePlus={false}
        />
        <Modal
          className="hidden"
          title={"Create Monitor"}
          isModalOpen={this.state.modalOpen}
          onCloseModal={this.onCloseModal}
          modalSize={"300px"}
        >
          <CreateMonitorWizard />
        </Modal>

        <MUIDataTable
          data={data.map((item) => {
            return [
              "",
              item.monitorId,
              item.connectRegions,
              item.agentRegions,
              item.createdOn,
              item.lastRun,
              this.displayNextRun(item),
              item,
            ];
          })}
          columns={columns}
          options={options}
        />
      </>
    );
  }
}

MonitorsList.propTypes = {
  monitorData: PropTypes.array,
  status: PropTypes.string,
  showSnackbarMessage: PropTypes.func,
  actionPath: PropTypes.string,
  fetchMonitors: PropTypes.func,
  disableMonitor: PropTypes.func,
  showConfirmationDialog: PropTypes.func,
  disabledId: PropTypes.number,
  resetMonitorsPageState: PropTypes.func,
  newMonitor: PropTypes.func,
};

const mapDispatchToProps = {
  fetchMonitors: fetchMonitors,
  showSnackbarMessage: showSnackbarMessage,
  disableMonitor: disableMonitor,
  showConfirmationDialog: showConfirmationDialog,
  resetMonitorsPageState: resetMonitorsPageState,
  newMonitor: newMonitor,
};

const mapStateToProps = (state) => ({
  monitorData: state.gsm.monitors || [],
  status: state.gsm.status,
  disabledId: state.gsm.disabledId,
});

export default connect(mapStateToProps, mapDispatchToProps)(MonitorsList);
