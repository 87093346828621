import PropTypes from "prop-types";
import React from "react";
import Chip from "../../Chips/Chip";
import moment from "moment";
import InsightsTable from "./InsightsTable";
import { NavLink } from "react-router-dom";
import { NETWORK_PERFORMANCE_TAGS } from "../../../constants/gsm";

const NetworkPerformance = ({ logs }) => {
  const columns = [
    {
      name: "timestamp",
      label: "Timestamp",
      options: {
        // eslint-disable-next-line react/display-name
        customBodyRender: (job) => {
          return (
            <NavLink
              className="textfield-bold actions__link"
              to={`/gsm/jobs/${job.jobId}/report`}
            >
              {moment(job.createdOn).format("DD-MM-YYYY HH:mm:ss")}
            </NavLink>
          );
        },
      },
    },
    {
      name: "connectRegion",
      label: "Connect Region",
    },
    {
      name: "agentRegion",
      label: "Agent Region",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "tags",
      label: "Tags",
      options: {
        customBodyRender: (tags) => {
          let chips = [];
          if (tags) {
            tags.forEach((tag) => {
              chips.push(<Chip label={tag} key={tag} />);
            });
          }
          return chips;
        },
      },
    },
  ];

  const data = logs.filter((log) => {
    for (const tag of NETWORK_PERFORMANCE_TAGS) {
      if (log.tags && log.tags.includes(tag)) {
        return true;
      }
    }
    return false;
  });

  return (
    <InsightsTable
      logs={data}
      tags={NETWORK_PERFORMANCE_TAGS}
      columns={columns}
      dataToColumn={(result) => {
        return [
          result,
          result.connectRegion,
          result.agentRegion,
          result.status,
          result.tags,
        ];
      }}
    />
  );
};

NetworkPerformance.propTypes = {
  logs: PropTypes.array,
};

export default NetworkPerformance;
