import React, { useEffect, useState } from "react";
import Time from "../DateTime/TimeContainer";
import moment from "moment";
import { MUIDataTable, Button } from "@operata/ui-components";
import { getTimestampCriteria } from "../../utils/datatables";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadAuditLogsFetched,
  fetchAuditLogs,
  getAuditLogs,
  showProgress,
} from "../../actions/audit";
import Pagination from "../Pagination/Pagination";
import GetAppIcon from "@material-ui/icons/GetApp";

const defaultTimeFilter = "time:(from:now-1h,to:now)";

const AuditLogs = () => {
  const dispatch = useDispatch();
  const { auditLogs, auditLogsRecCount } = useSelector((state) => state.audit);
  const [filters, setFilters] = useState([defaultTimeFilter]);
  const [timestampFilterKey, setTimestampFilterKey] = useState(0);
  const [timeFilterDisplay, setTimeFilterDisplay] = useState("Last 1 hour");

  useEffect(() => {
    dispatch(fetchAuditLogs(getSearchCriteria(filters)));
  }, []);

  const getSearchCriteria = (filterList, page = 0, rowsPerPage = 10) => {
    const timeCriteria = getTimestampCriteria(
      filterList[0].length > 0 ? filterList[0] : defaultTimeFilter
    );
    const serviceCriteria = {
      service: filterList[1] && filterList[1][0],
    };
    const idCriteria = {
      id: filterList[2] && filterList[2][0],
    };
    const paginationCriteria = {
      from: page * rowsPerPage,
      size: rowsPerPage,
    };

    return Object.assign(
      {},
      timeCriteria,
      serviceCriteria,
      idCriteria,
      paginationCriteria
    );
  };

  const handleLogDownload = async () => {
    dispatch(showProgress());

    let logs = [];
    let page = 0;
    let batchSize = 1000; // download in batches of 1000
    do {
      const results = await getAuditLogs(
        getSearchCriteria(filters, page, batchSize)
      );
      logs.push(...results.data);
      page++;
    } while (page * batchSize < auditLogsRecCount);

    createJSONDownload(logs);

    dispatch(downloadAuditLogsFetched());
  };

  const createJSONDownload = (logs) => {
    const blob = new Blob([JSON.stringify(logs)], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "audit-logs.json";
    a.click();
    a.remove();
  };

  const columns = [
    {
      name: "time",
      label: "Time",
      options: {
        filter: true,
        filterType: "custom",
        filterList: filters[0],
        filterOptions: {
          // eslint-disable-next-line react/display-name
          display: (filterList, onChange, index, column) => {
            let selectedRange = "Last 1 hour";
            let selectedRangeDisplay = "Last 1 hour";

            return (
              <Time
                key={timestampFilterKey}
                filterCallback={(
                  dateRange,
                  selectedRange,
                  selectedRangeDisplay
                ) => {
                  filterList[index] = dateRange;
                  setTimeFilterDisplay(selectedRangeDisplay);
                  onChange(filterList[index], index, column);
                }}
                selectedRange={selectedRange}
                selectedRangeDisplay={selectedRangeDisplay}
                selectedRefreshRate={1}
                autoRefresh={false}
                showAutoRefresh={false}
              />
            );
          },
          logic: () => {
            return false;
          },
        },
        customFilterListRender: (value) => {
          if (value.length > 0) {
            return timeFilterDisplay;
          } else {
            return "";
          }
        },
        customBodyRender: (timestamp) => {
          return moment(timestamp).format("DD-MM-YYYY HH:mm:ss");
        },
      },
    },
    {
      name: "Service",
      label: "Service",
      options: {
        filter: true,
        filterList: filters[1],
        customFilterListRender: (v) => `Service: ${v}`,
      },
    },
    {
      name: "Job ID",
      label: "Job ID",
      options: {
        filter: true,
        filterList: filters[2],
        customFilterListRender: (v) => `Job ID: ${v}`,
      },
    },
    {
      name: "message",
      label: "Message",
      options: {
        filter: false,
      },
    },
    {
      name: "exception",
      label: "Exception",
      options: {
        filter: false,
      },
    },
  ];

  const resultOptions = {
    filter: true,
    download: false,
    search: false,
    sort: false,
    selectableRows: "none",
    serverSide: true,
    count: auditLogsRecCount,
    page: 0,
    rowsPerPage: 100,
    // eslint-disable-next-line react/display-name
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return (
        <Pagination
          className="pagination__wrapper"
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 100, 500]}
          onChangeRowsPerPage={(event) => changeRowsPerPage(event.target.value)}
          onChangePage={(_, page) => changePage(page)}
        />
      );
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changeRowsPerPage":
          dispatch(
            fetchAuditLogs(
              getSearchCriteria(
                tableState.filterList,
                tableState.page,
                tableState.rowsPerPage
              )
            )
          );
          break;
        case "changePage":
          dispatch(
            fetchAuditLogs(
              getSearchCriteria(
                tableState.filterList,
                tableState.page,
                tableState.rowsPerPage
              )
            )
          );
          break;
      }
    },
    onFilterChange: (column, filterList) => {
      if (filterList[0].length === 0) {
        setTimestampFilterKey(Math.round(Math.random() * 10000));
      }

      setFilters(filterList);
      dispatch(fetchAuditLogs(getSearchCriteria(filterList)));
    },
  };

  return (
    <React.Fragment>
      <Button
        className="button button--right"
        buttonText={
          <>
            <span className="button__circle">
              <GetAppIcon color={"primary"} fontSize={"small"} />
            </span>
            Download Logs
          </>
        }
        hidePlus={true}
        onClick={handleLogDownload}
      />
      <MUIDataTable
        data={
          auditLogs &&
          auditLogs.map((result) => {
            return [
              result._source.timestamp,
              result._source.service,
              result._source.id,
              result._source.message,
              result._source.exception,
            ];
          })
        }
        columns={columns}
        options={resultOptions}
      />
    </React.Fragment>
  );
};

export default AuditLogs;
