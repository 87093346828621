import React from "react";
import { SecondarySidebar, Badge } from "@operata/adagio";
import { EmbeddedDashboard } from "../../models/dataRole";
import { useRouteMatch } from "react-router-dom";
import {
  auth,
  USER_ROLE_ADMIN,
  USER_ROLE_SUPER,
  USER_ROLE_USER,
} from "../../auth/Auth";

interface PlaybookSidebarProps {
  playbooks?: EmbeddedDashboard[];
}

const PlaybookSidebar = ({ playbooks }: PlaybookSidebarProps) => {
  const { path } = useRouteMatch();

  return (
    <SecondarySidebar>
      <SecondarySidebar.Scroll>
        <div className={"playbooks-sidebar-title"}>
          <SecondarySidebar.Section heading={"Dashboards"}>
            {playbooks?.map((playbook) => (
              <SecondarySidebar.Item
                exact
                path={`${path}/${playbook.id}`}
                key={playbook.id}
              >
                {playbook.name ? playbook.name : `Dashboard ${playbook.id}`}
              </SecondarySidebar.Item>
            ))}
          </SecondarySidebar.Section>
        </div>
      </SecondarySidebar.Scroll>
      {auth.hasPermission([USER_ROLE_ADMIN, USER_ROLE_SUPER]) && (
        <SecondarySidebar.Footer>
          <SecondarySidebar.Item
            exact
            path={`${path}/roles`}
            badge={<Badge type={"info"}>Admin</Badge>}
          >
            Manage Access
          </SecondarySidebar.Item>
        </SecondarySidebar.Footer>
      )}
    </SecondarySidebar>
  );
};

export default PlaybookSidebar;
