import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import Alert, {
  ALERT_SUCCESS,
  ALERT_WARNING,
  ALERT_ERROR,
  ALERT_INFO,
} from "../Alert/Alert";
import "./Snackbar.scss";

import {
  SNACKBAR_SUCCESS,
  SNACKBAR_WARNING,
  SNACKBAR_ERROR,
  SNACKBAR_INFO,
  SNACKBAR_OPENED,
  closeSnackbar,
} from "../../actions/snackbar";

const alertMapping = {
  [SNACKBAR_SUCCESS]: ALERT_SUCCESS,
  [SNACKBAR_WARNING]: ALERT_WARNING,
  [SNACKBAR_ERROR]: ALERT_ERROR,
  [SNACKBAR_INFO]: ALERT_INFO,
};

class SnackbarMessage extends Component {
  render() {
    let { status, message, messageType, closeSnackbar } = this.props;

    const severity = alertMapping[messageType];

    return (
      <div className="snackbar__wrapper">
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={status === SNACKBAR_OPENED}
          autoHideDuration={100000}
          onClose={closeSnackbar}
          ContentProps={{
            "aria-describedby": "message-id",
            classes: { root: "snackbar__content" },
          }}
        >
          <Alert onClose={closeSnackbar} severity={severity}>
            {message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

SnackbarMessage.propTypes = {
  closeSnackbar: PropTypes.func,
  status: PropTypes.string.isRequired,
  message: PropTypes.string,
  messageType: PropTypes.oneOf([
    SNACKBAR_SUCCESS,
    SNACKBAR_WARNING,
    SNACKBAR_ERROR,
    SNACKBAR_INFO,
  ]).isRequired,
};

const mapDispatchToProps = { closeSnackbar: closeSnackbar };

const mapStateToProps = (state) => ({
  status: state.snackbar.status,
  messageType: state.snackbar.messageType,
  message: state.snackbar.message,
});

// eslint-disable-next-line no-class-assign
SnackbarMessage = connect(mapStateToProps, mapDispatchToProps)(SnackbarMessage);

export default SnackbarMessage;
