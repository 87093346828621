import React from "react";
import PropTypes from "prop-types";

class MonthYearSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      monthFocus: false,
      yearFocus: false,
    };

    this.monthFocus = this.monthFocus.bind(this);
    this.yearFocus = this.yearFocus.bind(this);
    this.monthBlur = this.monthBlur.bind(this);
    this.yearBlur = this.yearBlur.bind(this);
  }

  createCalendarMonths(months) {
    return this.mapToOption(months);
  }

  createYears(years) {
    return this.mapToOption(years);
  }

  monthFocus() {
    this.setState({ monthFocus: true });
  }

  monthBlur() {
    this.setState({ monthFocus: false });
  }

  yearFocus() {
    this.setState({ yearFocus: true });
  }

  yearBlur() {
    this.setState({ yearFocus: false });
  }

  mapToOption(variableArray) {
    return variableArray.map(function (varInstance, i) {
      return <option key={i}>{varInstance}</option>;
    });
  }

  createGlyph(icon, onClickHandler, previous, next) {
    return (
      <span
        className={icon}
        style={{ cursor: "pointer" }}
        onClick={() => onClickHandler(previous, next)}
      />
    );
  }

  render() {
    let months = this.createCalendarMonths(this.props.months);
    let years = this.createYears(this.props.years);
    let leftArrow = this.createGlyph(
      "chevron-left",
      this.props.changeMonthArrowsCallback,
      true,
      false
    );
    let rightArrow = this.createGlyph(
      "chevron-right",
      this.props.changeMonthArrowsCallback,
      false,
      true
    );

    return (
      <React.Fragment>
        <div className="monthYear__container">
          {leftArrow}
          <div className="monthYear__inner">
            <select
              value={this.props.months[this.props.month]}
              onChange={this.props.changeMonthCallback}
            >
              {months}
            </select>
            <select
              value={this.props.year}
              onChange={this.props.changeYearCallback}
            >
              {years}
            </select>
          </div>
          {rightArrow}
        </div>
      </React.Fragment>
    );
  }
}

MonthYearSelector.propTypes = {
  months: PropTypes.array.isRequired,
  years: PropTypes.array.isRequired,
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  changeMonthCallback: PropTypes.func.isRequired,
  changeYearCallback: PropTypes.func.isRequired,
  changeMonthArrowsCallback: PropTypes.func.isRequired,
};
export default MonthYearSelector;
