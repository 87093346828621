import {
  validateEmailField,
  validateRequireField,
  validatePasswordConfirmation,
  validatePassword,
} from "../Generic/core-validations";
import { axiosProxy } from "../../axios/AxiosProxy";

export const validate = (values) => {
  const errors = {};

  const requiredFields = ["name", "group", "role", "password", "email"];

  validateRequireField(requiredFields, values, errors);
  validateEmailField("email", values, errors);
  validatePassword("password", values, errors);
  validatePasswordConfirmation("password", "confirmPassword", values, errors);

  return errors;
};

export const asyncValidate = (values) => {
  const email = values["email"];
  return axiosProxy
    .getInstance()
    .get("/users/" + email)
    .then((resp) => {
      if (!values["id"] || values["id"] !== resp.data["id"]) {
        throw { email: "A user with this email already exists" };
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log("serverside error");
      } else {
        throw error;
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log("serverside error");
      } else {
        throw error;
      }
    });
};
