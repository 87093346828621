import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import DateTimePicker from "../DateTime/Wrapper";
import { BILLING_RECEIVED, fetchGroupBilling } from "../../actions/billing";

import {
  ButtonRefactored,
  Table,
  InputSelect,
  TableFilter,
  BrickCopy,
  Skeleton,
  Stack,
  TablePagination,
} from "@operata/adagio";

import { getRanges } from "../DateTime/lib/utils/TimeFunctionUtils";
import { createCSVDownload } from "../Datatables/utils";
import { Paginate } from "../../utils/paginate";
import { GroupBillingData } from "../../reducers/billing";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { BillingCriteria } from "../../actions/billing";
import { RootState } from "../../store/store";
import { ProgressSlice } from "../../reducers/progress";
import styled from "@emotion/styled";

type TimeRange =
  | [moment.Moment, moment.Moment, moment.Moment]
  | [string, moment.Moment, moment.Moment]
  | [string, string, string]
  | null;

type Time =
  | [moment.Moment, moment.Moment, moment.Moment]
  | [string, moment.Moment, moment.Moment];

type TimeRangeKey = keyof ReturnType<typeof getRanges>;

type BillingSlice = Pick<RootState, "billing">;
type BillingStateSlice = BillingSlice & ProgressSlice;

const getSearchCriteria = (timeRange: TimeRange) => {
  timeRange = timeRange || ["Last 30 Days", "now-30d", "now"];
  const [, fromTime, toTime] = timeRange;
  return {
    fromTime,
    toTime,
    timezone: moment.tz.guess(),
  };
};

const downloadCSV = (groups: GroupBillingData[]) => {
  const columns = [
    {
      name: "Group",
      download: true,
    },
    {
      name: "Name",
      download: true,
    },
    {
      name: "Cloud Collector Mins",
      download: true,
    },
    {
      name: "Agent Collector Mins",
      download: true,
    },
    {
      name: "Billing Rounded Mins",
      download: true,
    },
    {
      name: "Assurance Test Count",
      download: true,
    },
    {
      name: "Agent Count",
      download: true,
    },
  ];

  const data = groups.map((group) => {
    return {
      data: [
        group.GroupID,
        group.GroupName,
        group.AgentInteractionDurationRoundedMin,
        group.OperataStatsDurationRoundedMin,
        group.DurationRoundedMin,
        group.AssuranceTestCount,
        group.AgentCount,
      ],
    };
  });

  const options = {
    downloadOptions: {
      filename: "tableDownload.csv",
      separator: ",",
    },
  };

  createCSVDownload(columns, data, options);
};

type StateProps = {
  groups: GroupBillingData[];
  loading: boolean;
  status: string | undefined;
};

type DispatchProps = {
  fetchGroupBilling: (criteria: BillingCriteria) => void;
};

const PaddingTop = styled.div`
  padding-top: 12px;
`;

type BillingGroupsProps = StateProps & DispatchProps & RouteComponentProps;

const BillingGroups: React.FC<BillingGroupsProps> = ({
  groups = [],
  fetchGroupBilling,
  status,
  loading,
}) => {
  const groupNames = Array.from(
    new Set(groups.map((group) => group.GroupName))
  );

  const [groupFilter, setGroupFilter] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [timeRange, setTimeRange] = useState<Time | null>(null);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [shouldShowPagination, setShouldShowPagination] =
    useState<boolean>(false);
  const [paginationPage, setPaginationPage] = useState(0);

  useEffect(() => {
    fetchGroupBilling(getSearchCriteria(timeRange));
  }, [fetchGroupBilling, timeRange]);

  useEffect(() => {
    if (!loading && status === BILLING_RECEIVED) {
      setShouldShowPagination(true);
      setPaginationPage(1);
    } else {
      setShouldShowPagination(false);
    }
  }, [loading, page, pageSize, status]);

  const filteredGroups = groupFilter
    ? groups.filter((group) => group.GroupName === groupFilter)
    : groups;

  const paginatedGroups = Paginate({ pageSize, page: paginationPage })(
    filteredGroups
  );

  return (
    <>
      <TableFilter
        filters={
          <>
            <span
              onClick={setPickerOpen.bind(null, !pickerOpen)}
              className="time__link time__results"
            >
              {timeRange
                ? timeRange[0] === "Custom Range"
                  ? `${timeRange[1].format(
                      "DD-MM-YYYY HH:mm"
                    )} - ${timeRange[2].format("DD-MM-YYYY HH:mm")}`
                  : timeRange[0]
                : "Last 30 Days"}
            </span>
            <InputSelect
              placeholder="Group Name"
              selectedValue={groupFilter}
              setSelectedValue={(groupFilter: string | null) => {
                setGroupFilter(groupFilter);
                setPage(0);
              }}
            >
              {groupNames.map((group, index) => (
                <InputSelect.Option key={index} value={group} />
              ))}
            </InputSelect>
            <ButtonRefactored
              size="medium"
              type="secondary"
              disabled={!timeRange && !groupFilter}
              onClick={() => {
                setGroupFilter(null);
                setPage(0);
                setTimeRange(null);
              }}
            >
              Reset
            </ButtonRefactored>
          </>
        }
        actions={
          <ButtonRefactored
            type="secondary"
            size="medium"
            disabled={!groups.length}
            onClick={() => downloadCSV(groups)}
            iconAfter={<Table.DownloadIcon />}
          >
            Download CSV
          </ButtonRefactored>
        }
      />
      <div
        style={{
          position: "relative",
          marginTop: "-50px",
          marginBottom: "66px",
        }}
      >
        <DateTimePicker
          rightAlign={false}
          dateRangeSelected={(
            selectedRange: string,
            start: moment.Moment,
            end: moment.Moment
          ) => {
            if (selectedRange === "Custom Range") {
              setTimeRange([selectedRange, start, end]);
            } else {
              const timeRange = getRanges(new Date())[
                selectedRange as TimeRangeKey
              ];
              setTimeRange([
                selectedRange,
                moment(timeRange[4]),
                moment(timeRange[5]),
              ]);
            }
            setPickerOpen(false);
          }}
          pickerOpen={pickerOpen ? "open" : ""}
          setPickerOpen={(po: boolean | string) => {
            return setPickerOpen(!!po);
          }}
          currentRange={null}
          currentStartTime={timeRange?.[1]}
          currentEndTime={timeRange?.[2]}
        />
      </div>
      <Table>
        <thead>
          <Table.Row>
            <Table.Header width="280">Group Name</Table.Header>
            <Table.Header width="auto" align="right">
              Cloud Collector Mins
            </Table.Header>
            <Table.Header width="auto" align="right">
              Agent Collector Mins
            </Table.Header>
            <Table.Header width="auto" align="right">
              Billing Rounded Mins
            </Table.Header>
            <Table.Header width="auto" align="right">
              Agent Rounded Mins
            </Table.Header>
            <Table.Header width="auto" align="right">
              Cloud Rounded Mins
            </Table.Header>
            <Table.Header width="auto" align="right">
              Assurance Test Count
            </Table.Header>
            <Table.Header width="auto" align="right">
              Agent Count
            </Table.Header>
          </Table.Row>
        </thead>
        <tbody>
          {loading ? (
            Array.from({ length: pageSize }, (_, i) => (
              <Table.Row key={i}>
                <Table.Cell>
                  <Stack gap="8">
                    <Skeleton loading width={80} height={20} />
                    <Skeleton loading width={265} height={20} />
                  </Stack>
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
              </Table.Row>
            ))
          ) : paginatedGroups.length && shouldShowPagination ? (
            paginatedGroups.map((group, index) => (
              <Table.Row key={index}>
                <Table.Cell>
                  {group.GroupID ? (
                    <BrickCopy
                      title={group.GroupName}
                      onClick={() =>
                        navigator.clipboard.writeText(group.GroupID)
                      }
                    >
                      {group.GroupID}
                    </BrickCopy>
                  ) : (
                    <span>N/A</span>
                  )}
                </Table.Cell>
                <Table.Cell align="right">
                  {group.AgentInteractionDurationRoundedMin}
                </Table.Cell>
                <Table.Cell align="right">
                  {group.OperataStatsDurationRoundedMin}
                </Table.Cell>
                <Table.Cell align="right">
                  {group.DurationRoundedMin}
                </Table.Cell>
                <Table.Cell align="right">
                  {group.AgentDurationRoundedMin}
                </Table.Cell>
                <Table.Cell align="right">
                  {group.CloudDurationRoundedMin}
                </Table.Cell>
                <Table.Cell align="right">
                  {group.AssuranceTestCount}
                </Table.Cell>
                <Table.Cell align="right">{group.AgentCount}</Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.RowEmpty colspan={6}>No data Found</Table.RowEmpty>
          )}
        </tbody>
      </Table>
      {shouldShowPagination && paginatedGroups.length > 0 && (
        <>
          <PaddingTop>
            <TablePagination
              pageSize={pageSize}
              setPageSize={setPageSize}
              page={paginationPage}
              setPage={setPaginationPage}
              count={filteredGroups.length}
            />
          </PaddingTop>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = {
  fetchGroupBilling,
};

const mapStateToProps = (state: BillingStateSlice) => ({
  groups: state.billing?.billingData,
  loading: state.progress.loading,
  status: state.billing.status,
});

const ConnectedBillingGroups = withRouter(
  connect<StateProps, DispatchProps, unknown, BillingStateSlice>(
    mapStateToProps,
    mapDispatchToProps
  )(BillingGroups)
);

export const BillingTS = ConnectedBillingGroups;
