import { POLQA_MAX_SCORES } from "../../../../constants/gsm";

export const NOT_AVAILABLE = "-";

export const getPOLQAScore = (polqaScores, leg) => {
  if (!polqaScores) {
    return NOT_AVAILABLE;
  }

  const legScore = polqaScores.filter((score) => score.location === leg);
  if (legScore.length > 0) {
    return legScore[0].score;
  }
  return NOT_AVAILABLE;
};

export const getPOLQAMaxScore = (polqaScores, ref) => {
  if (!polqaScores) {
    return NOT_AVAILABLE;
  }

  if (POLQA_MAX_SCORES[ref]) {
    return POLQA_MAX_SCORES[ref];
  }

  const refScore = polqaScores.filter((score) => score.location === ref);
  if (refScore.length > 0) {
    return refScore[0].score;
  }
  return NOT_AVAILABLE;
};

export const calculateAudioQualityLoss = (reference, score) => {
  if (reference === NOT_AVAILABLE || score === NOT_AVAILABLE) {
    return NOT_AVAILABLE;
  }

  let percentage = ((reference - score) / reference) * 100;

  return percentage.toFixed(1);
};
