import React, { useEffect, useRef, useMemo } from "react";
import { useHistory, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Section,
  Stack,
  TaskStack,
  Typography,
  HeadingDetailed,
  Skeleton,
  ButtonRefactored,
} from "@operata/adagio";
import CanvasDashboard from "../CanvasDashboard/CanvasDashboard";
import { OpenInNew } from "@mui/icons-material";
import { fetchGroupDashboardDetails } from "../../actions/groups";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "./index.scss";
import { useFeatureFlags } from "../../FeatureFlags";
import Garvis from "../Garvis";
import { activeBISession } from "../../actions/auth";
import "../Iframe/Iframe.scss";
import "./index.scss";

let Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { charts } = useSelector((state) => state.homepage);
  const biAuthSuccessful = useSelector(
    (state) => state.canvas.biAuthSuccessful,
    shallowEqual
  );

  const intervalRef = useRef(null);
  const attemptsRef = useRef(0);

  const featureFlags = useFeatureFlags();

  useEffect(() => {
    dispatch(fetchGroupDashboardDetails());

    intervalRef.current = setInterval(() => {
      attemptsRef.current += 1;
      dispatch(activeBISession());
    }, 1000 * 2);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [dispatch]);

  useEffect(() => {
    if (biAuthSuccessful || attemptsRef.current > 10) {
      clearInterval(intervalRef.current);
    }
  }, [biAuthSuccessful]);

  const handleExternalNavigate = (url) => {
    window.open(url, "_blank");
  };

  const handleNavigate = (url) => {
    history.push(url);
  };

  const handleShowArticle = (id) => {
    if (window.Intercom && id) {
      window.Intercom("showArticle", id);
    } else {
      console.error("[Operata] Intercom article could not be loaded");
    }
  };

  const memorisedCanvasDashboard = useMemo(
    () => (
      <CanvasDashboard
        sliceId={charts?.["home_v3_agent_reported_issues_metric"]?.toString()}
        timeFilter={"28d"}
      />
    ),
    [charts]
  );

  return (
    <div className={"double-charts"}>
      <Stack direction="col" gap="medium">
        {featureFlags.garvis && <Garvis />}

        <Section>
          <Stack padding="medium" gap="small" direction="row">
            <Stack width={400} direction="column" gap="small">
              <HeadingDetailed
                level={3}
                title="Reported Issues and Insights (28 days)"
              />
              <Typography>
                <p>
                  Agent Reported Issues and Improvement Insights surface
                  opportunities to resolve issues for individual agents as well
                  as problems that may impact service and agent experience.
                  Analyze trends and reduce frequency to improve both agent and
                  customer experience.
                </p>
              </Typography>

              <Stack direction="row" gap="4">
                <ButtonRefactored
                  type="primary"
                  onClick={() => handleNavigate(`canvas/issues`)}
                  analytics={"home-explore-dashboard-issues"}
                >
                  Explore Dashboard
                </ButtonRefactored>
                <ButtonRefactored
                  iconAfter={<OpenInNew />}
                  onClick={() => handleShowArticle(10089660)}
                  analytics={"home-learn-more-issues"}
                >
                  Learn More (Docs)
                </ButtonRefactored>
              </Stack>
            </Stack>
            <Stack direction="row" gap="small" flex height={"180px"}>
              {/* Seems like we had a hack to load one dashboard first */}
              {/* Agent Reported Issues (28 days) :pie: */}
              <div className={"double-charts__metric-chart"}>
                {memorisedCanvasDashboard}
              </div>
              {biAuthSuccessful && (
                <>
                  {/* Insights (28 days) :pie: */}
                  {/* # Calls [By Attribute] (28 days) :pie: */}
                  <CanvasDashboard
                    sliceId={
                      charts?.[
                        "home_v3_agent_reported_issues_chart"
                      ]?.toString() || "215011"
                    }
                    timeFilter={"28d"}
                  />
                </>
              )}
              {!biAuthSuccessful && (
                <>
                  {/*<Skeleton*/}
                  {/*  width={"100%"}*/}
                  {/*  height={180}*/}
                  {/*  loading*/}
                  {/*  text={"Agent Reported Issues"}*/}
                  {/*/>*/}
                  <Skeleton
                    width={"100%"}
                    height={180}
                    loading
                    text={"Improvement Insights"}
                  />
                </>
              )}
            </Stack>
          </Stack>
        </Section>

        {/* IT Optimize Technology */}
        <Section>
          <Stack padding="medium" gap="small" direction="row">
            <Stack width={400} direction="column" gap="small">
              <HeadingDetailed level={3} title="Technology CX Risk (28 days)" />
              <Typography>
                <p>
                  The IT Risk Score is the percentage of calls with IT Risk
                  Indicators, such as network, application or equipment
                  problems. Proactively lower the IT Risk Score to improve
                  customer and agent experience.
                </p>
              </Typography>
              <Stack direction="row" gap="4">
                <ButtonRefactored
                  type="primary"
                  onClick={() => handleNavigate(`canvas/technology`)}
                  analytics={"home-explore-dashboard-technology"}
                >
                  Explore Dashboard
                </ButtonRefactored>
                <ButtonRefactored
                  iconAfter={<OpenInNew />}
                  onClick={() => handleShowArticle(10089759)}
                  analytics={"home-learn-more-technology"}
                >
                  Learn More (Docs)
                </ButtonRefactored>
              </Stack>
            </Stack>
            <Stack direction="row" gap="small" flex height={"180px"}>
              {biAuthSuccessful && (
                <>
                  {/* High IT CX Risk (28 days) :pie: */}
                  <div className={"double-charts__metric-chart"}>
                    <CanvasDashboard
                      sliceId={
                        charts?.["home_v3_technical_metric"]?.toString() ||
                        "214629"
                      }
                      timeFilter={"28d"}
                    />
                  </div>
                  {/* High IT CX Risk (28 days) */}
                  <CanvasDashboard
                    sliceId={
                      charts?.["home_v3_technical_chart"]?.toString() ||
                      "220220"
                    }
                    timeFilter={"28d"}
                  />
                </>
              )}
              {!biAuthSuccessful && (
                <>
                  <Skeleton
                    width={280}
                    height={180}
                    loading
                    text={"IT Risk Score"}
                  />
                  <Skeleton
                    width={"100%"}
                    height={180}
                    loading
                    text={"IT Risk Indicators"}
                  />
                </>
              )}
            </Stack>
          </Stack>
        </Section>

        {/* OPS Optimise - Customer Experience */}
        <Section>
          <Stack padding="medium" gap="small" direction="row">
            <Stack width={400} direction="column" gap="small">
              <HeadingDetailed
                level={3}
                title="Operational CX Risk (28 days)"
              />
              <Typography>
                <p>
                  The Ops Risk Score is the percentage of calls with Ops Risk
                  Indicators, such as call abandonment, no audio or missed
                  calls. Proactively lower the Ops Risk Score to improve
                  customer and agent experience.
                </p>
              </Typography>
              <Stack direction="row" gap="4">
                <ButtonRefactored
                  type="primary"
                  onClick={() => handleNavigate(`canvas/agentinteractions`)}
                  analytics={"home-explore-dashboard-agentinteractions"}
                >
                  Explore Dashboard
                </ButtonRefactored>
                <ButtonRefactored
                  iconAfter={<OpenInNew />}
                  onClick={() => handleShowArticle(10089761)}
                  analytics={"home-learn-more-agentinteractions"}
                >
                  Learn More (Docs)
                </ButtonRefactored>
              </Stack>
            </Stack>
            <Stack direction="row" gap="small" flex height={"180px"}>
              {biAuthSuccessful && (
                <>
                  {/* Medium & High Ops CX Risk (28 days) :pie: */}
                  <div className={"double-charts__metric-chart"}>
                    <CanvasDashboard
                      sliceId={
                        charts?.["home_v3_operational_metric"]?.toString() ||
                        "214909"
                      }
                      timeFilter={"28d"}
                    />
                  </div>
                  {/* Ops CX Risk (28 days) */}
                  <CanvasDashboard
                    sliceId={
                      charts?.["home_v3_operational_chart"]?.toString() ||
                      "220222"
                    }
                    timeFilter={"28d"}
                  />
                </>
              )}
              {!biAuthSuccessful && (
                <>
                  <Skeleton
                    width={280}
                    height={180}
                    loading
                    text={"Ops Risk Score"}
                  />
                  <Skeleton
                    width={"100%"}
                    height={180}
                    loading
                    text={"Ops Risk Indicators"}
                  />
                </>
              )}
            </Stack>
          </Stack>
        </Section>

        {/* Coverage */}
        <Section>
          <Stack padding="medium" gap="small" direction="row">
            <Stack width={400} direction="column" gap="small">
              <HeadingDetailed
                level={3}
                title="Collection Coverage (28 days)"
              />
              <Typography>
                <p>
                  Collection Coverage is the percentage of Agents that Operata
                  is collecting data and insights from. Use Coverage Gap details
                  to improve Collection Coverage and accuracy for all metrics
                  across your contact center environments.
                </p>
              </Typography>
              <Stack direction="row" gap="4">
                <ButtonRefactored
                  type="primary"
                  onClick={() => handleNavigate(`canvas/coverage`)}
                  analytics={"home-explore-dashboard-coverage"}
                >
                  Explore Dashboard
                </ButtonRefactored>
                <ButtonRefactored
                  iconAfter={<OpenInNew />}
                  onClick={() => handleShowArticle(10089764)}
                  analytics={"home-learn-more-coverage"}
                >
                  Learn More (Docs)
                </ButtonRefactored>
              </Stack>
            </Stack>
            <Stack direction="row" gap="small" flex height={"180px"}>
              {biAuthSuccessful && (
                <>
                  {/* Browser Coverage (28 days) :percentage text: */}
                  <div className={"double-charts__metric-chart"}>
                    <CanvasDashboard
                      title={"Test"}
                      sliceId={
                        charts?.["home_v3_coverage_metric"]?.toString() ||
                        "215020"
                      }
                      timeFilter={"28d"}
                    />
                  </div>
                  {/* Operata Coverage (28 days) */}
                  <CanvasDashboard
                    sliceId={
                      charts?.["home_v3_coverage_chart"]?.toString() || "214614"
                    }
                    timeFilter={"28d"}
                  />
                </>
              )}
              {!biAuthSuccessful && (
                <>
                  <Skeleton
                    width={280}
                    height={180}
                    loading
                    text={"Collection Coverage"}
                  />
                  <Skeleton
                    width={"100%"}
                    height={180}
                    loading
                    text={"Coverage Gap"}
                  />
                </>
              )}
            </Stack>
          </Stack>
        </Section>

        <Section>
          <Section.Header
            title="Explore Operata"
            description="Access handy tips & resources"
          />
          <Section.Content>
            <TaskStack>
              <TaskStack.Item
                title="Help Center"
                description="Videos and user guides to get you using Operata quickly."
                icon={<OpenInNew />}
                onClick={() =>
                  handleExternalNavigate("https://help.operata.com/en/articles/10223468-operata-training-centre")
                }
              />
              <TaskStack.Item
                title="Operata Documentation"
                description="Technical guides and reference documents to support deployment and integration of Operata."
                icon={<OpenInNew />}
                onClick={() =>
                  handleExternalNavigate("https://docs.operata.com/docs")
                }
              />
              <TaskStack.Item
                title="Recent Articles"
                description="Read more on our latest improvements, product releases and contact center insights over on our Blog."
                icon={<OpenInNew />}
                onClick={() =>
                  handleExternalNavigate("https://operata.com/blog")
                }
              />
            </TaskStack>
          </Section.Content>
        </Section>
      </Stack>
    </div>
  );
};

Home.propTypes = {
  selectedTimeFilter: PropTypes.string.isRequired,
  userProfile: PropTypes.object.isRequired,
};

Home.defaultProps = {
  selectedTimeFilter: "7d",
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  userProfile: state.auth.profile,
});

// eslint-disable-next-line no-class-assign
Home = connect(mapStateToProps, mapDispatchToProps)(Home);

// eslint-disable-next-line no-class-assign
Home = withRouter(Home);

export default Home;
