import React from "react";
import { Table, Stack, Badge, Heading } from "@operata/adagio";
import { formatDuration, formatDurationInSeconds, isNullDate } from "./tools";
import PropTypes from "prop-types";

export default function CallDetails({ call, hasResolved }) {
  let summary = call?.summary;
  let logs = call?.logs;

  return (
    <Stack block gap="small">
      <Heading level={4}>Details</Heading>
      <Table spacing="small" style="contained">
        <tbody>
          <Table.Row>
            <Table.Cell width={200}>
              <span>Agent Username</span>
            </Table.Cell>
            <Table.Cell>
              <span>
                {hasResolved
                  ? summary?.serviceAgent?.username || logs?.[0]?.agent || "N/A"
                  : ""}
              </span>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <span>Call Contact ID</span>
            </Table.Cell>
            <Table.Cell>
              <span>
                {hasResolved
                  ? summary?.contact?.id?.current ||
                    logs?.[0]?.contactId ||
                    "N/A"
                  : ""}
              </span>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <span>Call Direction</span>
            </Table.Cell>
            <Table.Cell>
              <span>
                {hasResolved ? summary?.contact?.direction || "N/A" : ""}
              </span>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <span>Ended by</span>
            </Table.Cell>
            <Table.Cell>
              <span>
                {hasResolved
                  ? summary?.contact?.endedBy ||
                    summary?.contact?.disconnectReason ||
                    "N/A"
                  : ""}
              </span>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <span>Network Type</span>
            </Table.Cell>
            <Table.Cell>
              <span>{hasResolved ? summary?.networkType || "N/A" : ""}</span>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <span>Call Start Time</span>
            </Table.Cell>
            <Table.Cell>
              <span>
                {hasResolved
                  ? (!isNullDate(summary?.callStartTime)
                      ? summary?.callStartTime
                      : summary?.contact?.events?.connectedToAgent) || "N/A"
                  : ""}
              </span>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <span>Call End Time</span>
            </Table.Cell>
            <Table.Cell>
              <span>
                {hasResolved
                  ? (!isNullDate(summary?.callEndTime)
                      ? summary.callEndTime
                      : summary?.contact?.events?.disconnected) || "N/A"
                  : ""}
              </span>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <span>Call Duration</span>
            </Table.Cell>
            <Table.Cell>
              <span>
                {hasResolved
                  ? formatDuration(
                      summary?.callStartTime,
                      summary?.callEndTime
                    ) ||
                    formatDurationInSeconds(
                      summary?.contact?.interaction?.agentInteractionDurationSec
                    ) ||
                    "N/A"
                  : ""}
              </span>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <span>Tags</span>
            </Table.Cell>
            <Table.Cell>
              <Stack direction="row" gap="8" wrap>
                {hasResolved ? (
                  summary?.insights?.tags?.length ||
                  (summary?.insights &&
                    Object.keys(summary.insights).some(
                      (key) => key !== "tags" && key !== "count"
                    )) ? (
                    <>
                      {summary?.insights?.tags?.length
                        ? summary.insights.tags.map((tag, index) => (
                            <Badge key={`tag-${index}`} type="neutral">
                              {tag?.description}
                            </Badge>
                          ))
                        : null}
                      {summary?.insights &&
                        Object.keys(summary.insights).map(
                          (key) =>
                            key !== "tags" &&
                            key !== "count" && (
                              <Badge key={`key-${key}`} type="neutral">
                                {key}
                              </Badge>
                            )
                        )}
                    </>
                  ) : (
                    "N/A"
                  )
                ) : null}
              </Stack>
            </Table.Cell>
          </Table.Row>
        </tbody>
      </Table>
    </Stack>
  );
}

CallDetails.propTypes = {
  hasResolved: PropTypes.bool,
  call: PropTypes.shape({
    logs: PropTypes.array,
    summary: PropTypes.shape({
      serviceAgent: PropTypes.shape({
        username: PropTypes.string,
      }),
      networkType: PropTypes.string,
      contact: PropTypes.shape({
        id: PropTypes.shape({
          current: PropTypes.string,
        }),
        interaction: PropTypes.shape({
          agentInteractionDurationSec: PropTypes.number,
        }),
        events: PropTypes.shape({
          connectedToAgent: PropTypes.string,
          disconnected: PropTypes.string,
        }),
        direction: PropTypes.string,
        endedBy: PropTypes.string,
        disconnectReason: PropTypes.string,
      }),
      callStartTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      callEndTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      insights: PropTypes.shape({
        tags: PropTypes.arrayOf(
          PropTypes.shape({
            description: PropTypes.string,
          })
        ),
      }),
    }),
  }),
};
