import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { auth } from "../../auth/Auth";
import PropTypes from "prop-types";

class Fallback extends Component {
  constructor() {
    super();

    this.loadDefaultPage = this.loadDefaultPage.bind(this);
  }

  loadDefaultPage() {
    this.props.history.replace("/" + auth.getCurrentGroupId() + "/dashboard");
  }

  componentDidMount() {
    // do fallback login flow
    var auth0 = new auth0.WebAuth({
      clientID: process.env.AUTH0_CLIENT_ID,
      domain: process.env.AUTH0_DOMAIN_LOGIN,
    });
    auth0.crossOriginVerification();
  }

  render() {
    return <div />;
  }
}

Fallback.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Fallback);
