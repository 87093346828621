import React from "react";
import AudioLatency from "./AudioLatency";
import PropTypes from "prop-types";
import "./AudioAnalytics.scss";
import AudioQualityVisualiser from "./AudioQualityVisualiser";

const AudioAnalytics = (props) => {
  const visualiserFilesMap = {};
  const job = props.jobDetails;

  if (job && job.polqaScores) {
    job.polqaScores.map((result) => {
      const location = result.location
        ? result.location
            .replace("customer", "Customer")
            .replace("softphone", "Agent")
            .replace("agent", "Agent")
            .replace("connect", "Connect")
            .replace("dialler", "Customer")
        : "N/A";

      visualiserFilesMap[location] = {
        result: result,
        url: result.url + "?rnd=" + Math.round(Math.random() * 10000),
      };
    });
  }

  return (
    <>
      <div className="rowDiv__audioQuality__inbound">
        <div className="report__title">Inbound Audio Quality</div>
        {job && job.polqaScores ? (
          <AudioQualityVisualiser
            visualiserFilesMap={visualiserFilesMap}
            tags={job.tags}
            isInbound={true}
          />
        ) : (
          <div className="rowDiv__empty__display">
            No audio quality data available
          </div>
        )}
      </div>
      <div className="rowDiv__audioQuality__outbound">
        <div className="report__title">Outbound Audio Quality</div>
        {job && job.polqaScores ? (
          <AudioQualityVisualiser
            visualiserFilesMap={visualiserFilesMap}
            tags={job.tags}
            isInbound={false}
          />
        ) : (
          <div className="rowDiv__empty__display">
            No audio quality data available
          </div>
        )}
      </div>
      <div className="rowDiv__audioQuality__latency">
        <div className="report__title">Audio Latency (Round Trip)</div>
        {job && job.audioLatency ? (
          <AudioLatency audioLatency={job.audioLatency} />
        ) : (
          <div className="rowDiv__empty__display">
            No audio latency data available
          </div>
        )}
      </div>
    </>
  );
};

AudioAnalytics.propTypes = {
  jobDetails: PropTypes.object,
  polqaScores: PropTypes.array,
  map: PropTypes.func,
  audioLatency: PropTypes.array,
};

export default AudioAnalytics;
