import {
  ERROR_JOB_INSIGHTS_RECEIVED,
  ERROR_JOB_STATS_REPORT_RECEIVED,
  ERROR_JOBS_STATS_RETRIEVAL,
  ERROR_LOGS_RETRIEVAL,
  ERROR_SOURCE_NUMBERS_RECEIVED,
  ERROR_TEST_CREATED,
  ERROR_TESTS,
  JOB_INSIGHTS_RECEIVED,
  JOB_STATS_REPORT_RECEIVED,
  JOBS_STATS_RECEIVED,
  LOG_UPDATED,
  LOGS_RECEIVED,
  NEW_TEST,
  RESET_TEST_DETAILS,
  RESET_TEST_PAGE_STATE,
  SOURCE_NUMBERS_RECEIVED,
  TEST_CREATED,
  TEST_DISABLED,
  TESTS_RECEIVED,
} from "../actions/heartbeat";
import { UPDATE_FILTER } from "../actions/location";

const heartbeat = (
  state = {
    logs: [],
    loading: false,
    message: "",
    tests: [],
    job: {},
    insight: {},
  },
  heartbeat
) => {
  switch (heartbeat.type) {
    case RESET_TEST_DETAILS:
      return Object.assign({}, state, {
        loading: false,
        status: RESET_TEST_DETAILS,
      });
    case TESTS_RECEIVED:
      return Object.assign({}, state, {
        tests: heartbeat.tests,
        loading: false,
        status: TESTS_RECEIVED,
      });
    case ERROR_TESTS:
      return Object.assign({}, state, {
        message: heartbeat.message,
        loading: false,
        status: ERROR_TESTS,
      });
    case TEST_DISABLED:
      return Object.assign({}, state, {
        loading: false,
        status: TEST_DISABLED,
      });
    case RESET_TEST_PAGE_STATE:
      return Object.assign({}, state, {
        loading: false,
        status: RESET_TEST_PAGE_STATE,
      });
    case TEST_CREATED:
      return Object.assign({}, state, {
        loading: false,
        status: TEST_CREATED,
      });
    case NEW_TEST:
      return Object.assign({}, state, {
        loading: false,
        status: NEW_TEST,
      });
    case ERROR_TEST_CREATED:
      return {
        ...state,
        loading: false,
        status: ERROR_TEST_CREATED,
        errorMsg: heartbeat.message,
      };
    case SOURCE_NUMBERS_RECEIVED:
      return Object.assign({}, state, {
        sourceNumbers: heartbeat.numbers,
        status: SOURCE_NUMBERS_RECEIVED,
      });
    case ERROR_SOURCE_NUMBERS_RECEIVED:
      return Object.assign({}, state, {
        sourceNumbers: [],
        status: ERROR_SOURCE_NUMBERS_RECEIVED,
      });
    case LOGS_RECEIVED:
      return Object.assign({}, state, {
        logs: heartbeat.logs,
        loading: false,
        status: LOGS_RECEIVED,
      });
    case ERROR_LOGS_RETRIEVAL:
      return Object.assign({}, state, {
        loading: false,
        status: ERROR_LOGS_RETRIEVAL,
      });
    case LOG_UPDATED: {
      let existing = false;
      const newJobs = state.logs.map((log) => {
        if (log.jobId === heartbeat.log.jobId) {
          existing = true;
          return Object.assign({}, log, heartbeat.log);
        }
        return log;
      });

      if (!existing) {
        newJobs.unshift(heartbeat.log);
      }

      return Object.assign({}, state, {
        logs: newJobs,
        status: LOG_UPDATED,
      });
    }
    case JOBS_STATS_RECEIVED:
      return Object.assign({}, state, {
        currentPeriodStats: heartbeat.currentPeriodStats,
        prevPeriodStats: heartbeat.prevPeriodStats,
        loading: false,
        status: JOBS_STATS_RECEIVED,
      });
    case ERROR_JOBS_STATS_RETRIEVAL:
      return Object.assign({}, state, {
        loading: false,
        status: ERROR_JOBS_STATS_RETRIEVAL,
      });
    case JOB_INSIGHTS_RECEIVED:
      return Object.assign({}, state, {
        insights: heartbeat.insight,
        loading: false,
        status: JOB_INSIGHTS_RECEIVED,
      });
    case ERROR_JOB_INSIGHTS_RECEIVED:
      return Object.assign({}, state, {
        message: heartbeat.message,
        loading: false,
        insights: {},
        status: ERROR_JOB_INSIGHTS_RECEIVED,
      });
    case JOB_STATS_REPORT_RECEIVED:
      return Object.assign({}, state, {
        job: heartbeat.job,
        loading: false,
        status: JOB_STATS_REPORT_RECEIVED,
      });
    case ERROR_JOB_STATS_REPORT_RECEIVED:
      return Object.assign({}, state, {
        message: heartbeat.message,
        job: {},
        loading: false,
        status: ERROR_JOB_STATS_REPORT_RECEIVED,
      });
    case UPDATE_FILTER:
      return Object.assign({}, state, {
        reduxPath: heartbeat.reduxPath,
        status: UPDATE_FILTER,
      });
    default:
      return state;
  }
};

export default heartbeat;
