import React from "react";
import TextField from "@material-ui/core/TextField";
import { validate } from "./ActionConfigSlackValidation";
import { getUrlParts } from "../../Generic/core-validations";
import PropTypes from "prop-types";

class ActionConfigSlack extends React.Component {
  constructor(props) {
    super();

    this.state = {
      actionId: "",
      type: props.actionType,
      url: "",
      method: "post",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleAddAction = this.handleAddAction.bind(this);
  }

  componentDidMount() {
    this.props.onRef(this);
    let { prevState } = this.props;
    if (prevState.type === this.state.type) {
      this.setState(prevState);
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.props.handleAddAction(this.state);
      }
    );
  }

  handleAddAction() {
    let errors = validate(this.state, ["url"], this);
    if (!errors["hasErrors"]) {
      let urlParts = getUrlParts(this.state.url);

      const action = Object.assign({}, this.state, {
        actionId: "Send_To_Slack",
        displayText: "[" + this.state.method + "] " + this.state.url,
        scheme: urlParts["protocol"].replace(":", ""),
        host: urlParts["hostname"],
        port: urlParts["port"],
        path: urlParts["pathname"],
      });

      this.props.handleAddAction(action);
    }

    return errors;
  }

  render() {
    return (
      <React.Fragment>
        <div className="form__control">
          <TextField
            error={this.state.error_url}
            label={
              this.state.errorMessage_url ? this.state.errorMessage_url : "Url"
            }
            id="outlined-required"
            name="url"
            className="textField"
            onChange={(event) => this.handleChange(event)}
            variant="outlined"
            value={this.state.url}
          />
        </div>
      </React.Fragment>
    );
  }
}

ActionConfigSlack.propTypes = {
  actionType: PropTypes.string,
  handleAddAction: PropTypes.func,
  onRef: PropTypes.func,
  prevState: PropTypes.object,
};

export default ActionConfigSlack;
