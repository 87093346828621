export const EXPAND_SIDEBAR = "EXPAND_SIDEBAR";
export const COLLAPSE_SIDEBAR = "COLLAPSE_SIDEBAR";

export const expandSidebar = () => ({
  type: EXPAND_SIDEBAR,
});

export const collapseSidebar = () => ({
  type: COLLAPSE_SIDEBAR,
});
