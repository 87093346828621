export function legendFormatter(data) {
  return (
    "<div>" +
    data.series
      .map((series) => {
        return "<div>" + series.dashHTML + " " + series.labelHTML + "</div>";
      })
      .join("") +
    "</div>"
  );
}
