import {
  ACCESS_TOKEN_CHANGED,
  AUTH_SUCCESSFUL,
  AUTH_UNSUCCESSFUL,
  SWITCH_GROUP_SUCCESSFUL,
  SWITCH_GROUP_UNSUCCESSFUL,
} from "../actions/auth";
import { AnyAction, Reducer } from "redux";
import { UserProfile } from "../models/user";

export type AuthState = {
  sessionExpired: boolean;
  token?: string;
  status: string;
  profile?: UserProfile;
  switchGroupStatus?: string;
  requestedUrl?: string;
};

const authReducer: Reducer<AuthState, AnyAction> = (
  state = { sessionExpired: true, status: "" },
  action: AnyAction
) => {
  if (action.type === ACCESS_TOKEN_CHANGED) {
    return Object.assign({}, state, {
      sessionExpired: false,
      token: action.token,
      status: AUTH_SUCCESSFUL,
    });
  } else if (action.type === AUTH_SUCCESSFUL) {
    return Object.assign({}, state, {
      sessionExpired: false,
      profile: action.profile,
      status: AUTH_SUCCESSFUL,
    });
  } else if (action.type === AUTH_UNSUCCESSFUL) {
    return Object.assign({}, state, {
      sessionExpired: false,
      profile: action.profile,
      status: AUTH_UNSUCCESSFUL,
    });
  } else if (action.type === SWITCH_GROUP_SUCCESSFUL) {
    return Object.assign({}, state, {
      sessionExpired: false,
      profile: action.profile,
      switchGroupStatus: SWITCH_GROUP_SUCCESSFUL,
      requestedUrl: action.requestedUrl,
    });
  } else if (action.type === SWITCH_GROUP_UNSUCCESSFUL) {
    return Object.assign({}, state, {
      sessionExpired: false,
      profile: action.profile,
      switchGroupStatus: SWITCH_GROUP_UNSUCCESSFUL,
    });
  } else {
    return Object.assign({}, state);
  }
};

export default authReducer;
