import { WINDOW_RESIZED } from "../actions/window";

const window = (state = { width: 800 }, action) => {
  switch (action.type) {
    case WINDOW_RESIZED:
      return {
        ...state,
        width: action.width,
      };
    default:
      return state;
  }
};

export default window;
