import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import intervalToDuration from "date-fns/intervalToDuration";
import sub from "date-fns/sub";
import format from "date-fns/format";
import { Tab } from "@material-ui/core";
import { Tabs, TabContainer } from "@operata/ui-components";
import NetworkPerformance from "./NetworkPerformance";
import CustomerExperience from "./CustomerExperience";
import AgentExperience from "./AgentExperience";
import ServiceAvailability from "./ServiceAvailability";
import { fetchJobsAverages } from "../../../actions/heartbeat";
import { useDispatch, useSelector } from "react-redux";
import { getTimestampCriteria } from "../../../utils/datatables";
import InsightsScore from "./InsightsScore";
import { sortDate } from "../../../utils/date";
import {
  subscribeToPage,
  unsubscribeFromPage,
} from "../../../websockets/socket";
import { HEARTBEAT_JOBS } from "../../../constants/push";
import { FINISHED_STATUSES } from "./Insights";
import { dispatchChangeFilter } from "../../../actions/location";
import { matchPath } from "react-router-dom";
import { QS } from "../../UNSAFE_route";

const InsightsDetails = ({ props, filters, logs, filterInsights }) => {
  const dispatch = useDispatch();
  const { currentPeriodStats, prevPeriodStats } = useSelector(
    (state) => state.heartbeat
  );
  const [selectedTab, setSelectedTab] = useState(filterInsights.selectedTab);

  logs.sort((a, b) => sortDate(a.createdOn, b.createdOn));

  useEffect(() => {
    const timeCriteria = getTimestampCriteria(filters.timestamp.dateRange);
    const regionsCriteria = {
      connectRegions: filters.connectRegions,
      agentRegions: filters.agentRegions,
    };
    const statusCriteria = {
      status: FINISHED_STATUSES,
    };
    const currentPeriodCriteria = Object.assign(
      {},
      timeCriteria,
      regionsCriteria,
      statusCriteria
    );
    const prevPeriodCriteria = Object.assign(
      {},
      getPreviousPeriodTimestampCriteria(timeCriteria),
      regionsCriteria,
      statusCriteria
    );

    dispatch(fetchJobsAverages(currentPeriodCriteria, prevPeriodCriteria));

    subscribeToPage(HEARTBEAT_JOBS, (job) => {
      if (FINISHED_STATUSES.includes(job.status)) {
        dispatch(fetchJobsAverages(currentPeriodCriteria, prevPeriodCriteria));
      }
    });

    return function cleanup() {
      unsubscribeFromPage(HEARTBEAT_JOBS);
    };
  }, [dispatch, filters]);

  const getPreviousPeriodTimestampCriteria = (current) => {
    let fromTime;

    const lastXRegex = /now(-\d+[mhdwMy])/;
    const toDateRegex = /now\/([mhdwMy])/;
    if (lastXRegex.test(current.fromTime)) {
      const period = current.fromTime.match(lastXRegex)[1];
      fromTime = `${current.fromTime}${period}`;
    } else if (toDateRegex.test(current.fromTime)) {
      const period = current.fromTime.match(toDateRegex)[1];
      fromTime = `${current.fromTime}-1${period}`;
    } else {
      const duration = intervalToDuration({
        start: new Date(current.fromTime),
        end: new Date(current.toTime),
      });
      const newFrom = sub(new Date(current.fromTime), duration);
      fromTime = `${format(newFrom, "yyyy-MM-dd'T'HH:mm:ss")}`;
    }

    return {
      fromTime,
      toTime: current.fromTime,
      timezone: current.timezone,
    };
  };

  const handleTab = (event, tab) => {
    setSelectedTab(tab);
    dispatch(
      dispatchChangeFilter(
        {
          ...filterInsights,
          selectedTab: tab,
        },
        "hbinsights"
      )
    );

    if (matchPath(window.location.pathname, props.match)) {
      QS.updateFromState("hbinsights");
    }
  };

  return (
    <div className="insights__wrapper">
      <Tabs selectedTab={parseInt(selectedTab)} onChange={handleTab}>
        <Tab
          icon={
            <InsightsScore
              value={
                currentPeriodStats ? currentPeriodStats.completedPercentage : 0
              }
              prevValue={
                prevPeriodStats ? prevPeriodStats.completedPercentage : 0
              }
              title="Telephony"
              description={
                <span>
                  Telephony Score is the percentage of successful calls made in
                  the selected period. See{" "}
                  <a
                    href="https://docs.operata.io"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>{" "}
                  for more info.
                </span>
              }
              unit={"%"}
            />
          }
        />
        <Tab
          icon={
            <InsightsScore
              title="CX Score"
              value={
                currentPeriodStats ? currentPeriodStats.average.cxScore : 0
              }
              prevValue={prevPeriodStats ? prevPeriodStats.average.cxScore : 0}
              description={
                <span>
                  CX Score is scored out of 10. Each call starts as 10 with
                  points deducted for critical defects using a weighted scale.
                  See{" "}
                  <a
                    href="https://docs.operata.io"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>{" "}
                  for more info.
                </span>
              }
            />
          }
        />
        <Tab
          icon={
            <InsightsScore
              title="AX Score"
              value={
                currentPeriodStats ? currentPeriodStats.average.axScore : 0
              }
              prevValue={prevPeriodStats ? prevPeriodStats.average.axScore : 0}
              description={
                <span>
                  AX Score is scored out of 10. Each call starts as 10 with
                  points deducted for critical defects using a weighted scale.
                  See{" "}
                  <a
                    href="https://docs.operata.io"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>{" "}
                  for more info.
                </span>
              }
            />
          }
        />
        <Tab
          icon={
            <InsightsScore
              title="Network Performance"
              value={
                currentPeriodStats ? currentPeriodStats.average.networkScore : 0
              }
              prevValue={
                prevPeriodStats ? prevPeriodStats.average.networkScore : 0
              }
              description={
                <span>
                  Network Score is scored out of 10. Each call starts as 10 with
                  points deducted for critical defects using a weighted scale.
                  See{" "}
                  <a
                    href="https://docs.operata.io"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>{" "}
                  for more info.
                </span>
              }
            />
          }
        />
      </Tabs>
      <TabContainer selectedTab={parseInt(selectedTab)} index={0}>
        {parseInt(selectedTab) === 0 && (
          <ServiceAvailability
            logs={logs}
            filterInsights={filterInsights}
            props={props}
          />
        )}
      </TabContainer>
      <TabContainer selectedTab={parseInt(selectedTab)} index={1}>
        {parseInt(selectedTab) === 1 && (
          <CustomerExperience
            logs={logs}
            filterInsights={filterInsights}
            props={props}
          />
        )}
      </TabContainer>
      <TabContainer selectedTab={parseInt(selectedTab)} index={2}>
        {parseInt(selectedTab) === 2 && (
          <AgentExperience
            logs={logs}
            filterInsights={filterInsights}
            props={props}
          />
        )}
      </TabContainer>
      <TabContainer selectedTab={parseInt(selectedTab)} index={3}>
        {parseInt(selectedTab) === 3 && (
          <NetworkPerformance
            logs={logs}
            filterInsights={filterInsights}
            props={props}
          />
        )}
      </TabContainer>
    </div>
  );
};

export default InsightsDetails;

InsightsDetails.propTypes = {
  filters: PropTypes.object,
  logs: PropTypes.array,
  props: PropTypes.func,
  match: PropTypes.object,
  filterInsights: PropTypes.object,
};
