import PropTypes from "prop-types";
import React from "react";
import TextField from "@material-ui/core/TextField";

const CloudCollector = ({ value }) => {
  let version = value.version;
  const timestamp = value.timestamp;

  let datestamp = new Date(timestamp);
  let month = datestamp.getMonth() + 1; //month is 0 indexed in js

  // Convert minutes to string and add leading zero if necessary
  var formattedMinutes =
    (datestamp.getMinutes() < 10 ? "0" : "") + datestamp.getMinutes();

  let timestring = timestamp
    ? datestamp.getDate().toString() +
      "/" +
      month.toString() +
      "/" +
      datestamp.getFullYear().toString() +
      " " +
      datestamp.getHours().toString() +
      ":" +
      formattedMinutes.toString()
    : "";
  return (
    <>
      <div className={"card__title h5"}>Cloud Collector Version</div>
      <br />
      <TextField
        name="version"
        className="textfield"
        variant="outlined"
        value={version}
        disabled
      />
      <br />
      <div className={"card__title h5"}>Deployed on</div>
      <br />
      <TextField
        name="timestamp"
        className="textfield"
        variant="outlined"
        value={timestring.toString()}
        disabled
      />
      <br />
    </>
  );
};

CloudCollector.propTypes = {
  error: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.object,
};

export default CloudCollector;
