import Dygraph from "dygraphs";
import { legendFormatter } from "./Helpers/legendFormatter.js";
import { zoomHelper } from "./Helpers/ZoomHelper.js";

const GraphAgentLogs = ({ call, graphOptions }) => {
  if (!call?.logs) return;

  const agentlogs = call?.logs?.map((d) => [
    new Date(d.time),
    d.level === "INFO" ? 1 : null,
    d.level === "WARN" ? 2 : null,
    d.level === "ERROR" ? 3 : null,
  ]);

  return new Dygraph("graph-agentlogs", agentlogs, {
    labels:
      call?.logs?.length > 0
        ? ["timestamp", "info", "warn", "error"]
        : ["timestamp"],
    labelsDiv: "graph-agentlogs-legend",
    axisLabelWidth: graphOptions.op_yAxisLabelWidth,
    axes: {
      x: {
        drawGrid: false,
        drawAxis: true,
      },
      y: {
        drawGrid: true,
        drawAxis: true,
        gridLineColor: "rgba(0,0,0,0.3)",
        axisLineColor: "rgba(0,0,0,0.0)",
        axisLabelFormatter: function (y) {
          return y === 1 ? "INFO" : y === 2 ? "WARN" : y === 3 ? "ERROR" : "";
        },
      },
    },
    xRangePad: graphOptions.op_xRangePad,
    strokeWidth: 0.0,
    drawPoints: true,
    pointSize: 4,
    highlightCircleSize: 6,
    fillGraph: false,
    drawAxesAtZero: true,
    labelsUTC: true,
    legend: "always",
    legendFormatter: legendFormatter,
    colors: ["blue", "orange", "red"],
    valueRange: [0, 4],
    plugins: [zoomHelper],
  });
};

export default GraphAgentLogs;
