import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import Button from "../Button/Button";
import { validate, asyncValidate } from "./validation";
import OpTextField from "../Generic/OpTextField";
import { fetchUser, createOrUpdateUser } from "../../actions/users";
import {
  USER_CREATED,
  USER_UPDATED,
  ERROR_USER_CREATED,
} from "../../actions/users";
import {
  showSnackbarMessage,
  SNACKBAR_SUCCESS,
  SNACKBAR_ERROR,
} from "../../actions/snackbar";
import OpSelect from "../Generic/OpSelect";
import MenuItem from "@material-ui/core/MenuItem";
import { withRouter } from "react-router-dom";
import PropTypes, { bool, string } from "prop-types";

class UserForm extends Component {
  constructor(props) {
    super(props);
    this.UNSAFE_componentWillReceiveProps =
      this.UNSAFE_componentWillReceiveProps.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    if (id && id > 0) {
      this.props.fetchUser(id);
    }
  }

  isEditUser() {
    let id = this.props.match.params.id;
    return id && id > 0;
  }

  handleCancel = () => {
    this.props.history.push("/admin/users");
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.status === USER_CREATED) {
      this.props.showSnackbarMessage(
        SNACKBAR_SUCCESS,
        "Teammate created successfully."
      );
      this.props.history.push("/admin/users");
    } else if (nextProps.status === USER_UPDATED) {
      this.props.showSnackbarMessage(
        SNACKBAR_SUCCESS,
        "Teammate updated successfully."
      );
      this.props.history.push("/admin/users");
    } else if (nextProps.status === ERROR_USER_CREATED) {
      this.props.showSnackbarMessage(SNACKBAR_ERROR, nextProps.message);
    }
  }

  render() {
    let { handleSubmit, pristine, submitting } = this.props;

    return (
      <React.Fragment>
        {/* <PageTitle align={'title--centre'} pageTitle={pageTitle} /> */}
        <div className="grid__container">
          <form
            className="grid__column grid__column--four"
            onSubmit={handleSubmit(this.props.createOrUpdateUser)}
          >
            <div className="form__control">
              <OpTextField
                className="textfield"
                name="name"
                label="Name"
                type="text"
              />
            </div>
            <div className="form__control">
              <OpTextField
                className="textfield"
                name="group"
                label="Group"
                type="text"
              />
            </div>

            {this.props.initialValues &&
              this.props.initialValues.role !== "Operata Superuser" && (
                <div className="form__control">
                  <OpSelect name="role" label="Role">
                    <MenuItem key="role1" value="Group Admin">
                      Group Admin
                    </MenuItem>
                    <MenuItem key="role2" value="Group User">
                      Group User
                    </MenuItem>
                    <MenuItem key="role3" value="Group Viewer">
                      Group Viewer
                    </MenuItem>
                  </OpSelect>
                </div>
              )}

            <div className="form__control">
              <OpTextField
                className="textfield"
                name="email"
                label="Email"
                type="text"
              />
            </div>

            <div className="form__control form__control--buttons">
              <Button
                buttonText={"Cancel"}
                className={"btnColouredSmall"}
                disabled={pristine || submitting}
                onClick={this.handleCancel}
              />
              <Button
                type="submit"
                buttonText={"Save"}
                className={"btnSolid"}
                disabled={pristine || submitting}
              />
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  fetchUser: fetchUser,
  createOrUpdateUser: createOrUpdateUser,
  showSnackbarMessage: showSnackbarMessage,
};

export const mapStateToProps = (state) => ({
  pageTitle:
    state.user.data && state.user.data.id && state.user.data.id > 0
      ? "Edit Teammate"
      : "New Group",
  initialValues: state.user.data,
  loading: state.user.loading,
  status: state.user.status,
  message: state.user.message,
});

UserForm.propTypes = {
  status: string,
  showSnackbarMessage: PropTypes.func,
  message: string,
  handleSubmit: PropTypes.func,
  pristine: bool,
  submitting: bool,
  initialValues: PropTypes.object,
  createOrUpdateUser: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  fetchUser: PropTypes.func,
};

// eslint-disable-next-line no-class-assign
UserForm = reduxForm({
  form: "UserForm",
  validate,
  asyncValidate,
  asyncBlurFields: ["email"],
  enableReinitialize: true,
})(UserForm);

// eslint-disable-next-line no-class-assign
UserForm = connect(mapStateToProps, mapDispatchToProps)(UserForm);

export default withRouter(UserForm);
