import React from "react";
import StatusHeader from "./StatusHeader";
import StatusResults from "./StatusResults";
import "./Status.scss";
import { Select } from "@operata/ui-components";
import MenuItem from "@material-ui/core/MenuItem";
import StatusMap from "./StatusMap";
import PropTypes from "prop-types";

const statusDummyData = [
  {
    createdOn: "12:05:07",
    connectRegion: "ap-southeast-2",
    agentRegion: "Mumbai",
    cxScore: 99,
    axScore: 83,
    flags: "Increasing Latency PSTN",
  },
  {
    createdOn: "12:00:01",
    connectRegion: "us-east-1",
    agentRegion: "Mumbai",
    cxScore: 98,
    axScore: 80,
    flags: "Increasing Signalling Delays",
  },
];

let Status = class Status extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <StatusHeader group="Test group" />
        <div className="timeFilter__wrapper">
          <Select name="timeSelect">
            <MenuItem value="test">Test</MenuItem>
          </Select>
        </div>
        <StatusMap data={this.props.data} />
        <StatusResults data={statusDummyData} />
      </div>
    );
  }
};

Status.propTypes = {
  data: PropTypes.object,
};

export default Status;
