import React from "react";
import { Badge, Tab } from "@material-ui/core";

function LinkTab(props) {
  if (props.badgeCount) {
    return (
      <React.Fragment>
        <Badge
          color="primary"
          badgeContent={props.badgeCount}
          className="badge"
        >
          <Tab
            component="a"
            onClick={(event) => event.preventDefault()}
            {...props}
          />
        </Badge>
      </React.Fragment>
    );
  } else {
    return (
      <Tab
        component="a"
        onClick={(event) => event.preventDefault()}
        {...props}
      />
    );
  }
}

export default LinkTab;
