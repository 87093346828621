import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import "./Breadcrumb.scss";
import { auth } from "../../auth/Auth";
import { openGroupSwitch } from "../../actions/groups";
import { prefixedPath } from "../../utils/deeplinks";
import PropTypes from "prop-types";

class Breadcrumb extends React.Component {
  constructor(props) {
    super(props);
    this.handleGroupClick = this.handleGroupClick.bind(this);
  }

  handleGroupClick(event) {
    this.props.openGroupSwitch();
    event.preventDefault();
  }

  makeLinkBreadcrumb(path, name) {
    return (
      <NavLink
        activeClassName="breadcrumb__link--active"
        className="breadcrumb__link"
        to={prefixedPath(path)}
      >
        {name}
      </NavLink>
    );
  }

  makeTextBreadcrumb(crumbClass, name) {
    return <span className={crumbClass}>{name}</span>;
  }

  render() {
    return (
      <div className="breadcrumb__wrapper" aria-label="Breadcrumb">
        <NavLink
          activeClassName="breadcrumb__link--active"
          className="breadcrumb__link"
          to="#"
          onClick={this.handleGroupClick}
        >
          {auth.getCurrentGroupName()}
        </NavLink>

        {this.props.path.map((crumb, index) => {
          const nameToDisplay = crumb.dynamic ? crumb.displayName : crumb.name;
          const pathToDisplay = crumb.dynamic ? crumb.displayPath : crumb.path;
          if (crumb.path) {
            if (crumb.dynamic && (!nameToDisplay || !pathToDisplay)) {
              return this.makeLinkBreadcrumb("#", "...");
            }
            return this.makeLinkBreadcrumb(pathToDisplay, nameToDisplay);
          }

          const crumbClass =
            index === this.props.path.length - 1
              ? "breadcrumb__text"
              : "breadcrumb__link";
          if (crumb.dynamic) {
            if (!nameToDisplay) {
              return this.makeTextBreadcrumb(crumbClass, "...");
            }
            return this.makeTextBreadcrumb(crumbClass, nameToDisplay);
          }
          return this.makeTextBreadcrumb(crumbClass, crumb);
        })}
      </div>
    );
  }
}

const mapDispatchToProps = {
  openGroupSwitch: openGroupSwitch,
};

const mapStateToProps = (state) => ({
  path: state.breadcrumb.path,
});

// eslint-disable-next-line no-class-assign
Breadcrumb = connect(mapStateToProps, mapDispatchToProps)(Breadcrumb);

Breadcrumb.propTypes = {
  openGroupSwitch: PropTypes.func,
  path: PropTypes.array,
};

export default Breadcrumb;
