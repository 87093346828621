import { CUSTOMERS_RECEIVED } from "../actions/customers";

const customers = (
  state = {
    data: [],
    status: "",
  },
  action
) => {
  switch (action.type) {
    case CUSTOMERS_RECEIVED:
      return {
        ...state,
        data: action.customerList,
        status: CUSTOMERS_RECEIVED,
      };
    default:
      return state;
  }
};

export default customers;
