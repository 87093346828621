import Dygraph from "dygraphs";
import { legendFormatter } from "./Helpers/legendFormatter.js";
import { zoomHelper } from "./Helpers/ZoomHelper.js";

const GraphHoldmute = ({ call, graphOptions }) => {
  if (!call?.call) return;
  const holdmute = call?.call?.map((d) => [
    new Date(d.timestamp),
    d.agentStatus == "Talk"
      ? 3
      : d.agentStatus == "On Mute"
      ? 2
      : d.agentStatus == "On Hold"
      ? 1
      : 0,
  ]);

  return new Dygraph("graph-holdmute", holdmute, {
    labels: ["timestamp", "status"],
    labelsDiv: "graph-holdmute-legend",
    axisLabelWidth: graphOptions.op_yAxisLabelWidth,
    axes: {
      x: {
        drawGrid: false,
        drawAxis: true,
      },
      y: {
        drawGrid: true,
        drawAxis: true,
        gridLineColor: "rgba(0,0,0,0.3)",
        axisLineColor: "rgba(0,0,0,0.0)",
        axisLabelFormatter: function (y) {
          return y == 3 ? "Talk" : y == 2 ? "Mute" : y == 1 ? "Hold" : "";
        },
      },
    },
    xRangePad: graphOptions.op_xRangePad,
    stepPlot: true,
    fillGraph: false,
    drawAxesAtZero: true,
    labelsUTC: true,
    legend: "always",
    legendFormatter: legendFormatter,
    colors: ["#8000ff"],
    valueRange: [0, 4],
    plugins: [zoomHelper],
  });
};

export default GraphHoldmute;
