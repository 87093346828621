import {
  EVENT_SOURCE_CREATED,
  ERROR_EVENT_SOURCE_CREATED,
  EVENT_SOURCE_CONFIG_RECEIVED,
  EVENT_SOURCE_DELETED,
  ERROR_EVENT_SOURCE_FETCH,
  ERROR_EVENT_SOURCE_DELETED,
} from "../actions/eventBridge";

const eventBridge = (state = { loading: false, data: [] }, action) => {
  switch (action.type) {
    case EVENT_SOURCE_CREATED:
      return Object.assign({}, state, {
        loading: false,
        status: EVENT_SOURCE_CREATED,
      });
    case ERROR_EVENT_SOURCE_CREATED:
      return Object.assign({}, state, {
        loading: false,
        status: ERROR_EVENT_SOURCE_CREATED,
        message: action.message,
      });
    case ERROR_EVENT_SOURCE_FETCH:
      return Object.assign({}, state, {
        loading: false,
        status: ERROR_EVENT_SOURCE_FETCH,
        message: action.message,
      });
    case ERROR_EVENT_SOURCE_DELETED:
      return Object.assign({}, state, {
        loading: false,
        status: ERROR_EVENT_SOURCE_DELETED,
        message: action.message,
      });
    case EVENT_SOURCE_CONFIG_RECEIVED:
      return {
        ...state,
        data: action.eventSourceConfig,
        loading: false,
        status: EVENT_SOURCE_CONFIG_RECEIVED,
      };
    case EVENT_SOURCE_DELETED:
      return {
        ...state,
        loading: false,
        status: EVENT_SOURCE_DELETED,
      };
    default:
      return state;
  }
};

export default eventBridge;
