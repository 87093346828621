import React from "react";
import PropTypes from "prop-types";
import RangeButton from "./RangeButton";

class Ranges extends React.Component {
  constructor(props) {
    super(props);

    let focused = [];
    let ranges = Object.values(this.props.ranges);
    for (let i = 0; i < ranges.length; i++) {
      focused.push(false);
    }

    this.state = {
      viewingIndex: this.props.selectedRange,
      focused: focused,
    };

    this.viewingIndexChangeCallback =
      this.viewingIndexChangeCallback.bind(this);
    this.setFocusedCallback = this.setFocusedCallback.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // On Change of Selected Range reset viewing index to be the range index
    if (this.props.selectedRange !== nextProps.selectedRange) {
      this.setState({
        viewingIndex: nextProps.selectedRange,
      });
    }
  }

  viewingIndexChangeCallback(newIndex) {
    // Allow a new item selected to be made
    let length = this.state.focused.length;
    if (newIndex >= 0 && newIndex < length) {
      this.setState({
        viewingIndex: newIndex,
      });
    }
  }

  setFocusedCallback(index, focusedInput) {
    // Set the focus value of indexed item, focusedInput is true or false
    let focused = this.state.focused;
    focused[index] = focusedInput;
    this.setState({
      focused: focused,
    });
  }

  render() {
    // Map the range index and object name and value to a range button
    return (
      <div className="quick__wrapper open">
        <ul className="quick__column">
          {Object.keys(this.props.ranges).map((range, i) => {
            let column = this.props.ranges[range][3];
            if (column === 1) {
              return this.renderQuickRangeButton(i, range);
            }
          })}
        </ul>
        <ul className="quick__column">
          {Object.keys(this.props.ranges).map((range, i) => {
            let column = this.props.ranges[range][3];
            if (column === 2) {
              return this.renderQuickRangeButton(i, range);
            }
          })}
        </ul>
        <ul className="quick__column">
          {Object.keys(this.props.ranges).map((range, i) => {
            let column =
              range === "Custom Range" ? 3 : this.props.ranges[range][3];
            if (column === 3 || column === undefined) {
              return this.renderQuickRangeButton(i, range);
            }
          })}
        </ul>
      </div>
    );
  }

  renderQuickRangeButton(i, range) {
    return (
      <li className="quick__item" key={i}>
        <RangeButton
          key={i}
          index={i}
          label={range}
          value={this.props.ranges[range]}
          selectedRange={this.props.selectedRange}
          rangeSelectedCallback={this.props.rangeSelectedCallback}
          viewingIndex={this.state.viewingIndex}
          viewingIndexChangeCallback={this.viewingIndexChangeCallback}
          focused={this.state.focused}
          setFocusedCallback={this.setFocusedCallback}
        />
      </li>
    );
  }
}

Ranges.propTypes = {
  ranges: PropTypes.object.isRequired,
  screenWidthToTheRight: PropTypes.number.isRequired,
  selectedRange: PropTypes.number.isRequired,
  rangeSelectedCallback: PropTypes.func.isRequired,
};

export default Ranges;
