export const FEATURES = {
  WORKFLOW_APPLICATION_TYPE_GENESYS: "WORKFLOW_APPLICATION_TYPE_GENESYS",
  WORKFLOW_APPLICATION_TYPE_TWILIO: "WORKFLOW_APPLICATION_TYPE_TWILIO",
  WORKFLOW_APPLICATION_TYPE_CISCO: "WORKFLOW_APPLICATION_TYPE_CISCO",

  WORKFLOW_ACTION_SLACK: "WORKFLOW_ACTION_SLACK",
  WORKFLOW_ACTION_APPLICATION_SPECIFIC: "WORKFLOW_ACTION_APPLICATION_SPECIFIC",
  WORKFLOW_ACTION_OPERATA_TEST: "WORKFLOW_ACTION_OPERATA_TEST",

  PERFORMANCE_MODULE: "PERFORMANCE_MODULE",
  SIMULATE_MODULE: "SIMULATE_MODULE",
  EVENTBRIDGE_MODULE: "EVENTBRIDGE_MODULE",
  DATA_SOURCES_MODULE: "DATA_SOURCES_MODULE",
};

class FeatureToggles {
  constructor() {
    this.featureToggles = null;
  }

  getFeatureToggles() {
    if (this.featureToggles === null) {
      this.featureToggles = {};
      this.featureToggles[FEATURES.WORKFLOW_APPLICATION_TYPE_GENESYS] = false;
      this.featureToggles[FEATURES.WORKFLOW_APPLICATION_TYPE_TWILIO] = false;
      this.featureToggles[FEATURES.WORKFLOW_APPLICATION_TYPE_CISCO] = false;

      this.featureToggles[FEATURES.WORKFLOW_ACTION_SLACK] = false;
      this.featureToggles[FEATURES.WORKFLOW_ACTION_APPLICATION_SPECIFIC] =
        false;
      this.featureToggles[FEATURES.WORKFLOW_ACTION_OPERATA_TEST] = false;
    }

    return this.featureToggles;
  }

  isFeatureEnabled(feature) {
    let featureToggles = this.getFeatureToggles();
    return featureToggles[feature];
  }

  addFeatureToggle(feature, enabled) {
    let currentFeatureToggles = this.getFeatureToggles();
    currentFeatureToggles[feature] = enabled;
  }
}

export let featureToggles = new FeatureToggles();
