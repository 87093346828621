import React from "react";
import TextField from "@material-ui/core/TextField";
import WorkflowContext from "../WorkflowContext";
import ChipInput from "material-ui-chip-input";
import _ from "lodash";

export class StepSave extends React.Component {
  render() {
    return (
      <WorkflowContext.Consumer>
        {({
          workflowName,
          setWorkflowName,
          error_workflowName,
          errorMessage_workflowName,
          application,
          trigger,
          actions,
          availableTriggers,
          availableActions,
          availableApplications,
          tags,
          setTags,
        }) => {
          let applicationIcon = availableApplications[application].icon;
          let triggerIcon = availableTriggers[trigger].icon;
          let actionIcon = availableActions[actions[0].type].icon;

          return (
            <div className="grid__container workflow__summary">
              <div className="grid__column grid__column--four">
                <div className="form__control">
                  <h2>Details</h2>
                </div>

                <div className="workflow-journey">
                  <span className="workflow-journey__icon">
                    <img src={applicationIcon} alt="icon" />
                  </span>
                  <span className="workflow-journey__icon">
                    <img src={triggerIcon} alt="icon" />
                  </span>
                  <span className="workflow-journey__icon">
                    <img src={actionIcon} alt="icon" />
                  </span>
                </div>

                <div className="workflow__input">
                  <p>Workflow Name</p>
                  <TextField
                    error={error_workflowName}
                    label={
                      errorMessage_workflowName
                        ? errorMessage_workflowName
                        : "Workflow Name"
                    }
                    id="outlined-required"
                    name="description"
                    className="textField"
                    onChange={(event) => setWorkflowName(event.target.value)}
                    variant="outlined"
                    value={workflowName}
                  />
                </div>

                <div className="workflow__input">
                  <ChipInput
                    className="tags__wrapper"
                    label="Add Tags"
                    placeholder="Type and press enter to add tags"
                    value={
                      !tags ||
                      typeof tags === "string" ||
                      tags instanceof String
                        ? []
                        : tags
                    }
                    onAdd={(newTag) => setTags(_.union(tags, [newTag]))}
                    onDelete={(deltedTag) =>
                      setTags(_.without(tags, deltedTag))
                    }
                  />
                </div>
              </div>
            </div>
          );
        }}
      </WorkflowContext.Consumer>
    );
  }
}
