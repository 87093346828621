import React from "react";
import WorkflowContext from "../WorkflowContext";
import PropTypes from "prop-types";

class StepApplication extends React.Component {
  renderApplications(
    availableApplications,
    selectedApplication,
    setApplication
  ) {
    return Object.keys(availableApplications).map((key) => {
      let { application, image, enabled } = availableApplications[key];
      let selected = key === selectedApplication ? "selected" : "";

      return (
        <li
          className={`application__cell ${enabled} ${selected}`}
          key={key}
          onClick={() => {
            if (enabled !== "disabled") setApplication(key);
          }}
        >
          <div
            style={{ backgroundImage: `url(${image})` }}
            className="application__logo"
            alt={application}
          />
          <p className="application__name">{application}</p>
        </li>
      );
    });
  }

  render() {
    return (
      <WorkflowContext.Consumer>
        {({ application, setApplication, availableApplications }) => (
          <div className="actions__grid">
            {this.props.renderSearch()}
            <ul className="auto-grid">
              {this.renderApplications(
                availableApplications,
                application,
                setApplication
              )}
            </ul>
          </div>
        )}
      </WorkflowContext.Consumer>
    );
  }
}

StepApplication.propTypes = {
  renderSearch: PropTypes.func,
};

export default StepApplication;
