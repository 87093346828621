import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Button } from "@operata/ui-components";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import React from "react";
import PropTypes from "prop-types";
import ConditionRow from "./ConditionRow";

const StepConditions = (props) => {
  const { conditions, setConditions, errors } = props;
  const operator =
    conditions && conditions.length > 0 ? conditions[0].operator : "";

  const handleAddCondition = () => {
    const updated = [
      ...conditions,
      {
        operator,
      },
    ];
    setConditions(updated);
  };

  const handleRemoveCondition = (index) => {
    const updated = [
      ...conditions.slice(0, index),
      ...conditions.slice(index + 1),
    ];
    setConditions(updated);
  };

  const handleConditionChange = (index, prevCondition, newCondition) => {
    if (prevCondition.operator !== newCondition.operator) {
      handleOperatorChange(newCondition.operator);
      return;
    }

    const updated = conditions.map((original, i) => {
      if (i === index) {
        return newCondition;
      }
      return original;
    });
    setConditions(updated);
  };

  const handleOperatorChange = (operator) => {
    const updated = conditions.map((condition) => {
      return {
        ...condition,
        operator,
      };
    });
    setConditions(updated);
  };

  return (
    <Grid container item direction={"column"} xs={11} spacing={1}>
      <Grid item style={{ textAlign: "right" }}>
        <Button
          className={"btnColouredSmall"}
          buttonText={
            <AddIcon
              color={"primary"}
              fontSize={"small"}
              style={{ marginBottom: "-5px" }}
            />
          }
          hidePlus={true}
          onClick={handleAddCondition}
        />
      </Grid>
      <Grid item>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Operator</TableCell>
                <TableCell>Threshold</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {conditions &&
                conditions.map((condition, i) => (
                  <ConditionRow
                    key={i}
                    index={i}
                    condition={condition}
                    operator={operator}
                    onChange={handleConditionChange}
                    onDelete={handleRemoveCondition}
                    errors={errors.conditions[i] || {}}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

StepConditions.propTypes = {
  conditions: PropTypes.array,
  setConditions: PropTypes.func,
  errors: PropTypes.object,
};

export default StepConditions;
