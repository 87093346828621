import React, { useEffect } from "react";
import {useParams} from "react-router-dom";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { axiosProxy } from "../../axios/AxiosProxy";
import { auth } from "../../auth/Auth";
import { DataRole, EmbeddedDashboard } from "../../models/dataRole";
import rison from "rison";

type EmbeddedPlaybookProps = {
  role?: DataRole;
  playbooks: EmbeddedDashboard[];
  handleClick: (contactId: string) => void;
};

type EmbeddedPlaybookParams = {
  id: string;
};

export default function EmbeddedPlaybook({
  role,
  playbooks,
   handleClick
}: EmbeddedPlaybookProps) {
  const { id } = useParams<EmbeddedPlaybookParams>();


  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === "navigate") {
        const { href } = event.data;

        const url = new URL(href);
        const contactId = url.pathname.split("/call/")[1];

        handleClick(contactId);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [handleClick]);


  useEffect(() => {
    const f = async () => {
      if (!role || !playbooks) {
        return;
      }

      const foundPlaybook = playbooks.find((p) => p.id === parseInt(id, 10));
      if (!foundPlaybook) {
        return;
      }

      const containerEl = document.getElementById("my-superset-container");
      if (!containerEl) {
        return;
      }

      const urlParams = new URLSearchParams(window.location.search);
      let nativeFilters = urlParams.get("filter");
      let parsedFilters: Record<string, any> | undefined;

      if (nativeFilters) {
        nativeFilters = nativeFilters.replace(/=+$/, "");

        try {
          parsedFilters = rison.decode(nativeFilters);
        } catch (error) {
          console.error("Failed to parse native_filters from URL:", error);
        }
      }

      const embeddedDashboard = await getEmbeddedDashboard(foundPlaybook);

      embedDashboard({
        id: embeddedDashboard.embeddedID,
        supersetDomain: supersetUrl(),
        mountPoint: containerEl,
        fetchGuestToken: () => getGuestToken(role, foundPlaybook),
        dashboardUiConfig: {
          hideTitle: true,
          filters: {
            expanded: false,
          },
          urlParams: parsedFilters
            ? { native_filters: rison.encode(parsedFilters) }
            : {},
        },
      });
    };

    f();
  }, [id, role, playbooks]);

  if (!role) {
    return <></>;
  }

  return (
     <div id="my-superset-container"/>
  );
}

async function getGuestToken(role: DataRole, playbook: EmbeddedDashboard) {
  return axiosProxy
     .getInstance()
     .post(`/dataRoles/${role.id}/token/${playbook.id}`)
     .then((resp) => resp.data);
}

async function getEmbeddedDashboard(dashboard: EmbeddedDashboard) {
  return axiosProxy
     .getInstance()
     .get(`/dashboards/${dashboard.id}`)
     .then((resp) => resp.data);
}

function supersetUrl() {
  if (!process.env.REACT_APP_EMBEDDED_BI_URL) {
    throw new Error("REACT_APP_EMBEDDED_BI_URL is not set");
  }
  return process.env.REACT_APP_EMBEDDED_BI_URL.replace(
     ".operata.io",
     auth.getRegionalUrlPrefix() + ".operata.io"
  );
}
