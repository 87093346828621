import React from "react";
import "./ModalWrapper.css";
import PropTypes from "prop-types";

const ModalWrapper = ({ children, onClose }) => {
  const handleWrapperClick = (e) => {
    if (e.target.classList.contains("adagio-modal-overlay")) {
      onClose();
    }
  };

  return (
    <div className="modal-wrapper" onClick={handleWrapperClick}>
      {children}
    </div>
  );
};

ModalWrapper.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
};

export default ModalWrapper;
