import {
  validateRequireField,
  validatePhoneNumberField,
  updateComponentState,
  setHasErrors,
} from "../../Generic/core-validations";
import { calculateMinimumCallDuration } from "./CampaignCreateWizard";

export const validate = (values, requiredFields, component) => {
  const errors = {};

  validateRequireField(requiredFields, values, errors, component);

  validatePhoneNumberField("toNumber", values, errors);

  validateAnswerTreatmentField("answerTreatment", values, errors, component);

  validateCallDurationField("callDuration", values, errors, component);
  validateCallsPerSecondField("callsPerSecond", values, errors, component);

  return errors;
};

const validateAnswerTreatmentField = (field, values, errors, component) => {
  if (
    values[field] &&
    !/^([0-9wW#*]|[wW]{\d+}|[wW]{{\$([^}])+}}|{\$([^}])+})*$/.test(
      values[field]
    )
  ) {
    errors[field] = "Please enter valid answer treatment";
  }
  updateComponentState(component, errors, field);
  setHasErrors(errors);
};

const validateCallDurationField = (field, values, errors, component) => {
  const minCallDuration = calculateMinimumCallDuration(
    values.totalCalls,
    values.callsPerSecond
  );
  if (values[field] < minCallDuration) {
    errors[field] = `Minimum value is ${minCallDuration}`;
  }
  updateComponentState(component, errors, field);
  setHasErrors(errors);
};

const validateCallsPerSecondField = (field, values, errors, component) => {
  if (values[field] && values[field] > values.cpsLimit) {
    errors[field] = `CPS limit is ${values.cpsLimit}`;
  }
  updateComponentState(component, errors, field);
  setHasErrors(errors);
};
