import React, { useState } from "react";
import { Section, InputCopilot } from "@operata/adagio";
import Transcript from "./Transcript";
import "./style.scss";
import "./Chat.css";

const ai_icon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4C2.89543 4 2 4.89543 2 6V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V6C22 4.89543 21.1046 4 20 4H4ZM7.13611 15L9.39611 8.29004H11.4311L13.5661 15H11.8811L11.5161 13.82H9.20111L8.83611 15H7.13611ZM9.60611 12.635H11.1511L10.3911 10.18L9.60611 12.635ZM14.5881 15V8.29004H16.1881V15H14.5881Z"
    />
  </svg>
);

function Chat() {
  const [prompts, setPrompts] = useState([]);
  const [query, setQuery] = useState("");

  function handleChange(event) {
    event.preventDefault();

    setPrompts([{ key: Math.random(), prompt: query }, ...prompts]);
    setQuery("");
  }

  function closeTranscript(key) {
    setPrompts(prompts.filter((prompt) => prompt.key !== key));
  }

  return (
    <Section>
      <Section.Content>
        <div className="ask">
          <form autoComplete="off" onSubmit={handleChange}>
            <InputCopilot
              icon={ai_icon}
              value={query}
              onChange={(event) => setQuery(event.target.value)}
              placeholder="Ask CX Copilot about your Contact Center"
            />
          </form>
        </div>
        <div className="chat">
          {prompts.map(({ key, prompt }) => (
            <Transcript
              key={key}
              prompt={prompt}
              onClose={() => closeTranscript(key)}
            />
          ))}
        </div>
      </Section.Content>
    </Section>
  );
}

export default Chat;
