import { AnyAction, Reducer } from "redux";
import {
  BILLING_LOGS_RECEIVED,
  BILLING_RECEIVED,
  MARKETPLACE_DATA_RECEIVED,
  BILLING_SUBSCRIPTIONS_RECEIVED,
  ERROR_MARKETPLACE_BILLING,
  ERROR_BILLING_SUBSCRIPTIONS,
} from "../actions/billing";

export type GroupBillingData = {
  GroupID: string;
  GroupName: string;
  AgentInteractionDurationRoundedMin: number;
  OperataStatsDurationRoundedMin: number;
  DurationRoundedMin: number;
  HeartbeatTestCount: number;
  AssuranceTestRoundedMin: number;
  AssuranceTestCount: number;
  AgentCount: number;
  AgentDurationRoundedMin: number;
  CloudDurationRoundedMin: number;
};

export interface BillingAuditLogsType {
  provider: string;
  customerID: string;
  customerName: string;
  groupID: string;
  groupName: string;
  sentAt: string | number | Date;
  status: string;
  billingData: Record<string, any>;
}

export type BillingState = {
  loading: boolean;
  billingData: Array<GroupBillingData>;
  billingAuditLogs: Array<BillingAuditLogsType>;
  marketplaceData: Array<any>;
  status?: string;
  subscriptions: Array<any>;
  message: string;
};

const billing: Reducer<BillingState, AnyAction> = (
  state: BillingState = {
    loading: false,
    billingData: [],
    billingAuditLogs: [],
    marketplaceData: [],
    subscriptions: [],
    message: "",
    status: "",
  },
  action: AnyAction
) => {
  switch (action.type) {
    case BILLING_RECEIVED:
      return {
        ...state,
        loading: false,
        billingData: action.billing,
        status: BILLING_RECEIVED,
      };
    case BILLING_LOGS_RECEIVED:
      return {
        ...state,
        loading: false,
        status: BILLING_LOGS_RECEIVED,
        billingAuditLogs: action.data,
      };
    case MARKETPLACE_DATA_RECEIVED:
      return {
        ...state,
        loading: false,
        status: MARKETPLACE_DATA_RECEIVED,
        marketplaceData: action.marketplaceData,
      };
    case BILLING_SUBSCRIPTIONS_RECEIVED:
      return {
        ...state,
        loading: false,
        subscriptions: action.subscriptions,
      };
    case ERROR_MARKETPLACE_BILLING:
      return {
        ...state,
        loading: false,
        status: ERROR_MARKETPLACE_BILLING,
        message: action.message,
      };
    case ERROR_BILLING_SUBSCRIPTIONS:
      return {
        ...state,
        loading: false,
        status: ERROR_BILLING_SUBSCRIPTIONS,
        message: action.message,
      };
    default:
      return state;
  }
};

export default billing;
