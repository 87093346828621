import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  fetchEventSourceConfig,
  handlePostEventSource,
  deleteEventSource,
} from "../../../actions/eventBridge";
import {
  HeadingDetailed,
  ButtonRefactored as Button,
  Stack,
  Section,
  Center,
} from "@operata/adagio";
import IconAdd from "@operata/adagio/dist/Icons/Add";
import EventBridgeTable from "./EventBridgeTable";
import ModalAddEventBridge from "./ModalAddEventBridge";
import ModalDeleteEventBridge from "./ModalDeleteEventBridge";
import { useAppThunkDispatch } from "../../../store/store";
import {
  showSnackbarMessage,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
} from "../../../actions/snackbar";

export type EventBridgeSource = {
  eventSourceId: string;
  awsRegion: string;
  eventSourceName: string;
  accountId: string;
  status: string;
  lastTriggered?: string;
};

export type EventSourceFormDataType = {
  awsRegion: string;
  eventSourceName: string;
  accountId: string;
};

type EventSourceCreateActionError = {
  message: string;
};

const EventBridge = () => {
  const dispatch = useAppThunkDispatch();

  const data = useSelector(
    (state: { eventBridge: { data: EventBridgeSource[] } }) =>
      state.eventBridge.data
  );

  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const closeAddModal = () => {
    setAddModalOpen(false);
  };

  const [selectedForDelete, setSelectedForDelete] =
    useState<EventBridgeSource | null>(null);
  const closeDeleteModal = () => {
    setSelectedForDelete(null);
  };

  const [isCreating, setIsCreating] = useState(false);

  // Fetch event source list on load of page
  useEffect(() => {
    dispatch(fetchEventSourceConfig(true)).finally(() =>
      setInitialLoading(false)
    );
  }, [dispatch]);

  const handleCreateSubmit = (eventSourceFormData: EventSourceFormDataType) => {
    setIsCreating(true);
    dispatch(handlePostEventSource(eventSourceFormData))
      .then(() => {
        closeAddModal();
        dispatch(
          showSnackbarMessage(
            SNACKBAR_SUCCESS,
            "Event source is created successfully."
          )
        );
      })
      .catch((error: EventSourceCreateActionError) => {
        dispatch(
          showSnackbarMessage(
            SNACKBAR_ERROR,
            error.message
              ? error.message
              : "Error - Unable to create event source."
          )
        );
      })
      .finally(() => setIsCreating(false));
  };

  const handleDeleteSubmit = () => {
    dispatch(deleteEventSource(selectedForDelete))
      .then(() => {
        closeDeleteModal();
        dispatch(
          showSnackbarMessage(
            SNACKBAR_SUCCESS,
            "Event source is deleted successfully."
          )
        );
      })
      .catch(() => {
        dispatch(
          showSnackbarMessage(
            SNACKBAR_ERROR,
            "Error - Unable to delete event source."
          )
        );
      });
  };

  return (
    <Center width={"medium"}>
      <Stack direction="column" gap="medium" padding="medium" block>
        <HeadingDetailed
          level={1}
          title="EventBridge Settings"
          actions={
            <Button
              type="primary"
              size="large"
              icon={<IconAdd />}
              onClick={() => setAddModalOpen(true)}
            >
              Add Event Source
            </Button>
          }
        />
        <Section>
          <Stack direction="column" gap="medium" padding="medium" block>
            <HeadingDetailed level={3} title="EventBridge Sources" />
            <EventBridgeTable
              eventSourceData={data}
              onDeleteSelect={setSelectedForDelete}
              loading={initialLoading}
            />
          </Stack>
        </Section>

        {isAddModalOpen && (
          <ModalAddEventBridge
            onClose={closeAddModal}
            handleSubmit={handleCreateSubmit}
            isCreating={isCreating}
          />
        )}
        {selectedForDelete && (
          <ModalDeleteEventBridge
            onClose={closeDeleteModal}
            handleSubmit={handleDeleteSubmit}
          />
        )}
      </Stack>
    </Center>
  );
};

export default EventBridge;
