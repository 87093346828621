import React from "react";
import { validate } from "./ActionConfigEmailValidation";
import TextField from "@material-ui/core/TextField";
import * as PropTypes from "prop-types";
import Select from "@material-ui/core/Select";
import { OutlinedInput, FormControl, InputLabel } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import { validateNumericField } from "../validation";

export class ActionCondition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state[props.field] = props.value ? props.value : this.props.default;

    this.handleChange = this.handleChange.bind(this);
    this.handleMultiSelect = this.handleMultiSelect.bind(this);
    this.handleAddCondition = this.handleAddCondition.bind(this);
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  handleMultiSelect(event) {
    const target = event.target;
    const name = target.name;

    const fieldValues = [...this.state[this.props.field]];
    if (fieldValues.includes(name)) {
      fieldValues.splice(fieldValues.indexOf(name), 1);
    } else {
      fieldValues.push(name);
    }

    this.setState(
      {
        [this.props.field]: fieldValues,
      },
      () => {
        this.props.handleAddCondition(
          this.props.field,
          this.state[this.props.field]
        );
      }
    );
  }

  handleChange(event) {
    const target = event.target;
    const name = target.name;
    let value = target.type === "checkbox" ? target.checked : target.value;

    //Account for blank value bug
    if (target.type !== "checkbox" && this.props.required && !target.value) {
      value = this.props.default;
    }

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.props.handleAddCondition(
          this.props.field,
          this.state[this.props.field]
        );
      }
    );
  }

  handleAddCondition() {
    const { required, field } = this.props;
    let errors = {};
    if (required) {
      errors = validate(this.state, [field], this);
    }

    if (this.props.type && this.props.type === "number") {
      errors = { ...errors, ...validateNumericField(field, this.state, this) };
    }

    if (!errors["hasErrors"]) {
      this.setState({ displayText: field });
    }

    this.props.handleAddCondition(field, this.state[field]);
    return errors;
  }

  render() {
    var fieldType = this.props.type;
    var fieldLabel = this.state["errorMessage_" + this.props.field]
      ? this.state["errorMessage_" + this.props.field]
      : this.props.description;
    var fieldValue = this.state[this.props.field]
      ? this.state[this.props.field]
      : this.props.default;
    const fieldOptions = this.props.options;

    var fieldError =
      this.state["error_" + this.props.field] || this.props.errorMessage;

    switch (fieldType) {
      case "checkbox":
        return (
          <div className="form__control form____label_color">
            <FormControl error={fieldError} variant="outlined">
              <FormLabel>{fieldLabel}</FormLabel>
              {fieldOptions.map((option) => (
                <FormControlLabel
                  key={option}
                  control={
                    <Checkbox
                      checked={fieldValue.includes(option) || false}
                      onChange={this.handleMultiSelect}
                      name={option}
                      value={option}
                    />
                  }
                  label={option}
                />
              ))}
            </FormControl>
          </div>
        );
      case "select":
        return (
          <div className="form__control form____label_color">
            <FormControl variant="outlined">
              <InputLabel htmlFor={this.props.field}>{fieldLabel}</InputLabel>
              <Select
                name={this.props.field}
                error={fieldError}
                variant="outlined"
                value={fieldValue}
                onChange={(event) => this.handleChange(event)}
                input={<OutlinedInput name="" id={this.props.field} />}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {fieldOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        );
      default:
        return (
          <div className="form__control">
            <TextField
              error={fieldError}
              label={fieldLabel}
              id="outlined-required"
              name={this.props.field}
              className="textField"
              onChange={(event) => this.handleChange(event)}
              variant="outlined"
              value={fieldValue}
            />
          </div>
        );
    }
  }
}

ActionCondition.propTypes = {
  field: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  handleAddCondition: PropTypes.func,
  required: PropTypes.bool,
  description: PropTypes.string,
  default: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onRef: PropTypes.func,
  type: PropTypes.string,
  options: PropTypes.array,
  key: PropTypes.string,
  errorMessage: PropTypes.string,
  handleField: PropTypes.func,
};
