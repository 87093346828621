import React, { useEffect, useState } from "react";
import { Button, Modal, MUIDataTable } from "@operata/ui-components";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrUpdateConfig,
  fetchConfig,
  fetchDefaultTaggingConfig,
} from "../../actions/config";
import TaggingConfigWizard from "./TaggingConfigWizard/TaggingConfigWizard";
import Grid from "@material-ui/core/Grid";
import { showConfirmationDialog } from "../../actions/dialog";

export const CALL_TAG_CONFIG_KEY = "CALL_TAG_CONFIGURATIONS";

const CallTaggingConfig = () => {
  const dispatch = useDispatch();
  const { config } = useSelector((state) => state.config);
  const [state, setState] = useState({
    modalOpen: false,
    selectedConfig: {},
  });
  const [taggingConfig, setTaggingConfig] = useState([]);

  useEffect(() => {
    dispatch(fetchConfig(CALL_TAG_CONFIG_KEY, `json`));
  }, [dispatch]);

  useEffect(() => {
    if (
      config.key === CALL_TAG_CONFIG_KEY &&
      (!config.value || config.value.length === 0)
    ) {
      dispatch(fetchDefaultTaggingConfig());
    } else if (config.key === CALL_TAG_CONFIG_KEY && config.value) {
      setTaggingConfig(config.value);
    }
  }, [config, dispatch]);

  const handleEditConfig = (config) => {
    setState({
      modalOpen: true,
      selectedConfig: config,
    });
  };

  const handleAddConfig = () => {
    setState({
      modalOpen: true,
      selectedConfig: {},
    });
  };

  const handleSaveConfig = (config) => {
    const { id } = config;
    delete config.id;

    let updatedConfigs;
    if (id >= 0) {
      updatedConfigs = taggingConfig.map((original, i) => {
        if (i === id) {
          return config;
        }
        return original;
      });
    } else {
      updatedConfigs = [...taggingConfig, config];
    }

    dispatch(
      createOrUpdateConfig(
        {
          key: CALL_TAG_CONFIG_KEY,
          value: updatedConfigs,
        },
        "json"
      )
    );

    setState({
      modalOpen: false,
      selectedConfig: {},
    });
  };

  const deleteConfig = (config) => {
    const updated = [
      ...taggingConfig.slice(0, config.id),
      ...taggingConfig.slice(config.id + 1),
    ];

    dispatch(
      createOrUpdateConfig(
        {
          key: CALL_TAG_CONFIG_KEY,
          value: updated,
        },
        "json"
      )
    );
  };

  const handleRemoveConfig = (config) => {
    dispatch(
      showConfirmationDialog(
        "Delete Config",
        "Are you sure you want to delete this tagging configuration?",
        deleteConfig,
        [config],
        () => {},
        []
      )
    );
  };

  const columns = [
    {
      name: "Name",
    },
    {
      name: "Field",
    },
    {
      name: "Conditions",
      options: {
        filter: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (conditions) => {
          return (
            <div>
              <ul>
                {conditions.map((condition) => {
                  return (
                    <li key={condition.name}>
                      {condition.name}: {condition.operator}{" "}
                      {condition.threshold}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        },
      },
    },
    {
      name: "Actions",
      options: {
        filter: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (config) => {
          return (
            <div className="group__links">
              {
                <React.Fragment>
                  <span
                    className="textfield-bold actions__link"
                    onClick={() => {
                      handleEditConfig(config);
                    }}
                  >
                    Edit
                  </span>
                  <span
                    className="textfield-bold actions__link"
                    onClick={() => {
                      handleRemoveConfig(config);
                    }}
                  >
                    Remove
                  </span>
                </React.Fragment>
              }
            </div>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    filter: true,
    filterType: "dropdown",
    fixedHeader: false,
    download: false,
    print: false,
    search: true,
    viewColumns: false,
    pagination: true,
  };

  return (
    <Grid
      container
      item
      direction={"column"}
      alignItems={"flex-end"}
      spacing={1}
    >
      <Modal
        className="hidden"
        title={"Tagging Configuration"}
        isModalOpen={state.modalOpen}
        onCloseModal={() => {
          setState({
            modalOpen: false,
            selectedConfig: {},
          });
        }}
      >
        <TaggingConfigWizard
          config={state.selectedConfig}
          onSave={handleSaveConfig}
        />
      </Modal>
      <Grid item>
        <Button
          className={"button"}
          buttonText={"New Config"}
          onClick={handleAddConfig}
        />
      </Grid>
      <Grid item>
        <MUIDataTable
          data={
            taggingConfig &&
            taggingConfig.map((value, index) => {
              return [
                value.name,
                value.field,
                value.conditions,
                {
                  ...value,
                  id: index,
                },
              ];
            })
          }
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
};

CallTaggingConfig.propTypes = {};

export default CallTaggingConfig;
