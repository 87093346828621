export let AVAILABLE_CAMPAIGN_TYPES = {};

AVAILABLE_CAMPAIGN_TYPES["fullload"] = {
  image: require("../../../shared/images/full-load-icon.png").default,
  icon: require("../../../shared/images/full-load-icon.png").default,
  type: "Full Load",
  enabled: "",
  description: "Full Load Campaign type",
};

AVAILABLE_CAMPAIGN_TYPES["avalanche"] = {
  image: require("../../../shared/images/avalanche-icon.png").default,
  icon: require("../../../shared/images/avalanche-icon.png").default,
  type: "Avalanche",
  enabled: "disabled",
  description: "Avalanche Campaign type",
};

AVAILABLE_CAMPAIGN_TYPES["ramp"] = {
  image: require("../../../shared/images/ramp-icon.png").default,
  icon: require("../../../shared/images/ramp-icon.png").default,
  type: "Ramp",
  enabled: "disabled",
  description: "Ramp Campaign type",
};

AVAILABLE_CAMPAIGN_TYPES["soak"] = {
  image: require("../../../shared/images/soak-icon.png").default,
  icon: require("../../../shared/images/soak-icon.png").default,
  type: "Soak",
  enabled: "disabled",
  description: "Soak Campaign type",
};
