import React from "react";
import moment from "moment";
import { showSnackbarMessage, SNACKBAR_ERROR } from "../../../actions/snackbar";
import { connect } from "react-redux";
import {
  ERROR_LOGS_RETRIEVAL,
  fetchLogs,
  logUpdated,
} from "../../../actions/heartbeat";
import { dispatchChangeFilter } from "../../../actions/location";

import * as PropTypes from "prop-types";
import { MUIDataTable, Time } from "@operata/ui-components";
import { getTimestampCriteria } from "../../../utils/datatables";
import { NavLink, matchPath, withRouter } from "react-router-dom";
import {
  subscribeToPage,
  unsubscribeFromPage,
} from "../../../websockets/socket";
import { HEARTBEAT_JOBS } from "../../../constants/push";
import { formatDate } from "../../../utils/date";
import { QS } from "../../UNSAFE_route";

const badCallStatusList = [
  "Call Failed",
  "Agent Error",
  "Call Not Accepted",
  "Call Not Accepted by Agent",
];

let LogsList = class Logs extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    subscribeToPage(HEARTBEAT_JOBS, (msg) => {
      this.props.logUpdated(msg);
    });

    const { filterLogs } = this.props;
    this.props.fetchLogs(getTimestampCriteria(filterLogs.timestamp.dateRange));
  }

  componentDidUpdate() {
    if (matchPath(window.location.pathname, this.props.match)) {
      QS.updateFromState("hblogslist");
    }
  }

  componentWillUnmount() {
    unsubscribeFromPage(HEARTBEAT_JOBS);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { showSnackbarMessage } = this.props;

    if (nextProps.status === ERROR_LOGS_RETRIEVAL) {
      showSnackbarMessage(
        SNACKBAR_ERROR,
        "An error occurred while retrieving heartbeat."
      );
    }
  }

  render() {
    const { filterLogs } = this.props;

    if (!filterLogs["timestamp"]) {
      filterLogs["timestamp"] = {
        dateRange: "time:(from:now-7d,to:now)",
        selectedRange: "Last 7 days",
        selectedRangeDisplay: "Last 7 days",
      };
      filterLogs["colIndex"] = 0;
      filterLogs["order"] = "desc";
    }
    if (!filterLogs["filterList"]) {
      filterLogs["filterList"] = {
        jobID: null,
        heartbeatID: null,
        agentID: null,
        diallerCallID: null,
        ccaasCallID: null,
        status: null,
        updatedOn: null,
        carrier: null,
      };
    }

    let sortDirectionForCols = {};
    sortDirectionForCols[filterLogs.colIndex] = filterLogs.order;

    const resultColumns = [
      {
        name: "createdOn",
        label: "Created On",
        options: {
          download: false,
          showColumn: false,
          sortDirection: sortDirectionForCols[0]
            ? sortDirectionForCols[0]
            : null,
          filter: true,
          filterType: "custom",
          customFilterListRender: (value) => {
            if (value.length > 0 && filterLogs.timestamp) {
              return filterLogs.timestamp.selectedRangeDisplay;
            } else {
              return "";
            }
          },
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              return (
                <Time
                  filterCallback={(
                    dateRange,
                    selectedRange,
                    selectedRangeDisplay
                  ) => {
                    filterList[0] = selectedRange;

                    filterLogs["filterColsHistory"] = filterList;
                    filterLogs["timestamp"] = {
                      dateRange: dateRange,
                      selectedRange: selectedRange,
                      selectedRangeDisplay: selectedRangeDisplay,
                    };

                    onChange(dateRange, index, column);
                  }}
                  selectedRange={filterLogs.timestamp.selectedRange}
                  selectedRangeDisplay={
                    filterLogs.timestamp.selectedRangeDisplay
                  }
                  selectedRefreshRate={1}
                  autoRefresh={false}
                  showAutoRefresh={false}
                />
              );
            },
            logic: () => {
              return false;
            },
          },
          filterList: [],
        },
      },
      {
        name: "Created On",
        label: "Created On",
        options: {
          filter: false,
          sort: true,
          sortDirection: sortDirectionForCols[1]
            ? sortDirectionForCols[1]
            : null,
          customBodyRender: (value) => {
            return <div className="textfield">{formatDate(value)}</div>;
          },
        },
      },
      {
        name: "Job ID",
        label: "Job ID",
        options: {
          filter: true,
          customFilterListRender: (value) => `Job ID: ${value}`,
          sort: true,
          customBodyRender: (value) => {
            return (
              <div className="table__long-field">
                <span className="textfield">{value}</span>
              </div>
            );
          },
          sortDirection: sortDirectionForCols[2]
            ? sortDirectionForCols[2]
            : null,

          filterList: filterLogs.filterList["jobID"],
        },
      },
      {
        name: "Heartbeat ID",
        label: "Heartbeat ID",
        options: {
          filter: true,
          customFilterListRender: (value) => `Hearbeat ID: ${value}`,
          sort: true,
          customBodyRender: (value) => {
            return (
              <div className="table__long-field">
                <span className="textfield">{value}</span>
              </div>
            );
          },
          sortDirection: sortDirectionForCols[3]
            ? sortDirectionForCols[3]
            : null,

          filterList: filterLogs.filterList["heartbeatID"],
        },
      },
      {
        name: "Agent ID",
        label: "Agent ID",
        options: {
          filter: true,
          customFilterListRender: (value) => `Agent ID: ${value}`,
          sort: true,
          customBodyRender: (value) => {
            return (
              <div className="table__long-field">
                <span className="textfield">{value}</span>
              </div>
            );
          },
          sortDirection: sortDirectionForCols[4]
            ? sortDirectionForCols[4]
            : null,

          filterList: filterLogs.filterList["agentID"],
        },
      },
      {
        name: "CX Score",
        label: "CX Score",
        options: {
          filter: false,
          sort: true,
          sortDirection: sortDirectionForCols[5]
            ? sortDirectionForCols[5]
            : null,
        },
      },
      {
        name: "AX Score",
        label: "AX Score",
        options: {
          filter: false,
          sort: true,
          sortDirection: sortDirectionForCols[6]
            ? sortDirectionForCols[6]
            : null,
        },
      },
      {
        name: "Network Score",
        label: "Network Score",
        options: {
          filter: false,
          sort: true,
          sortDirection: sortDirectionForCols[7]
            ? sortDirectionForCols[7]
            : null,
        },
      },
      {
        name: "Dialler Call ID",
        label: "Dialler Call ID",
        options: {
          filter: true,
          customFilterListRender: (value) => `Dialler Call ID: ${value}`,
          sort: true,
          customBodyRender: (value) => {
            return (
              <div className="table__long-field">
                <span className="textfield">{value}</span>
              </div>
            );
          },
          sortDirection: sortDirectionForCols[8]
            ? sortDirectionForCols[8]
            : null,

          filterList: filterLogs.filterList["diallerCallID"],
        },
      },
      {
        name: "CCaaS Call ID",
        label: "CCaaS Call ID",
        options: {
          filter: true,
          customFilterListRender: (value) => `CCaaS Call ID: ${value}`,
          sort: true,
          customBodyRender: (value) => {
            return (
              <div className="table__long-field">
                <span className="textfield">{value}</span>
              </div>
            );
          },
          sortDirection: sortDirectionForCols[9]
            ? sortDirectionForCols[9]
            : null,

          filterList: filterLogs.filterList["ccaasCallID"],
        },
      },
      {
        name: "Status",
        label: "Status",
        options: {
          filter: true,
          sort: true,
          sortDirection: sortDirectionForCols[10]
            ? sortDirectionForCols[10]
            : null,

          filterList: filterLogs.filterList["status"],
        },
      },
      {
        name: "Updated On",
        label: "Updated On",
        options: {
          download: false,
          filter: true,
          customFilterListRender: (value) => `Updated On: ${value}`,
          sort: true,
          customBodyRender: (value) => {
            return moment(value).format("DD-MM-YYYY HH:mm:ss");
          },
          sortDirection: sortDirectionForCols[11]
            ? sortDirectionForCols[11]
            : null,

          filterList: filterLogs.filterList["updatedOn"],
        },
      },
      {
        name: "Description",
        label: "Description",
        options: {
          filter: false,
          customFilterListRender: (value) => `Description: ${value}`,
          sort: true,
          customBodyRender: (value) => {
            return (
              <div className="table__long-field">
                <span className="textfield">{value}</span>
              </div>
            );
          },
          sortDirection: sortDirectionForCols[12]
            ? sortDirectionForCols[12]
            : null,
        },
      },
      {
        name: "Carrier",
        label: "Carrier",
        options: {
          filter: true,
          customFilterListRender: (value) => `Carrier: ${value}`,
          sort: true,
          customBodyRender: (value) => {
            return (
              <div className="table__long-field">
                <span className="textfield">{value}</span>
              </div>
            );
          },
          sortDirection: sortDirectionForCols[13]
            ? sortDirectionForCols[13]
            : null,

          filterList: filterLogs.filterList["carrier"],
        },
      },
      {
        name: "Actions",
        options: {
          download: false,
          filter: false,
          search: false,
          sort: false,
          customBodyRender: (jobId) => {
            return (
              <React.Fragment>
                {jobId ? (
                  <NavLink
                    className="textfield-bold actions__link"
                    to={"/gsm/heartbeat/jobs/" + jobId + "/report"}
                  >
                    Report
                  </NavLink>
                ) : (
                  <div />
                )}
              </React.Fragment>
            );
          },
        },
      },
    ];

    const resultOptions = {
      filter: true,
      search: true,
      selectableRows: "none",
      onFilterChange: (column, filterList) => {
        if (column === "createdOn") {
          if (filterList[0].length <= 0) {
            filterLogs["timestamp"] = {
              dateRange: "time:(from:now-7d,to:now)",
              selectedRange: "Last 7 days",
              selectedRangeDisplay: "Last 7 days",
            };
          }

          this.props.fetchLogs(
            getTimestampCriteria(filterLogs.timestamp.dateRange)
          );
        }

        this.props.dispatchChangeFilter(
          {
            ...filterLogs,
            filterList: {
              jobID: filterList[2],
              heartbeatID: filterList[3],
              agentID: filterList[4],
              diallerCallID: filterList[8],
              ccaasCallID: filterList[9],
              status: filterList[10],
              updatedOn: filterList[11],
              carrier: filterList[13],
            },
          },
          "hblogslist"
        );
      },
      customSort: (data, colIndex, order) => {
        if (
          colIndex !== filterLogs.colIndex ||
          (colIndex === filterLogs.colIndex && order !== filterLogs.order)
        ) {
          this.props.dispatchChangeFilter(
            {
              ...filterLogs,
              colIndex: colIndex,
              order: order,
            },
            "hblogslist"
          );
        }

        return data.sort((a, b) => {
          const valueA = a.data[colIndex];
          const valueB = b.data[colIndex];

          if (valueA === "N/A" && valueB !== "N/A") {
            return 1; // "N/A" should be sorted after other values
          } else if (valueA !== "N/A" && valueB === "N/A") {
            return -1; // "N/A" should be sorted after other values
          } else {
            return (valueA < valueB ? -1 : 1) * (order === "asc" ? 1 : -1);
          }
        });
      },
    };

    return (
      <React.Fragment>
        <MUIDataTable
          data={this.props.data.map((result) => {
            return [
              result.createdOn,
              result.createdOn,
              result.jobId,
              result.heartbeatId,
              result.agentId,
              badCallStatusList.includes(result.status)
                ? "N/A"
                : result.cxScore,
              badCallStatusList.includes(result.status)
                ? "N/A"
                : result.axScore,
              badCallStatusList.includes(result.status)
                ? "N/A"
                : result.networkScore,
              result.diallerCallId,
              result.receiverCallId,
              result.status,
              result.updatedOn,
              result.description,
              result.carrier,
              result.jobId,
            ];
          })}
          columns={resultColumns}
          options={resultOptions}
        />
      </React.Fragment>
    );
  }
};

LogsList.propTypes = {
  fetchLogs: PropTypes.func,
  logUpdated: PropTypes.func,
  showSnackbarMessage: PropTypes.func,
  status: PropTypes.string,
  data: PropTypes.array,
  filterLogs: PropTypes.object,
  dispatchChangeFilter: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
};

const mapDispatchToProps = {
  fetchLogs: fetchLogs,
  logUpdated: logUpdated,
  showSnackbarMessage: showSnackbarMessage,
  dispatchChangeFilter: dispatchChangeFilter,
};

const mapStateToProps = (state) => {
  function getFilterLogs() {
    return state.location["hblogslist"] && state.location["hblogslist"].filter
      ? state.location["hblogslist"].filter
      : {};
  }

  return {
    data: state.heartbeat.logs || [],
    loading: state.heartbeat.loading,
    status: state.heartbeat.status,
    reduxPath: state.heartbeat.reduxPath,
    filterLogs: getFilterLogs(),
  };
};

LogsList = connect(mapStateToProps, mapDispatchToProps)(LogsList);

export default withRouter(LogsList);
