import React from "react";
import PropTypes from "prop-types";
import { FormControl } from "@material-ui/core";
import moment from "moment";
import DateTimeRangeContainer from "./lib/index";
import { getRanges } from "./lib/utils/TimeFunctionUtils";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import classNames from "classnames";
import WrapperContext from "./WrapperContext";
import Button from "../Button/Button";

function LinkTab(props) {
  return (
    <Tab component="a" onClick={(event) => event.preventDefault()} {...props} />
  );
}

class Wrapper extends React.Component {
  constructor(props) {
    super(props);
    let now = new Date();
    let end = moment(now);
    let start = moment(now).subtract(15, "minutes");

    this.state = {
      start: start,
      end: end,
      selectedRange: props.selectedRange
        ? props.selectedRange
        : "Last 15 Minutes",
      tab: this.props.selectedRange === "Custom Range" ? 0 : 1,
      pickerOpen: "",
      quickRangeClicked: false,
      rightAlign:
        this.props.rightAlign !== undefined ? this.props.rightAlign : false,
    };

    //this.onClick = this.onClick.bind(this);
    this.applyCallback = this.applyCallback.bind(this);
    this.rangeCallback = this.rangeCallback.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handlePickerGo = this.handlePickerGo.bind(this);
    this.onClickContainerHandler = this.onClickContainerHandler.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { pickerOpen } = nextProps;
    this.setState({ pickerOpen: pickerOpen });

    this.onClickContainerHandler(pickerOpen);
  }

  applyCallback(startDate, endDate) {
    this.setState({
      start: startDate,
      end: endDate,
      selectedRange: "Custom Range",
      quickRangeClicked: false,
    });
  }

  rangeCallback(index, value) {
    this.setState({
      selectedRange: value,
      quickRangeClicked: true,
    });
  }

  handleTabChange(event, tab) {
    this.setState({ tab });
  }

  handlePickerGo() {
    let { dateRangeSelected } = this.props;
    dateRangeSelected(
      this.state.selectedRange ? this.state.selectedRange : "Custom Range",
      this.state.start,
      this.state.end
    );
  }

  onClickContainerHandler(pickerOpen) {
    if (pickerOpen === "open") {
      document.addEventListener("click", this.handleOutsideClick, false);
    }
  }

  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.container && this.props.pickerOpen === "open") {
      if (this.container.contains(e.target)) {
        return;
      }
      document.removeEventListener("click", this.handleOutsideClick, false);
      this.setState({ pickerOpen: "" });

      this.props.setPickerOpen && this.props.setPickerOpen("");
    } else {
      /* onClickContainerHandler(pickerOpen === 'open') adds the event listener,
         we need to remove it when pickerOpen !== 'open'
         This can't be on onClickContainerHandler since that isn't triggered on setState
      */
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
  }

  renderVanillaPicker(ranges, local, maxDate) {
    const { tab, pickerOpen, rightAlign } = this.state;
    let value = `${this.state.start.format(
      "DD-MM-YYYY HH:mm"
    )} - ${this.state.end.format("DD-MM-YYYY HH:mm")}`;
    return (
      <div
        className={classNames(
          "time__wrapper",
          {
            open: pickerOpen,
          },
          {
            right_align: rightAlign,
          }
        )}
        ref={(container) => {
          this.container = container;
        }}
      >
        <div className="time__header">
          <h3>Time Range</h3>
          <Tabs
            className="time__links"
            value={tab}
            onChange={this.handleTabChange}
          >
            <LinkTab label="Absolute" href="page1" />
            <LinkTab label="Quick" href="page2" />
          </Tabs>
        </div>

        <div
          className={classNames("time__content", {
            open: pickerOpen,
          })}
        >
          <WrapperContext.Provider value={{ state: this.state }}>
            <DateTimeRangeContainer
              ranges={ranges}
              start={this.state.start}
              end={this.state.end}
              local={local}
              maxDate={maxDate}
              autoApply={true}
              applyCallback={this.applyCallback}
              rangeCallback={this.rangeCallback}
              handleOutsideClick={this.handleOutsideClick}
            >
              <FormControl
                id="formControlsTextB"
                type="text"
                label="Text"
                placeholder="Enter text"
                style={{ cursor: "pointer" }}
                disabled
                value={value}
              />
            </DateTimeRangeContainer>
            <div className="button__wrapper">
              <Button
                onClick={this.handlePickerGo}
                open={this.state.pickerOpen}
                className={"btnSolidSmall"}
                buttonText={"Set"}
              />
            </div>
          </WrapperContext.Provider>
        </div>
      </div>
    );
  }

  render() {
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let ranges = getRanges(now);

    let local = {
      format: "DD-MM-YYYY HH:mm",
      sundayFirst: false,
    };

    let maxDate = moment(start).add(24, "hour");
    return (
      <div className="container">
        {this.renderVanillaPicker(ranges, local, maxDate)}
      </div>
    );
  }
}

Wrapper.propTypes = {
  selectedRange: PropTypes.string,
  pickerOpen: PropTypes.string,
  setPickerOpen: PropTypes.func,
  dateRangeSelected: PropTypes.func,
  rightAlign: PropTypes.bool,
};

export default Wrapper;
