import React from "react";
import MUIDataTable from "../Datatables";
import Pagination from "../Pagination/Pagination";
import "./Campaigns.scss";
import "../ActionBar/ActionBar.scss";
import { formatDate } from "../../utils/date";
import AudioPlayer from "../Audio/AudioPlayer";
import PropTypes from "prop-types";
const SECOND_IN_MILLS = 1000;

class CallLog extends React.Component {
  render() {
    const columns = [
      {
        name: "sid",
        label: "SID",
        options: {
          filter: false,
          sort: true,
          display: false,
          customBodyRender: (value) => {
            return <div className="textfield">{value}</div>;
          },
        },
      },
      {
        name: "AnswerTreatment",
        label: "Answer Treatment",
        options: {
          filter: false,
          sort: true,
          display: false,
          customBodyRender: (value) => {
            return <div className="textfield textfield-breakword">{value}</div>;
          },
        },
      },
      {
        name: "CreatedAt",
        label: "Created",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div className="textfield">{value}</div>;
          },
        },
      },
      {
        name: "QueuedAt",
        label: "Queued",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div className="textfield">{value}</div>;
          },
        },
      },
      {
        name: "InitiatedAt",
        label: "Initiated",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div className="textfield">{value}</div>;
          },
        },
      },
      {
        name: "RingingAt",
        label: "Ringing",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div className="textfield">{value}</div>;
          },
        },
      },
      {
        name: "InProgressAt",
        label: "In Progress",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div className="textfield">{value}</div>;
          },
        },
      },
      {
        name: "CompletedAt",
        label: "Completed",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div className="textfield">{value}</div>;
          },
        },
      },
      {
        name: "NoAnswerAt",
        label: "No Answer",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div className="textfield">{value}</div>;
          },
        },
      },
      {
        name: "BusyAt",
        label: "Busy",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div className="textfield">{value}</div>;
          },
        },
      },
      {
        name: "TerminatedPrematurelyAt",
        label: "Terminated Premature",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div className="textfield">{value}</div>;
          },
        },
      },

      {
        name: "FailedAt",
        label: "Failed",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div className="textfield">{value}</div>;
          },
        },
      },
      {
        name: "Duration",
        label: "Duration (Seconds)",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <div className="textfield">{value}</div>;
          },
        },
      },
      {
        name: "PostDialDelay",
        label: "Post Dial Delay (Seconds)",
        options: {
          filter: false,
          sort: true,
          display: false,
          customBodyRender: (value) => {
            return <div className="textfield">{value}</div>;
          },
        },
      },
      {
        name: "TimeToAnswer",
        label: "Time to Answer (Seconds)",
        options: {
          filter: false,
          sort: true,
          display: false,
          customBodyRender: (value) => {
            return <div className="textfield">{value}</div>;
          },
        },
      },
      {
        name: "Play",
        options: {
          filter: false,
          customBodyRender: (fileName) => {
            return (
              fileName && (
                <AudioPlayer src={fileName} size={35} borderWidth={2} />
              )
            );
          },
        },
      },
    ];

    const options = {
      selectableRows: "none",
      filter: true,
      filterType: "dropdown",
      fixedHeader: false,
      download: true,
      print: false,
      search: true,
      viewColumns: true,
      rowsPerPage: 10,
      customFooter: (
        count,
        page,
        rowsPerPage,
        changeRowsPerPage,
        changePage
      ) => {
        return (
          <Pagination
            className="pagination__wrapper"
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={(event) =>
              changeRowsPerPage(event.target.value)
            }
            onChangePage={(_, page) => changePage(page)}
          />
        );
      },
    };

    var data = this.props.callLog.map((item) => {
      const postDialDelayMillis = item.ringingAt - item.initiatedAt;
      const timeToAnswerMillis = item.inProgressAt - item.ringingAt;
      return [
        item.callSid,
        item.answerTreatment || "",
        formatDate(item.createdAt),
        formatDate(item.queuedAt),
        formatDate(item.initiatedAt),
        formatDate(item.ringingAt),
        formatDate(item.inProgressAt),
        formatDate(item.completedAt),
        formatDate(item.noAnswerAt),
        formatDate(item.busyAt),
        formatDate(item.terminatedPrematurelyAt),
        formatDate(item.failedAt),
        item.callDuration,
        postDialDelayMillis / SECOND_IN_MILLS,
        timeToAnswerMillis / SECOND_IN_MILLS,
        item.recordingUrl,
      ];
    });

    return (
      <React.Fragment>
        <MUIDataTable data={data} columns={columns} options={options} />
      </React.Fragment>
    );
  }
}

CallLog.propTypes = {
  callLog: PropTypes.array,
};

export default CallLog;
