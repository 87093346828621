import {
  withLDProvider,
  useFlags,
  useLDClient,
  withLDConsumer,
} from "launchdarkly-react-client-sdk";
import { ComponentType, ReactComponentElement } from "react";

export const useFeatureFlags = useFlags;
export const useFeatureFlagsClient = useLDClient;
export const withFeatureFlagsConsumer: (options?: {
  clientOnly: boolean;
}) => (_: any) => any = withLDConsumer;
export const withFeatureFlagsProvider = (App: ComponentType) => {
  return withLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_APP_ID as string,
    options: {
      streaming: false,
      bootstrap: {
        "embedded-canvas-api": null,
      },
    },
  })(App);
};
