export const COMPLETED_STATUS = "Completed";

export const TELEPHONY_TAGS = {
  "Poor Audio Quality": [
    "Poor Outbound call quality",
    "Very Poor Outbound call quality",
    "Poor Inbound call quality",
    "Very Poor Inbound call quality",
  ],
  "High Audio Latency": [
    "High PSTN Audio Latency",
    "Very High PSTN Audio Latency",
  ],
  "High Post Dial Delay": [
    "High Call connection time",
    "Very High Call connection time",
  ],
  "High Ringing Time": ["High Ringing time", "Very High Ringing time"],
};

export const CUSTOMER_EXPERIENCE_TAGS = {
  "Poor Audio Quality": [
    "Poor Inbound call quality",
    "Very Poor Inbound call quality",
    "Poor Outbound call quality",
    "Very Poor Outbound call quality",
  ],
  "High Audio Latency": ["High Echo Latency", "Very High Echo Latency"],
};

export const AGENT_EXPERIENCE_TAGS = {
  "Poor Audio Quality": [
    "Poor Call quality inbound to Agent",
    "Very Poor Call quality inbound to Agent",
    "Poor Call quality outbound from Agent",
    "Very Poor Call quality outbound from Agent",
  ],
  "High Audio Latency": [
    "High Audio Latency between CCaaS & Agent",
    "Very High Audio Latency between CCaaS & Agent",
  ],
  "High CPU": ["High CPU", "Very High CPU"],
  "Low Memory": ["Low Memory", "Very Low Memory"],
  "Softphone Errors": ["Softphone Error"],
  "High Connection Time": [
    "High Agent connection time",
    "Very High Agent connection time",
  ],
  "Unsupported Browser": ["Unsupported Browser Version"],
};

export const NETWORK_PERFORMANCE_TAGS = {
  "High Packet Loss": ["High Packet Loss", "Very High Packet Loss"],
  "High Jitter": ["High Jitter", "Very High Jitter"],
  "High RTT": ["High RTT", "Very High RTT"],
  "Low MOS": ["Low MOS", "Very Low MOS"],
};

export const POLQA_MAX_SCORES = {
  POLQA: 4.5,
  "POLQA SWB": 4.8,
};

export const getTagsWithoutCategory = (categorizedTags) => {
  return Object.values(categorizedTags).reduce((tagsA, tagsB) =>
    tagsA.concat(tagsB)
  );
};
