import React, { useState } from "react";
import { auth } from "../../auth/Auth";
import { Redirect } from "react-router-dom";
import {
  getCurrentURLPath,
  getGroupIdFromRequestedURL,
} from "../../utils/deeplinks";
import { showConfirmationDialog } from "../../actions/dialog";
import { useDispatch, useSelector } from "react-redux";
import { SWITCH_GROUP_SUCCESSFUL } from "../../actions/auth";
import { validate as uuidValidate } from "uuid";

const NotFound = () => {
  const [redirectNow, setRedirectNow] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(
    "/" + auth.getCurrentGroupId() + "/dashboard"
  );
  const [confirmationShown, setConfirmationShown] = useState(false);
  const dispatch = useDispatch();
  const { switchGroupStatus, requestedUrl } = useSelector(
    (state) => state.auth
  );

  const handleSwitchGroup = (groupId, requestedUrl) => {
    auth.switchGroup(groupId, requestedUrl);
  };

  const redirectToURL = (url) => {
    setRedirectUrl(url);
    setRedirectNow(true);
  };

  if (redirectNow) {
    return <Redirect to={redirectUrl} />;
  }

  if (switchGroupStatus === SWITCH_GROUP_SUCCESSFUL) {
    window.location.replace(requestedUrl);
  }

  const groupIdFromRequestedURL = getGroupIdFromRequestedURL();
  const currentURLPath = getCurrentURLPath();

  function showPageNotFoundMessage() {
    const currentURLPath = getCurrentURLPath();

    if (currentURLPath.length <= 1) {
      redirectToURL("/" + auth.getCurrentGroupId() + "/dashboard");
    } else if (!confirmationShown) {
      setConfirmationShown(true);
      dispatch(
        showConfirmationDialog(
          "Page couldn't be found.",
          "The requested page couldn't be found. You will be redirected to the dashboard.",
          redirectToURL,
          ["/" + auth.getCurrentGroupId() + "/dashboard"],
          null,
          []
        )
      );
    }
  }

  if (groupIdFromRequestedURL && !uuidValidate(groupIdFromRequestedURL)) {
    redirectToURL("/" + auth.getCurrentGroupId() + currentURLPath);
  } else if (
    groupIdFromRequestedURL &&
    groupIdFromRequestedURL !== auth.getCurrentGroupId()
  ) {
    let switchToGroupName = null;
    auth.getUserProfile().groups.map((group) => {
      if (group.id === groupIdFromRequestedURL) {
        switchToGroupName = group.name;
      }
    });

    if (switchToGroupName) {
      if (!confirmationShown) {
        setConfirmationShown(true);

        dispatch(
          showConfirmationDialog(
            "Switch Group",
            "You are about to access a page on Group '" +
              switchToGroupName +
              "'. Do you want to continue?",
            handleSwitchGroup,
            [groupIdFromRequestedURL, currentURLPath],
            requestedUrl,
            ["/" + auth.getCurrentGroupId() + "/dashboard"]
          )
        );
      }
    } else {
      showPageNotFoundMessage();
    }
  } else {
    showPageNotFoundMessage();
  }

  return <></>;
};

export default NotFound;
