import React from "react";
import WorkflowContext from "../WorkflowContext";
import PropTypes from "prop-types";

class StepAction extends React.Component {
  renderAction(selectedTrigger, selectedAction, setAction, availableActions) {
    return Object.keys(availableActions).map((key) => {
      let { displayText, image, enabled } = availableActions[key];

      if (
        selectedTrigger === "eb-summary-data" ||
        selectedTrigger === "eb-sod-test-data"
      ) {
        if (displayText === "Amazon Eventbridge") {
          enabled = "";
        } else {
          enabled = "disabled";
        }
      }

      if (
        selectedTrigger === "aws-connect-agent-issue-reported" ||
        selectedTrigger === "heartbeat-telephony"
      ) {
        if (
          displayText === "Send Email" ||
          displayText === "Invoke Webhook" ||
          displayText === "Slack" ||
          displayText === "Amazon Eventbridge"
        ) {
          enabled = "";
        } else {
          enabled = "disabled";
        }
      }

      let selected = key === selectedAction ? "selected" : "";
      return (
        <li
          className={`actions__cell ${enabled} ${selected}`}
          key={key}
          onClick={() => {
            if (enabled !== "disabled") setAction(key);
          }}
        >
          <img src={image} alt={displayText} />
          <p className="actions__name">{displayText}</p>
        </li>
      );
    });
  }

  render() {
    return (
      <WorkflowContext.Consumer>
        {({ trigger, action, setAction, availableActions }) => (
          <div className="actions__grid">
            {this.props.renderSearch()}

            <ul className="auto-grid">
              {this.renderAction(trigger, action, setAction, availableActions)}
            </ul>
          </div>
        )}
      </WorkflowContext.Consumer>
    );
  }
}
StepAction.propTypes = {
  renderSearch: PropTypes.func,
};

StepAction.contextType = WorkflowContext;

export default StepAction;
