import { auth } from "../auth/Auth";
import { v1 as uuid } from "uuid";
import { SHOW_PROGRESS } from "./progress";
import axios from "axios";
import { axiosProxy } from "../axios/AxiosProxy";
import _ from "lodash";

export const JOB_INITIALISED = "JOB_INITIALISED";
export const OUBOUND_CALL_INVOKED = "OUBOUND_CALL_INVOKED";
export const INBOUND_CALL_INVOKED = "INBOUND_CALL_INVOKED";
export const OUBOUND_CALL_FAILED = "OUBOUND_CALL_FAILED";
export const INBOUND_CALL_FAILED = "INBOUND_CALL_FAILED";
export const PERFORMANCE_RESULTS_RECEIVED = "PERFORMANCE_RESULTS_RECEIVED";
export const PERFORMANCE_HISTORY_RECEIVED = "PERFORMANCE_HISTORY_RECEIVED";
export const ERROR_PERFORMANCE_RESULTS_RETRIEVAL =
  "ERROR_PERFORMANCE_RESULTS_RETRIEVAL";
export const ERROR_PERFORMANCE_HISTORY_RETRIEVAL =
  "ERROR_PERFORMANCE_HISTORY_RETRIEVAL";
export const ERROR_POLQA_CALCULATION = "ERROR_POLQA_CALCULATION";
export const RESET_POLQA_CALCULATION = "RESET_POLQA_CALCULATION";
export const ERROR_OBTAINING_POOL_NUMBER = "ERROR_OBTAINING_POOL_NUMBER";

export const showProgress = () => ({
  type: SHOW_PROGRESS,
});

export const jobInitialised = (jobData) => ({
  type: JOB_INITIALISED,
  jobData: jobData,
});

export const outboundCallInvoked = () => ({
  type: OUBOUND_CALL_INVOKED,
});

export const inboundCallInvoked = () => ({
  type: INBOUND_CALL_INVOKED,
});

export const inboundCallFailed = (message) => ({
  type: INBOUND_CALL_FAILED,
  message: message,
});

export const performanceResultsReceived = (results) => ({
  type: PERFORMANCE_RESULTS_RECEIVED,
  results: results,
});

export const performanceHistoryReceived = (results) => ({
  type: PERFORMANCE_HISTORY_RECEIVED,
  results: results,
});

export const errorPerformanceResultsRetrieval = () => ({
  type: ERROR_PERFORMANCE_RESULTS_RETRIEVAL,
});

export const errorPerformanceHistoryRetrieval = () => ({
  type: ERROR_PERFORMANCE_HISTORY_RETRIEVAL,
});

export const showPOLQACalculationError = (message, timestamp) => ({
  type: ERROR_POLQA_CALCULATION,
  message: message,
  timestamp: timestamp,
});

export const resetPOLQACalculationError = () => ({
  type: RESET_POLQA_CALCULATION,
  message: "",
  timestamp: "",
});

export const errorObtainingAPoolNumber = () => ({
  type: ERROR_OBTAINING_POOL_NUMBER,
});

//redux-thunk
export const initTest = () => {
  return (dispatch) => {
    console.log("dispatching show progress..");
    dispatch(showProgress());
    dispatch(jobInitialised(getCurrentJob()));
  };
};

export const renewTest = () => {
  return (dispatch) => {
    console.log("dispatching show progress..");
    dispatch(showProgress());
    let newJob = makeNewJobObject();
    setCurrentJob(newJob);
    dispatch(jobInitialised(Object.assign({}, getCurrentJob())));
    //emptying performance results

    setTimeout(() => {
      dispatch(performanceResultsReceived([]));
    }, 500);
  };
};

const getCurrentJob = () => {
  if (localStorage.getItem("currentJob")) {
    let currentJob = JSON.parse(localStorage.getItem("currentJob"));
    return currentJob;
  } else {
    let newJob = makeNewJobObject();
    setCurrentJob(newJob);
    return newJob;
  }
};

export const setCurrentJob = (job) => {
  localStorage.setItem("currentJob", JSON.stringify(job));
};

export const invokeOutboundCall = (test, jobId) => {
  return (dispatch) => {
    console.log("dispatching show progress..");
    dispatch(showProgress());

    getAPoolNumber(jobId, test)
      .then((resp) => {
        let poolNumber = resp.data.number.replace("+", "");
        console.log(poolNumber);
        setRecordingConfig(poolNumber, test);

        window.makeASoftCall("+" + poolNumber);
        dispatch(outboundCallInvoked());
      })
      .catch((error) => {
        dispatch(errorObtainingAPoolNumber());
        console.log(error);
      });
  };
};

const setRecordingConfig = (poolNumber, test) => {
  const softphoneConfig = Object.assign({}, test, {
    phoneNumber: poolNumber,
  });
  window.setRecordingConfig(softphoneConfig);
  setCurrentJob(softphoneConfig);
};

export const invokeInboundCall = (test, jobId) => {
  return (dispatch) => {
    console.log("dispatching show progress..");
    dispatch(showProgress());

    getAPoolNumber(jobId, test)
      .then((resp) => {
        let poolNumber = resp.data.number.replace("+", "");
        console.log(poolNumber);
        setRecordingConfig(poolNumber, test);

        axios
          .get(
            process.env.REACT_APP_TWILIO_FUNCTION_URL +
              "/" +
              test.twilioFunction +
              "?callFrom=" +
              poolNumber +
              "&jobId=" +
              jobId
          )
          .then((resp) => {
            console.log("dispatching workflow created..");
            dispatch(inboundCallInvoked(resp.data));
          })
          .catch(() => {
            dispatch(
              inboundCallFailed(
                "An error occurred while invoking the inbound call"
              )
            );
          });
      })
      .catch((error) => {
        dispatch(errorObtainingAPoolNumber());
        console.log(error);
      });
  };
};

export const fetchPerformanceResults = (jobId) => {
  return (dispatch) => {
    // console.log('dispatching show progress..');
    // dispatch(showProgress());
    return getPerformanceResults(jobId)
      .then((resp) => {
        console.log("dispatching actionHistoryReceived..");
        let validatedResults = validateResults(resp.data, dispatch);
        dispatch(performanceResultsReceived(validatedResults));
      })
      .catch((error) => {
        console.log(error);
        //dispatch(errorPerformanceResultsRetrieval());
      });
  };
};

const validateHistory = (data) => {
  let validatedResults = [];
  if (data) {
    data.map((item) => {
      let result = item._source;
      validatedResults.push(result);
    });
  }

  return validatedResults;
};

const validateResults = (data, dispatch) => {
  let validatedResults = [];
  let failedLocations = [];

  let highestTimestamp = null;
  if (data) {
    data.map((item) => {
      let result = item._source;
      validatedResults.push(result);
    });
  }

  if (failedLocations.length > 0) {
    localStorage.setItem("lastValidatedTimestamp", highestTimestamp);
    let errorLocations = _.map(_.unionBy(failedLocations)).join(", ");
    let errorMessage = `Failed to complete POLQA analysis for locations: (${errorLocations}). Please try again.`;
    dispatch(
      showPOLQACalculationError(
        errorMessage,
        localStorage.getItem("lastValidatedTimestamp")
      )
    );
  }

  return validatedResults;
};

export const fetchPerformanceHistory = () => {
  return (dispatch) => {
    return getPerformanceHistory()
      .then((resp) => {
        let validatedResults = validateHistory(resp.data);
        dispatch(performanceHistoryReceived(validatedResults));
      })
      .catch(() => {
        dispatch(errorPerformanceHistoryRetrieval());
      });
  };
};

export const releasePoolNumber = () => {
  // eslint-disable-next-line no-unused-vars
  return (dispatch) => deletePoolNumber();
};

const getPerformanceResults = (jobId) => {
  return axiosProxy
    .getInstance()
    .get("/data/performance/" + auth.getCurrentGroupId() + "/" + jobId);
};

const getPerformanceHistory = () => {
  return axiosProxy
    .getInstance()
    .get("/data/performance/" + auth.getCurrentGroupId());
};

const makeNewJobObject = function () {
  return {
    jobId: uuid(),
    metric: "POLQA SWB",
    output: "Output 4",
    tags: "",
    groupId: auth.getCurrentGroupId(),
  };
};

const getAPoolNumber = function (jobId, test) {
  return axiosProxy
    .getInstance()
    .get("/performance/manager/number?jobid=" + jobId + "&name=" + test.type);
};

const deletePoolNumber = () => {
  const currentJob = getCurrentJob();
  if (currentJob && currentJob.phoneNumber) {
    return axiosProxy
      .getInstance()
      .delete(
        "/performance/manager/number?jobid=" +
          currentJob.jobId +
          "&number=" +
          encodeURIComponent("+" + currentJob.phoneNumber)
      );
  }
};
