import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import ChipInput from "material-ui-chip-input";

const SoftphoneAllowlistingConfig = ({ error, label, value, onChange }) => {
  let origins = [];
  let pages = {};

  if (value) {
    const valueArr = typeof value === "string" ? value.split(",") : value;
    origins = valueArr.filter(
      (val) => val.replace(/https?:\/\//, "").split("/").length === 1
    );
    origins.forEach((origin) => {
      pages[origin] = valueArr
        .filter(
          (val) =>
            val.startsWith(origin) &&
            val.replace(/https?:\/\//, "").split("/").length > 1
        )
        .map((pagesWithOrigin) => {
          return (
            "/" +
            pagesWithOrigin.replace(/https?:\/\//, "").match(/(.*?)\/(.*)/)[2]
          );
        });
    });
  }

  const onAddOrigin = (origin) => {
    if (!origin.startsWith("https://") && !origin.startsWith("http://")) {
      origin = `https://${origin}`;
    }

    const originMatch = origin.match(/(https?:\/\/.*?)[/#?](.*)/);
    if (originMatch && originMatch.length > 2) {
      origin = originMatch[1];
    }

    origins = _.union(origins, [origin]);
    updateConfigValue();
  };

  const onRemoveOrigin = (origin) => {
    origins = _.without(origins, origin);
    delete pages[origin];
    updateConfigValue();
  };

  const onAddPage = (origin, page) => {
    page = page.replace(origin, "");

    if (
      !page.startsWith("/") &&
      !page.startsWith("#") &&
      !page.startsWith("?")
    ) {
      page = `/${page}`;
    }
    const originPages = _.union(pages[origin], [page]);

    pages = Object.assign({}, pages, {
      [origin]: originPages,
    });
    updateConfigValue();
  };

  const onRemovePage = (origin, page) => {
    const originPages = _.without(pages[origin], page);

    pages = Object.assign({}, pages, {
      [origin]: originPages,
    });
    updateConfigValue();
  };

  const updateConfigValue = () => {
    const allPages = Object.entries(pages)
      .map(([origin, pages]) => {
        return pages.map((page) => `${origin}${page}`);
      })
      .flat();

    onChange(_.union(origins, allPages));
  };

  return (
    <>
      <ChipInput
        error={error}
        className="tags__wrapper"
        label={label}
        placeholder="Type and press enter to add"
        value={origins}
        onAdd={onAddOrigin}
        onDelete={onRemoveOrigin}
      />

      {origins.map((origin) => (
        <>
          <div className={"card__title h5"}>{origin}</div>
          <ChipInput
            className="tags__wrapper"
            label={"Allowlisted Pages for Messenger"}
            placeholder="Type and press enter to add"
            value={pages[origin]}
            onAdd={(page) => onAddPage(origin, page)}
            onDelete={(page) => onRemovePage(origin, page)}
          />
        </>
      ))}
    </>
  );
};

SoftphoneAllowlistingConfig.propTypes = {
  error: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default SoftphoneAllowlistingConfig;
