import PropTypes from "prop-types";
import React from "react";
import Button from "../../Button/Button";
import Search from "../../Search/Search";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CampaignContext from "./CampaignContext";
import {
  fetchCampaign,
  startCampaign,
  ERROR_CAMPAIGN_CREATED,
  CAMPAIGN_RECEIVED,
} from "../../../actions/campaign";
import { showConfirmationDialog } from "../../../actions/dialog";
import { connect } from "react-redux";
import { showSnackbarMessage, SNACKBAR_ERROR } from "../../../actions/snackbar";
import { validate } from "./validation";
import "../../Wizard/Wizard.scss";
import { AVAILABLE_CAMPAIGN_TYPES } from "./WizardConstants";
import StepCampaign from "./WizardContent/StepCampaign";
import StepVisualise from "./WizardContent/StepVisualise";
import StepRun from "./WizardContent/StepRun";
import StepData from "./WizardContent/StepData";

const STEP_CAMPAIGN = 0;
const STEP_VISUALISE = 1;
const STEP_DATA = 2;
let STEP_RUN = 3;

function getSteps(withDataStep) {
  if (withDataStep) {
    STEP_RUN = 3;
    return ["Campaign", "Visualise", "Data", "Run"];
  } else {
    STEP_RUN = 2;
    return ["Campaign", "Visualise", "Run"];
  }
}

let CampaignRunWizard = class CampaignRunWizard extends React.Component {
  setRunTags(runTags) {
    this.setState({ runTags });
  }

  setRunDescription(runDescription) {
    this.setState({ runDescription });
  }

  setApproved(approved) {
    this.setState({ approved });
  }

  handleStartCampaign() {
    let { startCampaign } = this.props;

    let run = {
      campaignId: this.state.campaignId,
      tags: this.state.runTags,
      description: this.state.runDescription,
    };

    let stepErrors = this.validateCurrentStep();

    if (!stepErrors["hasErrors"]) {
      startCampaign(run);
    } else {
      this.showValidationError(stepErrors);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { showSnackbarMessage } = this.props;

    if (nextProps.status === CAMPAIGN_RECEIVED) {
      this.setFormSate(nextProps);
    } else if (nextProps.status === ERROR_CAMPAIGN_CREATED) {
      showSnackbarMessage(
        SNACKBAR_ERROR,
        "An error occurred while creating the campaign."
      );
    }
  }

  constructor(props) {
    super(props);

    this.getStepContent = this.getStepContent.bind(this);

    this.setApproved = this.setApproved.bind(this);
    this.setRunTags = this.setRunTags.bind(this);
    this.setRunDescription = this.setRunDescription.bind(this);

    this.handleStartCampaign = this.handleStartCampaign.bind(this);
    this.setFormSate = this.setFormSate.bind(this);

    this.initFormState = {
      campaignId: "-1",
      activeStep: 0,
      type: "",
      name: "",
      description: "",
      tags: [],

      requiresApproval: false,
      approved: false,
      setApproved: this.setApproved,

      runDescription: "",
      runTags: [],

      setRunDescription: this.setRunDescription,
      setRunTags: this.setRunTags,

      fromNumber: "",

      toNumber: "",

      totalCalls: 0,

      noAnswerTimeout: 0,

      callDuration: 0,

      callsPerSecond: 0,

      answerTreatment: "",

      data: [],

      availableTypes: AVAILABLE_CAMPAIGN_TYPES,
    };

    this.state = this.initFormState;
  }

  setFormSate(props) {
    this.setState(this.initFormState);

    let { campaign } = props;
    if (campaign) {
      this.setState({
        campaignId: campaign.id,
        type: campaign.type,
        name: campaign.name,
        description: campaign.description,

        tags:
          !campaign.tags ||
          typeof campaign.tags === "string" ||
          campaign.tags instanceof String
            ? []
            : campaign.tags,

        fromNumber: campaign.fromNumber,
        toNumber: campaign.toNumber,
        totalCalls: campaign.totalCalls,
        noAnswerTimeout: campaign.noAnswerTimeout,
        callDuration: campaign.callDuration,
        callsPerSecond: campaign.callsPerSecond,
        answerTreatment: campaign.answerTreatment,
        data: campaign.data || [],
        requiresApproval:
          campaign.totalCalls > 300 || campaign.callsPerSecond > 6,
      });
    }
  }

  componentDidMount() {
    const campaignId = this.props.match.params.campaignId;

    if (campaignId) {
      this.props.fetchCampaign(campaignId);
    }
  }

  showValidationError(errors) {
    let { showSnackbarMessage } = this.props;
    switch (this.state.activeStep) {
      case STEP_RUN:
        if (errors["approved"]) {
          showSnackbarMessage(
            SNACKBAR_ERROR,
            "Please tick the approval checkbox to confirm you have the approval to run this campaign."
          );
        } else {
          showSnackbarMessage(
            SNACKBAR_ERROR,
            "Please enter the run description to continue."
          );
        }
        return;
    }
  }

  validateCurrentStep() {
    let errors = {};
    let requiredFields;

    switch (this.state.activeStep) {
      case STEP_RUN:
        requiredFields = ["runDescription"];
        if (this.state.requiresApproval) {
          requiredFields.push("approved");
        }
        return (errors = validate(this.state, requiredFields, this));
    }

    return errors;
  }

  renderSearch() {
    if (this.displaySearch === "true") {
      return (
        <div className="search__container">
          <Search />
        </div>
      );
    }
  }

  getStepContent(stepIndex) {
    switch (stepIndex) {
      case STEP_CAMPAIGN:
        return <StepCampaign />;
      case STEP_VISUALISE:
        return <StepVisualise />;
      case STEP_RUN:
        return <StepRun />;
      case STEP_DATA:
        return <StepData />;
      default:
        return "Unknown step";
    }
  }

  handleNext = () => {
    const { activeStep } = this.state;
    let stepErrors = this.validateCurrentStep();
    if (!stepErrors["hasErrors"]) {
      this.setState({
        activeStep: activeStep + 1,
      });
    } else {
      this.showValidationError();
    }
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1,
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  render() {
    const steps = getSteps(this.hasData());

    const { activeStep } = this.state;
    const displayPrev = this.state.activeStep > 0;
    const displayNext = this.state.activeStep < steps.length - 1;
    const displaySave = this.state.activeStep === steps.length - 1;

    return (
      <React.Fragment>
        <Stepper activeStep={activeStep} className="wizard__progress">
          {steps.map((label, index) => {
            const labelProps = {};
            if (index === activeStep) {
              labelProps.icon = (
                <div className="step step__active">{label}</div>
              );
            }
            if (index > activeStep) {
              labelProps.icon = (
                <div className="step step__future">{label}</div>
              );
            }
            if (index < activeStep) {
              labelProps.icon = <div className="step step__past">{label}</div>;
            }
            return (
              <Step key={label}>
                <StepLabel icon={index + 1} {...labelProps} />
              </Step>
            );
          })}
        </Stepper>
        <div className="wizard__content">
          <CampaignContext.Provider value={this.state}>
            {this.getStepContent(this.state.activeStep)}
          </CampaignContext.Provider>
        </div>

        <div className="wizard__btn-container">
          {displayPrev && (
            <Button
              onClick={this.handleBack}
              buttonText={"Previous"}
              className={"btnColoured"}
            />
          )}
          {displayNext && (
            <Button
              className={"btnSolid"}
              buttonText={"Next"}
              onClick={this.handleNext}
            />
          )}
          {displaySave && (
            <Button
              asyncButton={true}
              buttonText={"Run Campaign"}
              className={"btnSolid"}
              onClick={this.handleStartCampaign}
            />
          )}
        </div>
      </React.Fragment>
    );
  }

  hasData() {
    const isTemplatedAnswerTreatment = /{\$([^}])+}/.test(
      this.state.answerTreatment
    );
    return (
      (this.state.data && this.state.data.length > 0) ||
      isTemplatedAnswerTreatment
    );
  }
};

CampaignRunWizard.propTypes = {
  campaign: PropTypes.object,
  fetchCampaign: PropTypes.func,
  match: PropTypes.object,
  showSnackbarMessage: PropTypes.func,
  startCampaign: PropTypes.func,
  status: PropTypes.string,
};

const mapDispatchToProps = {
  startCampaign,
  showConfirmationDialog,
  showSnackbarMessage,
  fetchCampaign,
};

const mapStateToProps = (state) => ({
  status: state.campaign.status,
  campaign: state.campaign.campaign,
});

CampaignRunWizard = connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignRunWizard);

export default CampaignRunWizard;
