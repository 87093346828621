import React from "react";
import "../Badge/Badge.scss";
import { Stack, PageHeading, Tabs, Section, Main } from "@operata/adagio";
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  withRouter,
} from "react-router-dom";
import AgentLogs from "./AgentLogs";
import CallStatsLog from "./CallStatsLog";

const ExplorerCalls = () => {
  const { path } = useRouteMatch();

  return (
    <Main.Container headless>
      <Main.Scroll>
        <Stack direction={"column"} gap="24" flex={true} padding={"large"}>
          <PageHeading title="Calls and Logs" />
          <Section>
            <Stack direction={"column"} gap="24">
              <Tabs padding="8">
                <Tabs.Tab to={`${path}/calls`} label="Call Stats Log" />
                <Tabs.Tab to={`${path}/logs`} label="Softphone Logs" />
              </Tabs>
            </Stack>
            <Section.Content>
              <Stack direction="column" gap="medium">
                <Switch>
                  <Route exact path={path}>
                    <Redirect to={`${path}/calls`} />
                  </Route>
                  <Route path={`${path}/calls`}>
                    <CallStatsLog path={`${path}/calls`} />
                  </Route>
                  <Route path={`${path}/logs`}>
                    <AgentLogs />
                  </Route>
                </Switch>
              </Stack>
            </Section.Content>
          </Section>
        </Stack>
      </Main.Scroll>
    </Main.Container>
  );
};

export default withRouter(ExplorerCalls);
