import React from "react";
import "../ActionBar/ActionBar.scss";
// import './Campaigns.scss';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { AVAILABLE_CAMPAIGN_TYPES } from "./CreateCampaign/WizardConstants";
import Chip from "../Chips/Chip";

function renderTypes(selectedType) {
  return Object.keys(AVAILABLE_CAMPAIGN_TYPES).map((key) => {
    let { type, image, enabled } = AVAILABLE_CAMPAIGN_TYPES[key];
    let selected = key === selectedType ? "selected" : "";

    return (
      key === selectedType && (
        <div className={`campaignType__cel ${enabled} ${selected}`} key={key}>
          <div
            style={{ backgroundImage: `url(${image})` }}
            className="campaignType__logo"
            alt={type}
          />
        </div>
      )
    );
  });
}

function printTags(tags) {
  return tags.map((tag) => {
    return <Chip label={tag} key={tag} />;
  });
}

function CampaignViewDetails(props) {
  const { campaignRun } = props;
  return (
    <React.Fragment>
      {campaignRun && (
        <div className="campaigns__grid">
          <List>
            <ListItem>{renderTypes(campaignRun.type)}</ListItem>
            {campaignRun.runTags && (
              <ListItem>{printTags(campaignRun.runTags)}</ListItem>
            )}
            {!campaignRun.runTags && campaignRun.tags && (
              <ListItem>{printTags(campaignRun.tags)}</ListItem>
            )}

            <ListItem>
              <ListItemText
                primary="Campaign Name"
                secondary={campaignRun.name}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Type" secondary={campaignRun.type} />
            </ListItem>
            {campaignRun.runDescription && (
              <ListItem>
                <ListItemText
                  primary="Run Description"
                  secondary={campaignRun.runDescription}
                />
              </ListItem>
            )}
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary="Description"
                secondary={campaignRun.description}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="From Number"
                secondary={campaignRun.fromNumber}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="To Number"
                secondary={campaignRun.toNumber}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Total Calls"
                secondary={campaignRun.totalCalls}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="No Answer Timeout"
                secondary={campaignRun.noAnswerTimeout}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemText
                primary="Call Duration"
                secondary={campaignRun.callDuration}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Calls Per Second"
                secondary={campaignRun.callsPerSecond}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Answer Treatment"
                secondary={campaignRun.answerTreatment}
              />
            </ListItem>
          </List>
        </div>
      )}
    </React.Fragment>
  );
}

export default CampaignViewDetails;
