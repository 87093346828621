import Dygraph from "dygraphs";
import Crosshair from "../crosshair.js";
import { legendFormatter } from "./Helpers/legendFormatter.js";

const GraphCpu = ({ call, graphOptions }) => {
  if (!call?.call) return;
  const cpu = call?.call?.map((d) => [new Date(d.timestamp), d.cpuAvailable]);

  return new Dygraph("graph-cpu", cpu, {
    labels: ["timestamp", "cpu-available"],
    labelsDiv: "graph-cpu-legend",
    axisLabelWidth: graphOptions.op_yAxisLabelWidth,
    axes: {
      x: {
        drawGrid: false,
        drawAxis: true,
      },
      y: {
        drawGrid: true,
        drawAxis: true,
        gridLineColor: "rgba(0,0,0,0.3)",
      },
    },
    xRangePad: graphOptions.op_xRangePad,
    fillGraph: false,
    drawAxesAtZero: true,
    labelsUTC: true,
    legend: "always",
    legendFormatter: legendFormatter,
    colors: ["#f0a000"],
    plugins: [new Crosshair({ direction: "vertical" })],
  });
};

export default GraphCpu;
