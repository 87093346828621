import {
  SNACKBAR_OPENED,
  SNACKBAR_CLOSED,
  SNACKBAR_SUCCESS,
} from "../actions/snackbar";

const dialog = (
  state = { status: SNACKBAR_CLOSED, messageType: SNACKBAR_SUCCESS },
  action
) => {
  switch (action.type) {
    case SNACKBAR_OPENED:
      return {
        ...state,
        messageType: action.messageType,
        message: action.message,
        status: SNACKBAR_OPENED,
      };
    case SNACKBAR_CLOSED:
      return { ...state, status: SNACKBAR_CLOSED };
    default:
      return state;
  }
};

export default dialog;
