import CardAlternative from "../../../Card/CardAlternative";
import React from "react";
import * as PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import _ from "lodash";

function AX(props) {
  const { jobStats, calculateDegradation, agentToConnectReference } = props;

  let connect_to_agent;
  let agent_to_connect;
  let dialler_to_connect;
  if (jobStats.polqaScores) {
    connect_to_agent = jobStats.polqaScores.find(
      (polqaScores) => polqaScores.location === "connect_to_agent"
    );
    agent_to_connect = jobStats.polqaScores.find(
      (polqaScores) => polqaScores.location === "agent_to_connect"
    );
    dialler_to_connect = jobStats.polqaScores.find(
      (polqaScores) => polqaScores.location === "dialler_to_connect"
    );
  }

  return (
    <>
      <CardAlternative
        title={
          "AX " +
          (_.has(jobStats, "job.axScore")
            ? "(" + jobStats.job.axScore + "/10)"
            : "")
        }
      />

      <div className="campaigns__grid">
        <List>
          <ListItem>
            <ListItemText
              primary="Inbound Audio Quality"
              secondary={
                connect_to_agent && _.has(connect_to_agent, "score")
                  ? connect_to_agent.score
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Outbound Audio Quality"
              secondary={
                agent_to_connect && _.has(agent_to_connect, "score")
                  ? agent_to_connect.score
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Inbound Audio Loss"
              secondary={
                dialler_to_connect &&
                connect_to_agent &&
                _.has(dialler_to_connect, "score") &&
                _.has(connect_to_agent, "score")
                  ? calculateDegradation(
                      dialler_to_connect.score,
                      connect_to_agent.score
                    ) + " %"
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Outbound Audio Loss"
              secondary={
                agent_to_connect && _.has(agent_to_connect, "score")
                  ? calculateDegradation(
                      agentToConnectReference,
                      agent_to_connect.score
                    ) + " %"
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="CPU Utilisation"
              secondary={
                _.has(jobStats, "softphoneSummary.system.cpu.avg")
                  ? "Avg: " +
                    jobStats.softphoneSummary.system.cpu.avg.toFixed(2) +
                    " " +
                    "Min: " +
                    jobStats.softphoneSummary.system.cpu.min.toFixed(2) +
                    " " +
                    "Max: " +
                    jobStats.softphoneSummary.system.cpu.max.toFixed(2)
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Memory Utilisation"
              secondary={
                _.has(jobStats, "softphoneSummary.system.memory.avg")
                  ? "Avg: " +
                    jobStats.softphoneSummary.system.memory.avg.toFixed(2) +
                    " " +
                    "Min: " +
                    jobStats.softphoneSummary.system.memory.min.toFixed(2) +
                    " " +
                    "Max: " +
                    jobStats.softphoneSummary.system.memory.max.toFixed(2) +
                    " " +
                    "Total: " +
                    jobStats.softphoneSummary.system.memory.total.toFixed(2)
                  : "N/A"
              }
            />
          </ListItem>
        </List>

        <List>
          <ListItem>
            <ListItemText
              primary="Agent Username"
              secondary={
                jobStats && _.has(jobStats, "job.agentId")
                  ? jobStats.job.agentId
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="CPU Type"
              secondary={
                _.has(jobStats, "softphoneSummary.system.cpu.model")
                  ? jobStats.softphoneSummary.system.cpu.model
                  : "N/A"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Browser Type Version"
              secondary={
                _.has(jobStats, "softphoneSummary.browser.name")
                  ? jobStats.softphoneSummary.browser.name +
                    " " +
                    jobStats.softphoneSummary.browser.version
                  : "N/A"
              }
            />
          </ListItem>
        </List>
      </div>
    </>
  );
}

AX.propTypes = {
  jobStats: PropTypes.object,
  calculateDegradation: PropTypes.func,
  agentToConnectReference: PropTypes.number,
};

export default AX;
