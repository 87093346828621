import React from "react";
import { findDOMNode } from "react-dom";

import PropTypes from "prop-types";
import momentPropTypes from "react-moment-proptypes";
import { DateTimeRangePicker } from "./DateTimeRangePicker";
import WrapperContext from "../WrapperContext";

export const mobileBreakPoint = 680;

class DateTimeRangeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      x: 0,
      y: 0,
      screenWidthToTheRight: 0,
    };
    this.resize = this.resize.bind(this);
    this.changeVisibleState = this.changeVisibleState.bind(this);
    this.keyDown = this.keyDown.bind(this);
  }

  componentDidMount() {
    // window.addEventListener('resize', this.resize);
    // document.addEventListener('keydown', this.keyDown, false);
    this.resize();
  }

  UNSAFE_componentWillMount() {
    // window.removeEventListener('resize', this.resize);
    // document.removeEventListener('keydown', this.keyDown, false);
  }

  resize() {
    const domNode = findDOMNode(this).children[0];
    let boundingClientRect = domNode.getBoundingClientRect();
    let widthRightOfThis = window.innerWidth - boundingClientRect.x;
    if (widthRightOfThis < mobileBreakPoint) {
      // If in small mode put picker in middle of child
      let childMiddle = boundingClientRect.width / 2;
      let containerMiddle = 144;
      let newY = childMiddle - containerMiddle;
      this.setState({
        x: boundingClientRect.height + 5,
        y: newY,
        screenWidthToTheRight: widthRightOfThis,
      });
    } else {
      this.setState({
        x: boundingClientRect.height + 5,
        y: 0,
        screenWidthToTheRight: widthRightOfThis,
      });
    }
  }

  keyDown(e) {
    if (e.keyCode === 27) {
      this.setState({ visible: false });
      document.removeEventListener("keydown", this.keyDown, false);
    }
  }

  changeVisibleState() {
    this.setState((prevState) => ({
      visible: !prevState.visible,
    }));
  }

  shouldShowPicker() {
    if (
      this.state.visible &&
      this.state.screenWidthToTheRight < mobileBreakPoint
    ) {
      return "flex";
    } else if (this.state.visible) {
      return "flex";
    } else {
      return "flex";
    }
  }

  render() {
    let showPicker = this.shouldShowPicker();
    return (
      <div
        id="DateRangePickerContainer"
        className="daterangepickercontainer"
        onClick={this.onClickContainerHandler}
        ref={(container) => {
          this.container = container;
        }}
      >
        {this.props.children && (
          <div className="dateRangePickerChildren" id="DateRangePickerChildren">
            {this.props.children}
          </div>
        )}
        <div
          id="daterangepicker"
          className="daterangepicker"
          style={{ display: showPicker }}
        >
          <DateTimeRangePicker
            ranges={this.props.ranges}
            start={this.props.start}
            end={this.props.end}
            local={this.props.local}
            applyCallback={this.props.applyCallback}
            rangeCallback={this.props.rangeCallback}
            autoApply={this.props.autoApply}
            changeVisibleState={this.changeVisibleState}
            screenWidthToTheRight={this.state.screenWidthToTheRight}
            maxDate={this.props.maxDate}
          />
        </div>
      </div>
    );
  }
}

DateTimeRangeContainer.propTypes = {
  ranges: PropTypes.object.isRequired,
  start: momentPropTypes.momentObj,
  end: momentPropTypes.momentObj,
  local: PropTypes.object.isRequired,
  applyCallback: PropTypes.func.isRequired,
  rangeCallback: PropTypes.func,
  autoApply: PropTypes.bool,
  maxDate: momentPropTypes.momentObj,
  children: PropTypes.any,
};

DateTimeRangeContainer.contextType = WrapperContext;

export default DateTimeRangeContainer;
