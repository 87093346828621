import {
  TWELVE_MONTHS_APP_USAGE_RECEIVED,
  ERROR_APP_USAGE_RETRIEVAL,
} from "../actions/appUsage";

const appUsage = (
  state = {
    loading: false,
    usageData: [],
  },
  action
) => {
  switch (action.type) {
    case TWELVE_MONTHS_APP_USAGE_RECEIVED:
      return Object.assign({}, state, {
        usageData: action.usageData,
        loading: false,
        status: TWELVE_MONTHS_APP_USAGE_RECEIVED,
      });
    case ERROR_APP_USAGE_RETRIEVAL:
      return Object.assign({}, state, {
        loading: false,
        status: ERROR_APP_USAGE_RETRIEVAL,
      });
    default:
      return state;
  }
};

export default appUsage;
