import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Tabs } from "@operata/adagio";
import TabCharts from "./Tabs/_TabCharts";
import TabSummary from "./Tabs/_TabSummary";
import TabRTC from "./Tabs/_TabRTC";
import TabLogs from "./Tabs/_TabLogs";
import PropTypes from "prop-types";
import TabTelemetry from "./Tabs/_TabTelemetry";
import { auth, USER_ROLE_SUPER } from "../../auth/Auth";

function CallContent({ call, hasResolved }) {
  const { path, url } = useRouteMatch();

  return (
    <>
      <div className="call-section__tabs">
        <Tabs padding="medium">
          <Tabs.Tab exact to={`${url}`} label="Charts" />
          <Tabs.Tab to={`${url}/summary`} label="Summary" />
          <Tabs.Tab to={`${url}/rtc`} label="WebRTC" />
          <Tabs.Tab to={`${url}/logs`} label="Softphone Logs" />
          <Tabs.Tab to={`${url}/telemetry`} label="Telemetry" />
        </Tabs>
      </div>

      <Switch>
        <Route exact path={path}>
          <TabCharts call={call} hasResolved={hasResolved} />
        </Route>
        <Route path={`${path}/summary`}>
          <TabSummary call={call} hasResolved={hasResolved} />
        </Route>
        <Route path={`${path}/rtc`}>
          <TabRTC call={call} hasResolved={hasResolved} />
        </Route>
        <Route path={`${path}/logs`}>
          <TabLogs call={call} hasResolved={hasResolved} />
        </Route>
        {auth.hasPermission([USER_ROLE_SUPER]) && (
          <Route path={`${path}/telemetry`}>
            <TabTelemetry call={call} hasResolved={hasResolved} />
          </Route>
        )}
      </Switch>
    </>
  );
}

CallContent.propTypes = {
  call: PropTypes.object,
  hasResolved: PropTypes.bool,
};

export default CallContent;
