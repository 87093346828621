import React from "react";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import CampaignContext from "../CampaignContext";
import ChipInput from "material-ui-chip-input";
import _ from "lodash";
import { Alert } from "@material-ui/lab";

export class StepName extends React.Component {
  render() {
    return (
      <CampaignContext.Consumer>
        {({
          requiresApproval,
          approved,
          setApproved,
          runDescription,
          setRunDescription,
          error_runDescription,
          errorMessage_runDescription,
          runTags,
          setRunTags,
        }) => {
          return (
            <div className="grid__container workflow__summary">
              <div className="grid__column grid__column--four">
                {requiresApproval && (
                  <Alert
                    severity="warning"
                    action={
                      <Checkbox
                        checked={approved}
                        onChange={(event) => setApproved(event.target.checked)}
                        name="approved"
                      />
                    }
                  >
                    I have the required{" "}
                    <a href="https://help.operata.io/en/articles/3992442-aws-simulate-campaign-approval">
                      approvals
                    </a>{" "}
                    to run this campaign at this time
                  </Alert>
                )}

                <div className="workflow__input">
                  <TextField
                    error={error_runDescription}
                    label={
                      errorMessage_runDescription
                        ? errorMessage_runDescription
                        : "Run Description"
                    }
                    id="outlined-required"
                    name="runDescription"
                    className="textField"
                    onChange={(event) => setRunDescription(event.target.value)}
                    variant="outlined"
                    multiline={true}
                    rows="3"
                    value={runDescription}
                  />
                </div>

                <div className="workflow__input">
                  <ChipInput
                    className="tags__wrapper"
                    label="Add Tags"
                    placeholder="Type and press enter to add tags"
                    value={
                      !runTags ||
                      typeof runTags === "string" ||
                      runTags instanceof String
                        ? []
                        : runTags
                    }
                    onAdd={(newTag) => setRunTags(_.union(runTags, [newTag]))}
                    onDelete={(deltedTag) =>
                      setRunTags(_.without(runTags, deltedTag))
                    }
                  />
                </div>
              </div>
            </div>
          );
        }}
      </CampaignContext.Consumer>
    );
  }
}

export default StepName;
