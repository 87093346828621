import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import HelpIcon from "@material-ui/icons/Help";

const defaultHeadCellStyles = (theme) => ({
  root: {},
  fixedHeader: {
    top: "0px",
    left: "0px",
    zIndex: 100,
    backgroundColor: theme.palette.background.paper,
  },
  tooltip: {
    cursor: "pointer",
  },
  mypopper: {
    "&[data-x-out-of-boundaries]": {
      display: "none",
    },
  },
  data: {
    display: "inline-block",
  },
  sortAction: {
    display: "flex",
    verticalAlign: "top",
    cursor: "pointer",
    height: "10px",
  },
  sortActive: {
    color: theme.palette.text.primary,
  },
});

class TableHeadCell extends React.Component {
  static propTypes = {
    /** Extend the style applied to components */
    classes: PropTypes.object,
    /** Options used to describe table */
    options: PropTypes.object.isRequired,
    /** Current sort direction */
    sortDirection: PropTypes.string,
    /** Callback to trigger column sort */
    toggleSort: PropTypes.func.isRequired,
    /** Sort enabled / disabled for this column **/
    sort: PropTypes.bool.isRequired,
    /** Hint tooltip text */
    hint: PropTypes.string,
    /** Column displayed in print */
    print: PropTypes.bool.isRequired,
  };

  state = {
    isSortTooltipOpen: false,
    isHintTooltipOpen: false,
  };

  handleSortClick = () => {
    this.props.toggleSort(this.props.index);
  };

  render() {
    const { isSortTooltipOpen, isHintTooltipOpen } = this.state;
    const { children, classes, options, sortDirection, sort, hint, print } =
      this.props;
    const sortActive =
      sortDirection !== null && sortDirection !== undefined ? true : false;

    const sortLabelProps = {
      active: sortActive,
      hideSortIcon: true,
      ...(sortDirection ? { direction: sortDirection } : {}),
    };

    const cellClass = classNames({
      [classes.fixedHeader]: options.fixedHeader,
      "datatables-noprint": !print,
    });

    return (
      <TableCell
        className={cellClass}
        scope={"col"}
        sortDirection={sortDirection}
      >
        {options.sort && sort ? (
          <Tooltip
            title={options.textLabels.body.toolTip}
            placement={"bottom-start"}
            classes={{ tooltip: classes.tooltip, popper: classes.mypopper }}
            enterDelay={300}
            open={isSortTooltipOpen}
            onOpen={() =>
              isHintTooltipOpen
                ? this.setState({ isSortTooltipOpen: false })
                : this.setState({
                    isSortTooltipOpen: true,
                  })
            }
            onClose={() => this.setState({ isSortTooltipOpen: false })}
          >
            <div
              role="button"
              onKeyUp={this.handleClickSort}
              onClick={this.handleSortClick}
              className="sort__wrapper textfield-head"
              tabIndex={0}
            >
              <div
                className={classNames({
                  [classes.data]: true,
                  "sort__label--active": sortActive,
                })}
              >
                {children}
              </div>
              <div className={classes.sortAction}>
                <TableSortLabel {...sortLabelProps} />
                {hint && (
                  <Tooltip
                    title={hint}
                    placement={"bottom-end"}
                    classes={{
                      tooltip: classes.tooltip,
                      popper: classes.mypopper,
                    }}
                    enterDelay={300}
                    open={isHintTooltipOpen}
                    onOpen={() =>
                      this.setState({
                        isSortTooltipOpen: false,
                        isHintTooltipOpen: true,
                      })
                    }
                    onClose={() => this.setState({ isHintTooltipOpen: false })}
                  >
                    <HelpIcon fontSize="small" />
                  </Tooltip>
                )}
              </div>
            </div>
          </Tooltip>
        ) : (
          children
        )}
        {!options.sort ||
          (!sort && hint && (
            <Tooltip
              title={hint}
              placement={"bottom-end"}
              classes={{ tooltip: classes.tooltip, popper: classes.mypopper }}
              enterDelay={300}
            >
              <HelpIcon fontSize="small" />
            </Tooltip>
          ))}
      </TableCell>
    );
  }
}

export default withStyles(defaultHeadCellStyles, {
  name: "MUIDataTableHeadCell",
})(TableHeadCell);
