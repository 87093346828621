import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  LinkTab,
  TabContainer,
  Tabs,
  TabsWrapper,
} from "@operata/ui-components";
import {
  ERROR_JOB_STATS_REPORT_RECEIVED,
  fetchJobStats,
  fetchJobInsights,
  ERROR_JOB_INSIGHTS_RECEIVED,
} from "../../../actions/heartbeat";
import { showSnackbarMessage, SNACKBAR_ERROR } from "../../../actions/snackbar";

import Results from "./Results/Results";
import AudioAnalytics from "./AudioAnalytics/AudioAnalytics";
import "./Report.scss";

const Report = (props) => {
  const { job, status, message, insights } = useSelector(
    (state) => state.heartbeat
  );

  const [state, setState] = useState({
    selectedTab: 0,
  });

  let jobId = props.match.params.jobId;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchJobStats(jobId));
    dispatch(fetchJobInsights(jobId));
  }, []);

  if (status === ERROR_JOB_STATS_REPORT_RECEIVED) {
    dispatch(showSnackbarMessage(SNACKBAR_ERROR, message));
  }

  if (status === ERROR_JOB_INSIGHTS_RECEIVED) {
    dispatch(showSnackbarMessage(SNACKBAR_ERROR, message));
  }

  return (
    <TabsWrapper>
      <Tabs
        selectedTab={state.selectedTab}
        onChange={(e, val) => setState({ selectedTab: val })}
      >
        <LinkTab label="Results" href="page1" />

        <LinkTab label="Audio Analytics" href="page2" />

        {/*<LinkTab label="Network" href="page3" />*/}
      </Tabs>

      <div className="tab__content report__wrapper">
        <TabContainer selectedTab={state.selectedTab} index={0}>
          <Results jobStats={job} insights={insights} />
        </TabContainer>

        <TabContainer selectedTab={state.selectedTab} index={1}>
          <AudioAnalytics jobDetails={job} />
        </TabContainer>

        {/*<TabContainer selectedTab={state.selectedTab} index={2}>*/}
        {/*  <Network job={job.job} softphoneSummary={job.softphoneSummary} softphoneEvents={job.softphoneEvents} />*/}
        {/*</TabContainer>*/}
      </div>
    </TabsWrapper>
  );
};

Report.propTypes = {
  match: PropTypes.any,
};

export default Report;
