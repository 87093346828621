import PropTypes from "prop-types";
import React, { useState } from "react";
import { Filter, MUIDataTable } from "@operata/ui-components";
import { dispatchChangeFilter } from "../../../actions/location";
import { useDispatch } from "react-redux";
import { matchPath } from "react-router-dom";
import { INSIGHTS_TAGS } from "../../../constants/deeplinks";
import { QS } from "../../UNSAFE_route";

const InsightsTable = ({
  logs,
  tags = [],
  status = [],
  columns,
  dataToColumn,
  InsightsSneakPeek,
  filterInsights,
  props,
}) => {
  const [filters, setFilters] = useState({});
  const dispatch = useDispatch();

  let filteredList =
    parseInt(filterInsights.selectedTab) === INSIGHTS_TAGS.indexOf("Telephony")
      ? filterInsights.tags.telephony || []
      : parseInt(filterInsights.selectedTab) === INSIGHTS_TAGS.indexOf("CX")
      ? filterInsights.tags.cX || []
      : parseInt(filterInsights.selectedTab) === INSIGHTS_TAGS.indexOf("AX")
      ? filterInsights.tags.aX || []
      : parseInt(filterInsights.selectedTab) ===
        INSIGHTS_TAGS.indexOf("Network Performance")
      ? filterInsights.tags.networkPerformance || []
      : [];

  const handleFilterUpdate = (index, value, name) => {
    setFilters((prevFilters) => {
      return Object.assign({}, prevFilters, {
        [name]: value,
      });
    });

    setFilters(filters);

    switch (parseInt(filterInsights.selectedTab)) {
      case INSIGHTS_TAGS.indexOf("Telephony"):
        dispatch(
          dispatchChangeFilter(
            {
              ...filterInsights,
              tags: {
                ...filterInsights.tags,
                telephony:
                  filterInsights.tags.telephony &&
                  filterInsights.tags.telephony.includes(value)
                    ? filterInsights.tags.telephony.splice(
                        filterInsights.tags.telephony.indexOf(value)
                      )
                    : value.concat(filterInsights.tags.telephony),
              },
            },
            "hbinsights"
          )
        );

        if (matchPath(window.location.pathname, props.match)) {
          QS.updateFromState("hbinsights");
        }
        return;
      case INSIGHTS_TAGS.indexOf("CX"):
        dispatch(
          dispatchChangeFilter(
            {
              ...filterInsights,
              tags: {
                ...filterInsights.tags,
                cX:
                  filterInsights.tags.cX &&
                  filterInsights.tags.cX.includes(value)
                    ? filterInsights.tags.cX.splice(
                        filterInsights.tags.cX.indexOf(value)
                      )
                    : value.concat(filterInsights.tags.cX),
              },
            },
            "hbinsights"
          )
        );

        if (matchPath(window.location.pathname, props.match)) {
          QS.updateFromState("hbinsights");
        }
        return;
      case INSIGHTS_TAGS.indexOf("AX"):
        dispatch(
          dispatchChangeFilter(
            {
              ...filterInsights,
              tags: {
                ...filterInsights.tags,
                aX:
                  filterInsights.tags.aX &&
                  filterInsights.tags.aX.includes(value)
                    ? filterInsights.tags.aX.splice(
                        filterInsights.tags.aX.indexOf(value)
                      )
                    : value.concat(filterInsights.tags.aX),
              },
            },
            "hbinsights"
          )
        );

        if (matchPath(window.location.pathname, props.match)) {
          QS.updateFromState("hbinsights");
        }
        return;
      case INSIGHTS_TAGS.indexOf("Network Performance"):
        dispatch(
          dispatchChangeFilter(
            {
              ...filterInsights,
              tags: {
                ...filterInsights.tags,
                networkPerformance:
                  filterInsights.tags.networkPerformance &&
                  filterInsights.tags.networkPerformance.includes(value)
                    ? filterInsights.tags.networkPerformance.splice(
                        filterInsights.tags.networkPerformance.indexOf(value)
                      )
                    : value.concat(filterInsights.tags.networkPerformance),
              },
            },
            "hbinsights"
          )
        );

        if (matchPath(window.location.pathname, props.match)) {
          QS.updateFromState("hbinsights");
        }
        return;
    }
  };

  const removeCountFromFilterName = (filter) => {
    const split = filter.split(" ");
    split.pop();
    return split.join(" ");
  };

  const getFilteredData = () => {
    if (filteredList && filteredList.length > 0) {
      const filterOptionsWithoutCounts = filteredList.map(
        removeCountFromFilterName
      );

      const matchesWithTags = logs.filter((data) => {
        let filteredTags = filterOptionsWithoutCounts
          .map((filter) => tags[filter])
          .filter((tags) => tags);
        if (filteredTags.length > 0) {
          filteredTags = filteredTags.reduce((a, b) => a.concat(b));
          console.log(filteredTags);
          return (
            data.tags &&
            data.tags.filter(
              (tag) => filteredTags && filteredTags.indexOf(tag) >= 0
            ).length > 0
          );
        }
      });
      const matchesWithStatus = logs.filter(
        (data) => filterOptionsWithoutCounts.indexOf(data.status) >= 0
      );

      return matchesWithTags.concat(matchesWithStatus);
    }
    return logs;
  };

  const getTagsCount = (tag) => {
    return logs.filter((data) => data.tags && data.tags.indexOf(tag) >= 0)
      .length;
  };

  const getStatusCount = (status) => {
    return logs.filter((data) => data.status === status).length;
  };

  const getFilterOptions = () => {
    const tagFilters = Object.entries(tags).map(([category, tags]) => {
      let totalCount = 0;
      tags.forEach((tag) => (totalCount += getTagsCount(tag)));
      return `${category} (${totalCount})`;
    });
    const statusFilters = status.map((s) => {
      const count = getStatusCount(s);
      return `${s} (${count})`;
    });
    return tagFilters.concat(statusFilters);
  };

  const resultOptions = {
    filter: false,
    search: false,
    selectableRows: "none",
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 50],
    expandableRows: true,
    // eslint-disable-next-line react/display-name
    renderExpandableRow: (rowData) => {
      return <InsightsSneakPeek jobId={rowData[0]} />;
    },
    customSort: (data, colIndex, order) => {
      switch (parseInt(filterInsights.selectedTab)) {
        case INSIGHTS_TAGS.indexOf("Telephony"):
          if (
            (filterInsights.sortDirection.telephony.colIndex !== colIndex &&
              filterInsights.sortDirection.telephony.order !== order) ||
            (filterInsights.sortDirection.telephony.colIndex === colIndex &&
              filterInsights.sortDirection.telephony.order !== order)
          ) {
            dispatch(
              dispatchChangeFilter(
                {
                  ...filterInsights,
                  sortDirection: {
                    ...filterInsights.sortDirection,
                    telephony: {
                      colIndex: colIndex,
                      order: order,
                    },
                  },
                },
                "hbinsights"
              )
            );

            if (matchPath(window.location.pathname, props.match)) {
              QS.updateFromState("hbinsights");
            }
          }
          break;
        case INSIGHTS_TAGS.indexOf("CX"):
          if (
            (filterInsights.sortDirection.cX.colIndex !== colIndex &&
              filterInsights.sortDirection.cX.order !== order) ||
            (filterInsights.sortDirection.cX.colIndex === colIndex &&
              filterInsights.sortDirection.cX.order !== order)
          ) {
            dispatch(
              dispatchChangeFilter(
                {
                  ...filterInsights,
                  sortDirection: {
                    ...filterInsights.sortDirection,
                    cX: {
                      colIndex: colIndex,
                      order: order,
                    },
                  },
                },
                "hbinsights"
              )
            );

            if (matchPath(window.location.pathname, props.match)) {
              QS.updateFromState("hbinsights");
            }
          }
          break;
        case INSIGHTS_TAGS.indexOf("AX"):
          if (
            filterInsights.sortDirection.aX.colIndex !== colIndex ||
            (filterInsights.sortDirection.aX.colIndex === colIndex &&
              filterInsights.sortDirection.aX.order !== order)
          ) {
            dispatch(
              dispatchChangeFilter(
                {
                  ...filterInsights,
                  sortDirection: {
                    ...filterInsights.sortDirection,
                    aX: {
                      colIndex: colIndex,
                      order: order,
                    },
                  },
                },
                "hbinsights"
              )
            );

            if (matchPath(window.location.pathname, props.match)) {
              QS.updateFromState("hbinsights");
            }
          }
          break;
        case INSIGHTS_TAGS.indexOf("Network Performance"):
          if (
            filterInsights.sortDirection.networkPerformance.colIndex !==
              colIndex ||
            (filterInsights.sortDirection.networkPerformance.colIndex ===
              colIndex &&
              filterInsights.sortDirection.networkPerformance.order !== order)
          ) {
            dispatch(
              dispatchChangeFilter(
                {
                  ...filterInsights,
                  sortDirection: {
                    ...filterInsights.sortDirection,
                    networkPerformance: {
                      colIndex: colIndex,
                      order: order,
                    },
                  },
                },
                "hbinsights"
              )
            );

            if (matchPath(window.location.pathname, props.match)) {
              QS.updateFromState("hbinsights");
            }
          }
      }

      return data.sort((a, b) => {
        return (
          (a.data[colIndex] < b.data[colIndex] ? -1 : 1) *
          (order === "asc" ? 1 : -1)
        );
      });
    },
    onTableChange: (action, tableState) => {
      if (action === "columnViewChange") {
        switch (parseInt(filterInsights.selectedTab)) {
          case INSIGHTS_TAGS.indexOf("Telephony"):
            for (let index = 0; index < tableState.columns.length; index++) {
              if (
                tableState.columns[index].display !==
                filterInsights.showColumns.telephony[index].display
              ) {
                dispatch(
                  dispatchChangeFilter(
                    {
                      ...filterInsights,
                      showColumns: {
                        ...filterInsights.showColumns,
                        telephony: tableState.columns,
                      },
                    },
                    "hbinsights"
                  )
                );
              }
            }

            if (matchPath(window.location.pathname, props.match)) {
              QS.updateFromState("hbinsights");
            }
            break;
          case INSIGHTS_TAGS.indexOf("CX"):
            for (let index = 0; index < tableState.columns.length; index++) {
              if (
                tableState.columns[index].display !==
                filterInsights.showColumns.cX[index].display
              ) {
                dispatch(
                  dispatchChangeFilter(
                    {
                      ...filterInsights,
                      showColumns: {
                        ...filterInsights.showColumns,
                        cX: tableState.columns,
                      },
                    },
                    "hbinsights"
                  )
                );
              }
            }

            if (matchPath(window.location.pathname, props.match)) {
              QS.updateFromState("hbinsights");
            }
            break;
          case INSIGHTS_TAGS.indexOf("AX"):
            for (let index = 0; index < tableState.columns.length; index++) {
              if (
                tableState.columns[index].display !==
                filterInsights.showColumns.aX[index].display
              ) {
                dispatch(
                  dispatchChangeFilter(
                    {
                      ...filterInsights,
                      showColumns: {
                        ...filterInsights.showColumns,
                        aX: tableState.columns,
                      },
                    },
                    "hbinsights"
                  )
                );
              }
            }

            if (matchPath(window.location.pathname, props.match)) {
              QS.updateFromState("hbinsights");
            }
            break;
          case INSIGHTS_TAGS.indexOf("Network Performance"):
            for (let index = 0; index < tableState.columns.length; index++) {
              if (
                tableState.columns[index].display !==
                filterInsights.showColumns.networkPerformance[index].display
              ) {
                dispatch(
                  dispatchChangeFilter(
                    {
                      ...filterInsights,
                      showColumns: {
                        ...filterInsights.showColumns,
                        networkPerformance: tableState.columns,
                      },
                    },
                    "hbinsights"
                  )
                );
              }
            }

            if (matchPath(window.location.pathname, props.match)) {
              QS.updateFromState("hbinsights");
            }
        }
      }
    },
  };

  return (
    <div className="insights__filtertables">
      <Filter
        filters={[
          {
            type: "checkbox",
            label: "Options",
            name: "options",
            options: getFilterOptions(),
            filteredList: filteredList,
          },
        ]}
        onFilterUpdate={handleFilterUpdate}
        showReset={false}
      />

      <MUIDataTable
        data={getFilteredData().map(dataToColumn)}
        columns={columns}
        options={resultOptions}
      />
    </div>
  );
};

InsightsTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  dataToColumn: PropTypes.func,
  globalFilters: PropTypes.object,
  logs: PropTypes.array,
  status: PropTypes.array,
  tags: PropTypes.array,
  InsightsSneakPeek: PropTypes.elementType,
  filterInsights: PropTypes.object,
  props: PropTypes.object,
  match: PropTypes.object,
};

InsightsTable.displayName = "InsightsTable";

export default InsightsTable;
