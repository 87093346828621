import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Checkbox from "@material-ui/core/Checkbox";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

const defaultSelectCellStyles = (theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "white",
    },
  },
  fixedHeader: {
    top: "0px",
    left: "0px",
    zIndex: 100,
  },
  icon: {
    cursor: "pointer",
    transition: "transform 0.25s",
  },
  expanded: {
    transform: "rotate(90deg)",
  },
  hide: {
    visibility: "hidden",
  },
  headerCell: {
    zIndex: 110,
    backgroundColor: "white",
  },
  checkboxRoot: {
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
  disabled: {},
});

class TableSelectCell extends React.Component {
  static propTypes = {
    /** Select cell checked on/off */
    checked: PropTypes.bool.isRequired,
    /** Select cell part of fixed header */
    fixedHeader: PropTypes.bool.isRequired,
    /** Callback to trigger cell update */
    onChange: PropTypes.func,
    /** Extend the style applied to components */
    classes: PropTypes.object,
    /** Is expandable option enabled */
    expandableOn: PropTypes.bool,
    /** Is selectable option enabled */
    selectableOn: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    /** Select cell disabled on/off */
  };

  static defaultProps = {
    isHeaderCell: false,
    isRowExpanded: false,
    expandableOn: false,
    selectableOn: "none",
  };

  render() {
    const {
      classes,
      fixedHeader,
      isHeaderCell,
      expandableOn,
      selectableOn,
      isRowExpanded,
      onExpand,
      isRowSelectable,
      ...otherProps
    } = this.props;

    const selectNonBoolean =
      typeof selectableOn === "boolean" ? "multiple" : selectableOn;

    if (!expandableOn && selectNonBoolean === "none") return false;

    const cellClass = classNames({
      [classes.root]: true,
      [classes.fixedHeader]: fixedHeader,
      [classes.headerCell]: isHeaderCell,
    });

    const iconClass = classNames({
      [classes.icon]: true,
      [classes.hide]: isHeaderCell,
      [classes.expanded]: isRowExpanded,
    });

    const renderCheckBox = () => {
      if (isHeaderCell && selectNonBoolean !== "multiple") {
        // only display the header checkbox for multiple selection.
        return null;
      }
      return (
        <Checkbox
          classes={{
            root: classes.checkboxRoot,
            checked: classes.checked,
            disabled: classes.disabled,
          }}
          disabled={!isRowSelectable}
          {...otherProps}
        />
      );
    };

    const selectOption =
      (typeof selectableOn === "boolean" && selectableOn) ||
      (typeof selectableOn !== "boolean" && selectableOn !== "none");

    return (
      <TableCell className={cellClass} padding="checkbox">
        <div style={{ display: "flex", alignItems: "center" }}>
          {expandableOn && (
            <KeyboardArrowRight
              id="expandable-button"
              className={iconClass}
              onClick={onExpand}
            />
          )}
          {selectOption && renderCheckBox()}
        </div>
      </TableCell>
    );
  }
}

export default withStyles(defaultSelectCellStyles, {
  name: "MUIDataTableSelectCell",
})(TableSelectCell);
