import { UPDATE_FILTER, PATH_CHANGED } from "../actions/location";

const initialState = {
  path: "",
};

const location = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FILTER: {
      if (Object.keys(action.payload).length !== 0) {
        let retState = {};
        retState[action.reduxPath] = { filter: action.payload };
        return Object.assign({}, state, retState);
      } else return state;
    }
    case PATH_CHANGED:
      return Object.assign({}, state, {
        path: action.path,
      });
    default:
      return state;
  }
};

export default location;
