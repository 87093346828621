import PropTypes from "prop-types";
import { CircularGauge } from "@operata/ui-components";
import React from "react";
import VisibilityToggle from "../../VisibilityToggle/VisibilityToggle";
export const GREEN = "#55BF3B";
export const YELLOW = "#DDDF0D";
export const RED = "#DF5353";

const InsightsScore = ({ value, prevValue, unit, title, description }) => {
  const percentageChange = ((value - prevValue) / prevValue) * 100;

  const getTrendText = () => {
    let trendText = "";
    if (prevValue > 0 && percentageChange !== 0) {
      const upOrDown = percentageChange > 0 ? "Up" : "Down";
      trendText = `${upOrDown} ${Math.abs(Math.round(percentageChange))}%`;
    }
    return trendText;
  };

  const getThresholdColours = () => {
    let gaugeColour;
    if (percentageChange >= 0 || prevValue === 0) {
      gaugeColour = GREEN;
    } else if (percentageChange >= -10) {
      gaugeColour = YELLOW;
    } else {
      gaugeColour = RED;
    }

    return [[0, gaugeColour]];
  };

  return (
    <div className="insights__item">
      <h3 className="insights__title">{title}</h3>
      <CircularGauge
        width={200}
        height={200}
        value={value}
        unit={unit}
        subtext={getTrendText()}
        thresholds={getThresholdColours()}
        max={0}
      />
      <p className="insights__text">View details</p>

      <VisibilityToggle className={"trigger__description"}>
        {description}
      </VisibilityToggle>
    </div>
  );
};

export default InsightsScore;

InsightsScore.propTypes = {
  prevValue: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  unit: PropTypes.string,
  value: PropTypes.number,
};
