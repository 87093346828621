import {
  USER_ROLE_SUPER,
  USER_ROLE_ADMIN,
  USER_ROLE_USER,
} from "../../../auth/Auth";
import { ConfigField } from "../types/config";

export const datasourceConfig: ConfigField[] = [
  {
    type: `json`,
    key: `CLOUD_COLLECTOR_VERSION`,
    title: `Cloud Collector`,
    fieldText: `Cloud Collector`,
    description: `Cloud Collector version`,
    write: [],
    read: [USER_ROLE_SUPER, USER_ROLE_ADMIN, USER_ROLE_USER],
    default: ``,
  },
  {
    type: `json`,
    key: `ORCHESTRATOR_VERSION`,
    title: `Orchestrator`,
    fieldText: `Orchestrator`,
    description: `Orchestrator version`,
    write: [],
    read: [USER_ROLE_SUPER, USER_ROLE_ADMIN, USER_ROLE_USER],
    default: ``,
  },
  {
    type: `json`,
    key: `REDACTION_CONFIG`,
    title: `CTR Redaction Config`,
    fieldText: `CTR Redaction Config`,
    description: `The redaction config used to redact CTR data collected`,
    write: [],
    read: [USER_ROLE_SUPER, USER_ROLE_ADMIN, USER_ROLE_USER],
    default: ``,
  },
  {
    type: `array`,
    key: `CTR_ATTRIBUTES_WHITELIST`,
    title: `Allowlisted CTR Attributes`,
    fieldText: `Allowlisted CTR Attributes`,
    description: `The CTR attributes that can be collected`,
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_USER],
    default: ``,
  },

  {
    type: `json`,
    key: `AGENT_DETAILS`,
    title: `Agent Details S3 Bucket configuration`,
    fieldText: `Agent Details S3 Bucket configuration`,
    description: `Configuration for importing agent details from a customer S3 bucket.`,
    fields: [
      {
        type: `string`,
        key: `s3_bucket`,
        title: "Imported agent details from this customer S3 bucket will be used to enrich the call data.",
        default: "",
      },
      {
        type: `string`,
        key: `s3_bucket_prefix`,
        title: "An optional prefix of the Agent Details S3 Bucket.",
        default: "",
      },
      {
        type: `string`,
        key: `s3_bucket_region`,
        title: "Agent Details S3 Bucket Region",
        default: "",
      },
      {
        type: `string`,
        key: `aws_role_arn`,
        title: "Role ARN provided by the customer",
        default: "",
      },
      {
        type: `string`,
        key: `external_id`,
        title: "External ID used for assuming the customer's AWS role",
        default: "",
      }
    ],
    write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    read: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
  }
];
